import React, { useEffect, useState } from 'react';
import { Modal, Form, Select, Input, Button, message } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import './style.less';
import { chargeProfileActions } from '@store/actions';
const FormularioPerfil = (props) => {
  const dispatch = useDispatch();
  const chargersSelected = useSelector(({ profiles }) => profiles.chargers);
  const vehiculesProfile = useSelector(({ profiles }) => profiles.vehicules);
  const [secondOption, setSecondOption] = useState(true);
  const [secondOptionList, setSecondOptionList] = useState([{ id: 0, conn_id: '' }]);
  const { visible, handleHidePerfilForm, profiles } = props;

  const { Option } = Select;

  const [form] = Form.useForm();

  const handleCancelarClick = () => {
    form.resetFields();
    handleHidePerfilForm();
  };

  const handleSubmitPerfil = () => {
    form
      .validateFields()
      .then((rslt) => {
        const reviewFirstPriority = vehiculesProfile[0].filter((x) => {
          return x.content.charger === rslt.charger && x.content.connector === rslt.connector;
        });

        const reviewSecondPriority = vehiculesProfile[1].filter((x) => {
          return x.content.charger === rslt.charger && x.content.connector === rslt.connector;
        });

        const reviewThirdPriority = vehiculesProfile[2].filter((x) => {
          return x.content.charger === rslt.charger && x.content.connector === rslt.connector;
        });

        if (
          reviewFirstPriority.length === 0 &&
          reviewSecondPriority.length === 0 &&
          reviewThirdPriority.length === 0
        ) {
          handleCancelarClick();

          const data = chargersSelected.selected.filter((x) => {
            return x.name === rslt.charger;
          });

          const connectorId = data[0].connectors.filter((x) => {
            return x.id === rslt.connector;
          });

          if (!profiles.editProfile) {
            const vehiculeCreated = {
              id: (
                vehiculesProfile[0].length +
                vehiculesProfile[1].length +
                vehiculesProfile[2].length +
                1
              ).toString(),
              content: {
                ...rslt,
                connId: connectorId[0].conn_id,
                maxPower: connectorId[0].max_electric_power,
              },
            };
            vehiculesProfile[0].push(vehiculeCreated);
            dispatch(chargeProfileActions.setChargeProfileVehicules(vehiculesProfile));
          } else {
            const newProfiles = vehiculesProfile.map((priority) => {
              if (priority.length > 0) {
                const posObject = priority.findIndex((o) => o.id === profiles.id);
                if (posObject >= 0) {
                  priority[posObject] = {
                    id: profiles.id,
                    content: {
                      ...rslt,
                      connId: connectorId[0].conn_id,
                      maxPower: connectorId[0].max_electric_power,
                    },
                  };
                }
                return priority;
              }
              return priority;
            });
            dispatch(chargeProfileActions.setChargeProfileVehicules(newProfiles));
          }
        } else {
          message.error('Conector no Disponible');
        }
      })
      .catch((e) => {
        console.error(e);
      });
  };

  useEffect(() => {
    if (!profiles.editProfile) {
      form.resetFields();
    } else {
      // console.log(profiles.editProfile);
      form.setFieldsValue({
        name: profiles.name,
        charger: profiles.charger,
        connector: profiles.connector,
      });
    }
  }, [profiles.editProfile, profiles.charger]);

  const optionSelected = (value) => {
    setSecondOption(false);
    const data = chargersSelected.selected.filter((x) => {
      return x.name === value;
    });
    form.setFieldsValue({
      connector: '',
    });
    setSecondOptionList(data[0].connectors);
  };

  return (
    <Modal
      onCancel={handleCancelarClick}
      footer={false}
      className={'container-form-perfil'}
      visible={visible}
    >
      <p>{profiles.editProfile ? `Editar Vehiculo\n${profiles.name}` : `Nuevo Vehiculo`}</p>
      <Form form={form} layout={'vertical'}>
        <Form.Item
          name={'name'}
          rules={[{ required: true, message: 'Debes asignar un nombre' }]}
          label={'Nombre'}
        >
          <Input placeholder={'Ingrese un nombre'} />
        </Form.Item>

        <Form.Item
          name={'charger'}
          initialValue={null}
          rules={[{ required: true, message: 'Debes asignar una unidad' }]}
          label={'Cargador'}
        >
          <Select
            placeholder={'Seleccione unidad'}
            onSelect={(LabeledValue) => {
              optionSelected(LabeledValue);
            }}
          >
            {chargersSelected.selected.map((value) => {
              return <Option value={value.name}>{value.name}</Option>;
            })}
          </Select>
        </Form.Item>

        <Form.Item
          name={'connector'}
          initialValue={null}
          rules={[{ required: true, message: 'Debes asignar una unidad' }]}
          label={'Conector'}
        >
          <Select placeholder={'Seleccione unidad'} disabled={secondOption}>
            {secondOptionList.map((value) => {
              return <Option value={value.id}>{value.conn_id}</Option>;
            })}
          </Select>
        </Form.Item>

        <div className={'btn-form'}>
          <Button onClick={handleCancelarClick}>Cancelar</Button>
          <Button onClick={handleSubmitPerfil} type={'primary'}>
            Guardar
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

FormularioPerfil.defaultProps = {
  visible: false,
};

export default FormularioPerfil;
