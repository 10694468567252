import React, { useEffect, useState } from 'react';
import { Form, Input, Switch, Select } from 'antd';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import SpecialCard from '../components/SpecialCard';
import LayoutStep from '../components/LayoutStep';
import styles from './GeneralAlarma.module.less';

const { TextArea } = Input;
const { Option } = Select;

function GeneralInformation(props) {
  const { onNext, onPrevious } = props;
  const generalInfo = useSelector(({ profiles }) => profiles.generalInfo);
  const profileSetup = useSelector(({ profiles }) => profiles.profileSetup);

  const [general, setGeneral] = useState({
    name: '',
    description: '',
    modeCharge: '',
    power: '',
    yard: '',
  });

  const handleYard = (e) => setGeneral({ ...general, yard: e });
  const handleForm = (e) => setGeneral({ ...general, [e.target.id]: e.target.value });
  const handleCharge = (e) => setGeneral({ ...general, modeCharge: e });

  useEffect(() => {
    if (profileSetup.editProfile) {
      setGeneral({
        name: generalInfo.name,
        description: generalInfo.description,
        modeCharge: generalInfo.modeCharge,
        power: generalInfo.power,
        yard: generalInfo.yard,
      });
    }
  }, [generalInfo, profileSetup]);

  return (
    <LayoutStep onNext={onNext} onPrevious={onPrevious}>
      <SpecialCard>
        <Form.Item
          className={styles.short_input}
          label="Nombre"
          rules={[{ required: true, message: 'Debes asignar un nombre' }]}
          name="name"
          initialValue={generalInfo.name}
        >
          <Input
            onChange={handleForm}
            value={general.name}
          />
        </Form.Item>

        <Form.Item
          className={styles.short_input}
          label="Potencia del Garage"
          rules={[{ required: true, message: 'Debes asignar una potencia' }]}
          name="power"
          initialValue={generalInfo.power}
        >
          <Input
            onChange={handleForm}
            type="number"
            value={general.power}
          />
        </Form.Item>

        <Form.Item
          className={styles.large_input}
          label="Descripcion"
          name="description"
          initialValue={generalInfo.description}
        >
          <TextArea
            onChange={handleForm}
            value={general.description}
            className={styles.text_area}
            rows={3}
          />
        </Form.Item>

        <Form.Item
          className={styles.large_input}
          label="Modo de Carga"
          name="modeCharge"
          rules={[{ required: true, message: 'Debes asignar una descripcion' }]}
          initialValue={generalInfo.modeCharge}

        >
          <Select onChange={handleCharge} value={general.modeCharge} placeholder="Seleccione unidad">
            <Option value="" disabled hidden>Seleccione unidad</Option>
            <Option value="BLOCK">Modo Estatico</Option>
            <Option value="SOC">Modo SOC</Option>
          </Select>
        </Form.Item>

        <Form.Item
          className={styles.large_input}
          label="Patio"
          name="yard"
          rules={[{ required: true, message: 'Debes asignar un patio' }]}
          initialValue={generalInfo.yard}
        >
          <Select onChange={handleYard} value={general.modeCharge} placeholder="Seleccione patio">
            <Option value="Yard1">Patio de ejemplo 1</Option>
            <Option value="Yard2">Patio de ejemplo 2</Option>
            <Option value="Yard3">Patio de ejemplo 3</Option>
          </Select>
        </Form.Item>

        <Form.Item
          className={styles.large_input}
          label="Activar"
          name="is_active"
          valuePropName="checked"
          initialValue
        >
          <Switch defaultChecked />
        </Form.Item>
      </SpecialCard>
    </LayoutStep>
  );
}

GeneralInformation.propTypes = {
  onNext: PropTypes.func.isRequired,
  onPrevious: PropTypes.func.isRequired,
};

export default GeneralInformation;
