import React from 'react';
import PropTypes from 'prop-types';
import "./style.less"
import { Button, Popconfirm } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPen, faRecordVinyl } from '@fortawesome/free-solid-svg-icons';
import carBus from '@assets/img/icons8-autobús-100.png'
import station from '@assets/img/icons8-car-charger-100.png'

function Vehicules(props) {
 const {item, setIdVehicule, handleNuevoPerfilClick} = props;
 return(
     <>
        <div className='card-content wrapper'>
            <div className='led'>
            <FontAwesomeIcon
                className="icon-record"
                icon={faRecordVinyl}
            />
            </div>
            <div className='edit'>
            <Button className='buttons-edit' onClick={()=>{
                handleNuevoPerfilClick(item);
            }}>
                <FontAwesomeIcon
                    className="icon-edit"
                    icon={faPen}
                />
            </Button>
            </div>
            <div className='trash'>
            <Popconfirm
                title={'¿Está seguro de borrar perfil?'}
                okText={'Si'}
                cancelText={'No'}
                onConfirm={() => setIdVehicule(item.id)}
            >
                <Button className='buttons-edit'>
                    <FontAwesomeIcon
                        className="icon-edit"
                        icon={faTrash}
                    />
                </Button>
            </Popconfirm>
           
            </div>
            <div className='nombre'>
                <h1>Nombre:</h1>
            </div>
            <div className='charger'>
                <h1>Cargador:</h1>
            </div>
            <div className='connector'>
                <h1>Connector:</h1>
            </div>
            <div className='station'>
            <img
                src={station}
                alt="Station"
            />
            </div>
            <div className='vehicule'>
            <img
                src={carBus}
                alt="Vehicule"
            />
            </div>
            <div className='info-one'>
                <h1>{item.content.name}</h1>
            </div>
            <div className='info-two'>
                <h1>{item.content.charger}</h1>
            </div>
            <div className='info-three'>
                <h1>{item.content.connId}</h1>
            </div>
            
            
        </div>
     </>
 );

}

Vehicules.propTypes = {
    item: PropTypes.object.isRequired,
  };
  
  export default Vehicules;