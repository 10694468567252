import { del, get, post, put, patch } from './management';

const getProfiles = () => get('/profiles/', true, true);

const postProfile = (body) => post('/profiles/', body, true, true);

const updateProfile = (body) => {
  const bodyTemp = { ...body };
  delete bodyTemp.id;

  return put(`/profiles/${body.id}`, bodyTemp, true, true);
};

const deleteProfile = (body) => del(`/profiles/${body.id}`, true, true);

const getParkingProfiles = () => get('/garage/garages/', true, false);

const createParkingProfiles = (body) => post('/garage/garages/', body, true, false);

const createParkingProfilesConnector = (body) => post('/garage/garages-connectors/', body, true, false);

const getParkingProfilesbyId = (id) => get(`/garage/garages/${id}`, true, false);

const delParkingProfilesbyId = (id) => del(`/garage/garages/${id}`, true, false);

const deleteProfileGarageConnector = (id) => del(`/garage/garages-connectors/${id}`, true, false);

const updateProfileGarage = (body) => {
  const bodyTemp = { ...body };
  delete bodyTemp.id;

  return put(`/garage/garages/${body.id}`, bodyTemp, true, false);
};

const updateProfileConnGarage = (body) => {
  const bodyTemp = { ...body };
  delete bodyTemp.garage;
  delete bodyTemp.connector;

  return patch(`/garage/detail-information/${body.garage}/${body.connector}`, bodyTemp, true, false);
};

export default {
  getProfiles,
  postProfile,
  updateProfile,
  deleteProfile,
  getParkingProfiles,
  getParkingProfilesbyId,
  deleteProfileGarageConnector,
  delParkingProfilesbyId,
  createParkingProfiles,
  createParkingProfilesConnector,
  updateProfileGarage,
  updateProfileConnGarage,
};
