import React from 'react';
import { Popover, Button } from 'antd';
import { BellOutlined } from '@ant-design/icons';

const NotificationCenter = () => {
  return (
    <Popover placement="bottom" trigger="click">
      <Button size="large" type="text" icon={<BellOutlined />} />
    </Popover>
  );
};

export default NotificationCenter;
