import React from 'react';
import { Menu, Button } from 'antd';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import configMenuData from '../../utils/menus/config_user';
import configMenuSitioData from '../../utils/menus/config_sitio';
import { comprobar_permiso } from '@utils/auth/permissions'
import { useTranslation } from 'react-i18next'
const { SubMenu } = Menu;

const ConfigMenu = (props) => {
  const { t, /* i18n */ } = useTranslation();
  const { className, history } = props;
  const { pathname } = history.location;
  const user = useSelector((state) => state.user);
  let finalKey = '';
  let allChildren = [];

  configMenuData.forEach((item) => {
    const { path, key, children } = item(t);
    if (pathname.includes(path)) finalKey = key;
    if (children.length > 0) allChildren = [...allChildren, ...children];
  });

  allChildren.forEach((item) => {
    const { path, key } = item;
    if (pathname.includes(path)) finalKey = key;
  });

  return (
    //#3f3f3f color de background
    <div
      className={className}>
      <Link
        to="/tablero-cpo">
        <Button
          style={{
            width: '100%',
            borderRadius: '0px',
            backgroundColor: 'transparent',
            padding: '10px',
            height: 'auto',
            textAlign: 'left'
          }}
          icon={<ArrowLeftOutlined />}>
          {t('menus.back')}
        </Button>
      </Link>

      <Menu

        // defaultSelectedKeys={[key]}
        selectedKeys={[finalKey]}
        // theme="dark"
        mode="inline"
      // inlineCollapsed={isMenuCollapsed}
      >

        <Menu.ItemGroup key="config_sitio" title={t('menus.divider.site')}>
          {configMenuSitioData.map((value) => {
            const { name, children, component, path, icon, key } = value(t);


            if (name === t('menus.profile') && !comprobar_permiso(user, 5)) return;

            const menuItem = component ? (
              <Menu.Item
                style={{
                  marginBottom: '0'
                }}
                key={key} icon={icon}>
                <Link to={path}>{name}</Link>
              </Menu.Item>
            ) : (
              <SubMenu key={name} title={name} icon={icon}>
                {children.map((childValue) => {
                  const {
                    name: nameChild,
                    path: pathChild,
                    key: keyChild,
                    icon: childIcon,
                  } = childValue;
                  return (
                    <Menu.Item key={keyChild} icon={childIcon}>
                      <Link to={pathChild}>{nameChild}</Link>
                    </Menu.Item>
                  );
                })}
              </SubMenu>
            );

            return menuItem;
          })}
        </Menu.ItemGroup>
        {

          (comprobar_permiso(user, 5) || comprobar_permiso(user, 9)) && (
            <Menu.ItemGroup key="config_user" title={t('menus.divider.user')}>
              {configMenuData.map((value) => {
                const { name, children, component, path, icon, key } = value(t);

                if (name === t('menus.clients') && !comprobar_permiso(user, 5)) return;
                if (name === t('menus.users') && !comprobar_permiso(user, 9)) return;
                if (name === t('menus.roles')) return;


                const menuItem = component ? (
                  <Menu.Item key={key} icon={icon}>
                    <Link to={path}>{name}</Link>
                  </Menu.Item>
                ) : (
                  <SubMenu key={name} title={name} icon={icon}>
                    {children.map((childValue) => {
                      const {
                        name: nameChild,
                        path: pathChild,
                        key: keyChild,
                        icon: childIcon,
                      } = childValue;
                      return (
                        <Menu.Item key={keyChild} icon={childIcon}>
                          <Link to={pathChild}>{nameChild}</Link>
                        </Menu.Item>
                      );
                    })}
                  </SubMenu>
                );

                return menuItem;
              })}
            </Menu.ItemGroup>
          )
        }


      </Menu>
    </div>

  );
};

ConfigMenu.propTypes = {
  className: PropTypes.string,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
};

ConfigMenu.defaultProps = {
  className: null,
};

export default ConfigMenu;
