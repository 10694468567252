import React , { useEffect, useState } from 'react';
import { Row,Col, Card, Button,message, Tabs } from 'antd';
import Breadcrumb from '@containers/Breadcrumb';
import { DateSelectorGroup } from '@components/ui';
import { TablaRecargas } from './views';
import { apiSessions } from '@utils/services/cpo'
import getLocalDateString from '@utils/helpers/UtcResponseReceive'
import getUTCString from '@utils/helpers/UtcRequestConverter'
import { export_to_csv } from '@utils/helpers/exportCSVUtil';
import { Spanish } from '@utils/helpers/dictIdiomas'
import { useTranslation } from 'react-i18next'
import { TableCompleted } from './components';
import './style.less';
import TableActive from './components/TableActive';

const HistorialRecargas = () => {
    const { TabPane } = Tabs;
    const { t, i18n } = useTranslation();
    const textLanguage = Spanish({screen:'cpo-recargas'})
    const breadcrumbData = [[null, t('history.header')]];
    
    const rangoHoy = () => {
        let hoyTemp1 = new Date();
        let hoyTemp2 = new Date();

        hoyTemp1.setHours(0);
        hoyTemp1.setMinutes(0);
        hoyTemp1.setMilliseconds(0);

        hoyTemp2.setHours(23);
        hoyTemp2.setMinutes(59);
        hoyTemp2.setMilliseconds(59);

        return [hoyTemp1,hoyTemp2]
    }

    const [rangoDate , setRangoDate] = useState(rangoHoy());
    const [ dataSource, setDataSource ] = useState({
        data:[],
        loading:false
    });
    const [ filteredTable , setFilteredTable ] = useState({
        filteredInfo:null,
        sortedInfo:null,
        filteredData:[]
    });
    const [ filtersRecargas , setFiltersRecargas ] = useState({
        ciudades:[],
        estaciones:[],
        cargadores:[],
        conector:[],
        rfid:[]
    });

    useEffect( () => {
        getSessions();
    },[]);

    useEffect( () => {
        getSessions();
    },[rangoDate]);

    const onChangeDateRange = (start,end) => {
        let dateStart = new Date(start);
        let dateEnd = new Date(end);

        setRangoDate([dateStart,dateEnd]);
    };

    const getSessionsCSV = () => {
        message.loading(textLanguage.REQUEST_DOWNLOAD_CSV);
        
        export_to_csv(filteredTable.filteredData,dataSource.data,'Historial');
    }

    

    const getSessions = () => {
        setDataSource({...dataSource,loading:true});
        apiSessions.getHistoSession({
            start_date:`${getUTCString(rangoDate[0])}`,
            end_date:`${getUTCString(rangoDate[1])}`,
            status:'COMPLETED'
        }).then( (rslt) => {
            // console.log(rslt);
            let citys = [];
            let estaciones = [];
            let cargadores = [];
            let conectores = [];
            let rfid = [];

            for(let i = 0 ; i < rslt.length ; i++){
                let item = rslt[i];
                if(citys.filter( (v) => v.value === item.location_id.city).length === 0) citys.push({  text:item.location_id.city, value:item.location_id.city });
                if(estaciones.filter( (v) => v.value === item.location_id.name).length === 0) estaciones.push({  text:item.location_id.name, value:item.location_id.name });
                if(cargadores.filter( (v) => v.value === item.evse_uid.uid).length === 0) cargadores.push({  text:item.evse_uid.uid, value:item.evse_uid.uid });
                if(conectores.filter( (v) => v.value === item.connector_id.standard).length === 0) conectores.push({  text:item.connector_id.standard, value:item.connector_id.standard });
                if(rfid.filter( (v) => v.value === item.tag_id).length === 0) rfid.push({  text:item.tag_id, value:item.tag_id });
            }

            setFiltersRecargas({...filtersRecargas,rfid:rfid,conector:conectores,estaciones:estaciones,cargadores:cargadores,ciudades:citys});

            let dataToTable = rslt.map( (v,i) => {
                let kwh = v.kwh ? (typeof v.kwh === 'string' ? parseFloat(v.kwh) : v.kwh) : 0;
                
                return {
                    key:i,
                    city:v.location_id.city,
                    location:v.location_id.name,
                    evse:v.evse_uid.uid,
                    connector:v.connector_id.standard,
                    rfid:v.tag_id,
                    start:v.start_date_time,
                    end:v.end_date_time,
                    duration:v.duration,
                    energy:`${(kwh>=1000?(kwh/1000):kwh).toFixed(2)+(kwh>=1000?'MWh':'kWh')}`
                }
            })

            setDataSource({data:dataToTable,loading:false});
        }).catch( (err) => {
            console.log('err: ',err);
            setDataSource({data:[],loading:false});
        });
    }


    return(
        <Row className={'historial-recargas_container'}>
            <Breadcrumb breadcrumbData={breadcrumbData}></Breadcrumb>

            <Col span={24} className={'container-tabs'}>
                <Card>
                    <Tabs >
                        <TabPane key={'end'} tab={t('history.tabs.ended')} >
                            <TableCompleted />
                        </TabPane>
                        <TabPane key={'active'} tab={t('history.tabs.active')}>
                            <TableActive />
                        </TabPane>
                    </Tabs>
                </Card>
            </Col>
        </Row>
    );
}

export default HistorialRecargas;