import React, { useEffect } from 'react';
import { Typography, Tabs } from 'antd';
import PropTypes from 'prop-types';
import { LanguageSelector } from '../../components'
import logoCompuestoOrange from '@assets/img/logo_compuesto_mubon.png';
import history from '@utils/history';


import LoginForm from './containers/LoginForm';
import './style.less';
import { useSelector } from 'react-redux';

const { TabPane } = Tabs;
const { Paragraph } = Typography;

const onChange = (key) => {
  switch (key) {

    case '/ingreso-cpo':
      history.push('/ingreso-cpo');
      break;

    default:
      break;
  }
};

const Sign = (props) => {
  const { platform } = useSelector((state) => state);
  const {
    location: { pathname },
  } = props;

  useEffect(()=>{
		try {
			if(platform.icon !== '') document.querySelector("link[rel*='icon']").href = `${platform.icon}`;
			if(platform.name !== '') document.title = `${platform.name}`;
			if(platform.main_color !== ''){
				window.less.modifyVars({
					'@primary-color':platform.main_color,
				});
			}
	
			window.less.refreshStyles();
		} catch (error) {
			console.log('error: ', error)
		}
  },[]);

  return (
    <div id="login">
      <div className="content">
        <section>
          <div>
            <header>
              <img width={200} src={platform.logo !== '' ? platform.logo : logoCompuestoOrange} alt="Logo" />
              <Tabs className="tabs"  activeKey={pathname} size="large" onChange={onChange} centered>
                <TabPane key="/ingreso-cpo" tab="Charge Point Operator">
                  <LoginForm />
                </TabPane>
              </Tabs>
            </header>
          </div>
        </section>

        <footer>
          <Paragraph>{platform.copy}</Paragraph>
          <LanguageSelector selectStyle={'dark-style'}   />
        </footer>
      </div>
    </div>
  );
};

Sign.propTypes = {
  location: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default Sign;
