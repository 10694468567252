const mapColorApp = () => {
    return [
        "#49a2d8",
        "#00c08c",
        "#ff812d",
        "#f60141",
        "#999999",
        "#1832AB"
    ]
}

export default mapColorApp;