import React from 'react';
import { Menu } from 'antd';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import mainMenuData from '../../utils/menus/main';
import { comprobar_permiso } from '@utils/auth/permissions'
import { useTranslation } from 'react-i18next'
const { SubMenu } = Menu;

const MainMenu = (props) => {
  const { t, /* i18n */ } = useTranslation();
  const { className, history } = props;
  const { pathname } = history.location;
  const user = useSelector((state) => state.user);
  let finalKey = '';
  let allChildren = [];

  mainMenuData.forEach((item) => {
    const { path, key, children } = item(t);
    if (pathname.includes(path)) finalKey = key;
    if (children.length > 0) allChildren = [...allChildren, ...children];
  });

  allChildren.forEach((item) => {
    const { path, key } = item;
    if (pathname.includes(path)) finalKey = key;
  });
  return (
    <Menu
      className={className}
      // defaultSelectedKeys={[key]}
      selectedKeys={[finalKey]}
      // theme="dark"
      mode="inline"
    // inlineCollapsed={isMenuCollapsed}
    >
      {mainMenuData.map((value) => {
        const { name, children, component, path, icon, key } = value(t);

        if (name === t('menus.tablecpo') && !comprobar_permiso(user, 0)) return;
        if (name === t('menus.rfid') && !comprobar_permiso(user, 13)) return;
        if (name === t('menus.alerts') && !comprobar_permiso(user, 10)) return;


        const menuItem = component ? (
          <Menu.Item key={key} icon={icon}>
            <Link to={path}>{name}</Link>
          </Menu.Item>
        ) : (
          <SubMenu key={name} title={name} icon={icon}>
            {children.map((childValue) => {
              const {
                name: nameChild,
                path: pathChild,
                key: keyChild,
                icon: childIcon,
              } = childValue;
              return (
                <Menu.Item key={keyChild} icon={childIcon}>
                  <Link to={pathChild}>{nameChild}</Link>
                </Menu.Item>
              );
            })}
          </SubMenu>
        );
        return menuItem;
      })}
    </Menu>
  );
};

MainMenu.propTypes = {
  className: PropTypes.string,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
};

MainMenu.defaultProps = {
  className: null,
};

export default MainMenu;
