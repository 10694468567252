// import Content from '@containers/NotificationCenter.js/components/Content';
import React, { useEffect, useState } from 'react';
import { Layout, Image, Row, Col, Card } from 'antd';
// import Breadcrumb from '@containers/Breadcrumb';
// import HeaderCelsia from '../../containers/Header';
import logoCompuesto from '@assets/img/logo/LogoCelsiaBlanco.png';
import ReactMarkdown from 'react-markdown'
import defaultSettings from '../../utils/services/default'
import './style.less';

const Faq = () => {
  const { Header, Content } = Layout;
  const [faqContenido, setFaqContenido] = useState('');
  //const breadcrumbData = [[null, 'Soporte'], [null, 'FAQ']];
  const cargarFaqs = () => {
    fetch(`${defaultSettings.default_md_endpoint}/faq/cpo.md`)
      .then((response) => {
        return response.text();
      })
      .then((recurso) => {
        setFaqContenido(recurso);
      })
  }

  useEffect(() => {
    cargarFaqs();
  });

  return (
    <Layout>
      <Header className="content-wrapper__header">
        <Image width={100} src={logoCompuesto} alt="Logo" className={'faq-logo'} />
      </Header>
      <Content className={'faq-content'}>
        <Row className={'faq-contenedor_md'}>
          <Col span={22}>
            <Card bordered={false}>
              <ReactMarkdown children={faqContenido} />
            </Card>
          </Col>
        </Row>

      </Content>
    </Layout>
  );
}

export default Faq;