import InProgressView from '../../../../views/InProgressView';

const path = '/configuracion-cuenta/roles';

const rolesMenu = ( t ) => {
  return {
    path,
    name: t('menus.roles'),
    icon: null,
    component:InProgressView,
    key: 'config-user-roles',
    children: [],
  }; 
}

export default rolesMenu;