import { get } from './management';

const cards_energy = ({ start_date, end_date }) => {
  let urlTemp = `/analitycs/cards-energy?start_date=${start_date}&end_date=${end_date}`;
  // console.log(urlTemp);
  return get(urlTemp, true);
}

const obtener_cargas = ({ locations_id = [], start_date, end_date, gt_1kw }) => {
  let locationsSelected = locations_id.join(',');

  let urlTemp = `/analitycs/recharges?locations_id=${locationsSelected}&start_date=${start_date}&end_date=${end_date}${!gt_1kw ? '' : '&gt_1kw=False'}`;
  // console.log(urlTemp);
  return get(urlTemp, true);
}

const obtener_usos = ({ locations_id, start_date, end_date, gt_1kw }) => {
  let locationsSelected = locations_id.join(',');

  let urlTemp = `/analitycs/usages?locations_id=${locationsSelected}&start_date=${start_date}&end_date=${end_date}${!gt_1kw ? '' : '&gt_1kw=False'}`;
  // console.log(urlTemp);
  return get(urlTemp, true);
}

const obtener_tarjetas = ({ client_id, start_date, end_date, gt_1kw }) => {
  let urlTemp = `/analitycs/cards?client_id=${client_id}&start_date=${start_date}&end_date=${end_date}${!gt_1kw ? '' : '&gt_1kw=False'}`;
  // console.log(urlTemp);
  return get(urlTemp, true);
}

const obtener_disponibilidad = ({ locations_id = [], start_date, end_date, gt_1kw }) => {
  let locationsSelected = locations_id.join(',');

  let urlTemp = `/analitycs/availabilty?locations_id=${locationsSelected}&start_date=${start_date}&end_date=${end_date}${!gt_1kw ? '' : '&gt_1kw=False'}`;
  // console.log(urlTemp);
  return get(urlTemp, true);
}

const obtener_energia_conector = ({ locations_id = [], start_date, end_date, gt_1kw }) => {
  let locationsSelected = locations_id.join(',');

  let urlTemp = `/analitycs/usage-time-energy?locations_id=${locationsSelected}&start_date=${start_date}&end_date=${end_date}${!gt_1kw ? '' : '&gt_1kw=False'}`;
  // console.log(start_date,end_date);
  // console.log(urlTemp);
  return get(urlTemp, true);
}

const obtener_energia_entregada = ({ locations_id = [], start_date, end_date, step, gt_1kw }) => {
  let locationsSelected = locations_id.join(',');
	
  let urlTemp = `/analitycs/total-energy?locations_id=${locationsSelected}&start_date=${start_date}&end_date=${end_date}&step=${step}${!gt_1kw ? '' : '&gt_1kw=False'}`;
  return get(urlTemp, true);
}

const getSessionsMetrics = ({ locations_id = [], start_date, end_date, step, gt_1kw }) => {
  let locationsSelected = locations_id.join(',');

  let urlTemp = `/analitycs/count-sessions?locations_id=${locationsSelected}&start_date=${start_date}&end_date=${end_date}&step=${step}${!gt_1kw ? '' : '&gt_1kw=False'}`;
  return get(urlTemp, true);
}

const obtener_emisiones = ({ locations_id = [], start_date, end_date, gt_1kw }) => {
  let locationsSelected = locations_id.join(',');

  let urlTemp = `/analitycs/total-emissions?locations_id=${locationsSelected}&start_date=${start_date}&end_date=${end_date}${!gt_1kw ? '' : '&gt_1kw=False'}`;
  return get(urlTemp, true);
}

export default { cards_energy, getSessionsMetrics, obtener_energia_entregada, obtener_energia_conector, obtener_cargas, obtener_usos, obtener_tarjetas, obtener_disponibilidad, obtener_emisiones }