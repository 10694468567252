import React from 'react';
import { UserOutlined } from '@ant-design/icons';

import InProgressView from '../../../../views/InProgressView';

const path = '/registro-logs';

const registroLogs = {
  path,
  name: 'Regristro logs',
  icon: <UserOutlined />,
  component: () => <InProgressView title="Sección en desarrollo" />,
  key: 'registro-logs',
  children: [],
};

export default registroLogs;
