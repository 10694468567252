import React, { useEffect, useState } from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend } from 'recharts';
import { Card, Table, Col, Button, Input, Select, message, Row, Empty, Divider } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { DateSelectorGroup } from '@components/ui';
import { apiSessions } from '@utils/services/cpo'
import getUTCString from '@utils/helpers/UtcRequestConverter'
import ExtractDurationTime from '@utils/helpers/ExtractDurationTime';
import getDateLocalString from '@utils/helpers/UtcResponseReceive'
import { Spanish } from '@utils/helpers/dictIdiomas'
import { export_to_csv } from '@utils/helpers/exportCSVUtil'
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { locationActions } from '@store/actions';
import moment from 'moment';
import 'moment/locale/es';
import locationsConst from '@store/constants/locationsConst'

const TablaActivos = (props) => {
  const defaultSessionObserver = {
    "key": "",
    "id": "",
    "raw_id": 0,
    "operador": "",
    "estacion": "",
    "raw_conector_id": 0,
    "conector_id": "",
    "conector": "",
    "estado": "CHARGING",
    "recarga_activa": [],
    "uso": "PUBLIC",
    "direccion": "",
    "acciones": ""
  }
  const dispatch = useDispatch();
  const { current_duration, current_duration_custom } = ExtractDurationTime;
  const { t, i18n } = useTranslation();
  const textLanguage = Spanish({ screen: 'evse-view' })
  const { recargas, cargadorId,cambioComand} = props;
  const { user, platform, locations: locationsItems } = useSelector((state) => state);
  const [powerBd, setPowerBd] = useState(0);
  const [socBd, setSocBd] = useState(0);
  const [energyBd, setEnergyBd] = useState(0);
  const [sessionObserver, setObserver] = useState(defaultSessionObserver)
  const [rechargeSelected, setRechargeSelected] = useState(null);
  const [rechargeSaved, setRechargeSaved] = useState(null);
  const [data, setData] = useState({
    data: [],
    dataFiltered: [],
    loading: false,
  });
  const [selectedRows, setSelectedRows] = useState({
    data: []
  });

  const [maxValueEnergia, setMaxValueEnergia] = useState(0);

  const dataGraphic = [
    {
      name: 'Page A',
      uv: 4000,
      pv: 2400,
      amt: 2400,
    },
    {
      name: 'Page B',
      uv: 3000,
      pv: 1398,
      amt: 2210,
    },
    {
      name: 'Page C',
      uv: 2000,
      pv: 9800,
      amt: 2290,
    },
    {
      name: 'Page D',
      uv: 2780,
      pv: 3908,
      amt: 2000,
    },
    {
      name: 'Page E',
      uv: 1890,
      pv: 4800,
      amt: 2181,
    },
    {
      name: 'Page F',
      uv: 2390,
      pv: 3800,
      amt: 2500,
    },
    {
      name: 'Page G',
      uv: 3490,
      pv: 4300,
      amt: 2100,
    },
  ];
  const columns = [
    {
      title: 'RFID',
      dataIndex: 'rfid',
      key: 'rfid',
      fixed: 'left',
      sorter: (a, b) => `${a.rfid}`.localeCompare(b.rfid),
      filters: [...new Set(data.data.map((e) => `${e.rfid}`))].map((e) => ({ text: `${e}`, value: `${e}` })),
      onFilter: (value, record) => record.rfid.indexOf(value) === 0,
    },
    {
      title: t('global.word.connector') + ' ID',
      dataIndex: 'conector_id',
      key: 'conector_id',
      sorter: (a, b) => `${a.conector_id}`.localeCompare(b.conector_id),
      filters: [...new Set(data.data.map((e) => `${e.conector_id}`))].map((e) => ({ text: `${e}`, value: `${e}` })),
      onFilter: (value, record) => record.conector_id.indexOf(value) === 0,
    },
    {
      title: t('global.word.connector'),
      dataIndex: 'conector_name',
      key: 'conector_name',
      sorter: (a, b) => `${a.conector_name}`.localeCompare(b.conector_name),
      filters: [...new Set(data.data.map((e) => `${e.conector_name}`))].map((e) => ({ text: `${e}`, value: `${e}` })),
      onFilter: (value, record) => record.conector_name.indexOf(value) === 0,
    },
    {
      title: t('global.word.start'),
      dataIndex: 'start',
      key: 'start',
      sorter: (a, b) => `${a.start}`.localeCompare(b.start),
      render: (e) => getDateLocalString(e),
    },
    {
      title: t('global.word.duration'),
      dataIndex: 'duracion',
      key: 'duracion',
      sorter: (a, b) => `${a.duracion}`.localeCompare(b.duracion),
      render: (e) => current_duration_custom(getDateLocalString(e))
    },
    {
      title: t('global.word.energy'),
      dataIndex: 'energia',
      key: 'energia',
      sorter: (a, b) => `${a.energia}`.localeCompare(b.energia),
      render: (e) => <p>{parseFloat(e >= 1000 ? (e / 1000) : e)}{e >= 1000 ? 'MWh' : 'kWh'}</p>,
    },
  ]
  const {
    LOCATION_UPDATE_ITEMS: update,
    LOCATION_SUCCESS_ITEMS: success,
  } = locationsConst;

  const formatYAxis = (value) => {
    const roundedValue = value.toFixed(2); // Redondea a 2 decimales
  
    if (roundedValue.endsWith('.00')) {
      return roundedValue.slice(0, -3); // Remueve los decimales y el punto si son cero
    }
  
    return roundedValue;
  };

  useEffect(() => {
    if (rechargeSaved) {
      setTimeout(() => {
        dispatch(locationActions.updateLocation(rechargeSaved));
      }, 2000);
    }
  }, [selectedRows]);

  useEffect(() => {
    let arrayLocations = locationsItems || null;
    if (arrayLocations && arrayLocations.length && data && data.data) {
      data.data.forEach(element => {
        let index = arrayLocations.findIndex(location => (location.id && location.id == element.location_id) || (location.raw_id && location.raw_id == element.evse_id))
        if (index >= 0) {
          console.log('index location.id: ', index, arrayLocations[index])
          arrayLocations[index].session_id = element.session_id;
          arrayLocations[index].recarga_activa = arrayLocations[index].recarga_activa || [];
        }
      });
      dispatch({ type: update, payload: arrayLocations });
    }

    if (locationsItems && locationsItems.locations && locationsItems.locations.length) {
      let locView = locationsItems.locations.filter((e) => e.id === cargadorId).filter((e) => e.conector_id === sessionObserver.conector_id)[0];

      if (locView && locView.recarga_activa && locView.recarga_activa.length) {
        let obj = locView.recarga_activa[locView.recarga_activa.length - 1];

        if (obj) {
          setPowerBd(obj.uv || '0.00');
          setSocBd(obj.soc || '0.00');
          setEnergyBd(obj.pv || '0.00');
          updateEnergyTable(obj);

          let itemMaxEnergia = Math.max(...locView.recarga_activa.map(item => item.pv)); 
          let itemMaxPotencia = Math.max(...locView.recarga_activa.map(item => item.uv));
          let valormax=0;
          if(itemMaxEnergia>itemMaxPotencia){
             valormax = parseFloat(itemMaxEnergia)+0.5;
            setMaxValueEnergia(valormax);  
           
          }else{
             valormax = parseFloat(itemMaxPotencia)+0.5;
            setMaxValueEnergia(valormax); 
          }

          console.log('****************************************************************')
          console.log('****************************************************************')
          console.log('locView.recarga_activa: ', locView.recarga_activa)
          console.log('updateEnergyTable: ', obj)
          console.log('valor maximo : ', valormax)

          

        }
      }
    }
  }, [locationsItems]);

  useEffect(() => {
    if (locationsItems && locationsItems.locations && locationsItems.locations.length) {
      locationsItems.locations.forEach(location => {
        data.data.forEach(recarga => {
          if ((location && location.location_id) && (recarga && recarga.location_id) && (location.location_id === recarga.location_id) && location.recarga_activa && location.recarga_activa.length) {
            // console.log('location.recarga_activa 2: ', location.recarga_activa)

            updateEnergyTable(location.recarga_activa[location.recarga_activa.length - 1]);
          }
        });
      });
    }
  }, [data]);

  useEffect(() => {
    cargarData();
  }, [cambioComand]);

  const getSessionsCSV = () => {
    message.loading(textLanguage.REQUEST_DOWNLOAD_CSV);
    export_to_csv(
      data.dataFiltered ? data.dataFiltered.map((e) => ({
        ...e,
        start: getDateLocalString(e.start),
        duracion: getDateLocalString(e.start)
      })) : null,
      data.data.map((e) => ({
        ...e,
        start: getDateLocalString(e.start),
        duracion: getDateLocalString(e.start)
      })),
      'Recargas'
    );
  }

  const findLocation = (e, i) => {
    console.log('******* setObserver 111 *********');
    setTimeout(() => {
      if (i[0]) {
        let locView = null;
        locView = locationsItems.locations.filter((e) => e.id === cargadorId).filter((e) => e.conector_id === i[0].conector_id)[0];

        if (locView) {
          setObserver(locView);
          loadChart(locView);
        } else {
          setObserver(defaultSessionObserver);
        }

        setRechargeSelected(i[0]);
      }
    }, 2000);
  }

  const rowSelection = {
    onSelectInvert: (s, sr, cr) => {
      // console.log(s, sr, cr);
    },
    onSelect: (s, sr, cr) => {
      setSelectedRows({ data: [s.key] });
    },
    onChange: (e, i) => {
      if (i[0]) {
        let locView = null;
        locView = locationsItems.locations.filter((e) => e.id === cargadorId).filter((e) => e.conector_id === i[0].conector_id)[0];

        console.log('******* setObserver 222 *********');

        if (locView) {
          setObserver(locView);
          loadChart(locView);
        } else {
          setObserver(defaultSessionObserver);
          findLocation(e, i);
        }

        setRechargeSelected(i[0]);
      } else {
        setRechargeSelected(null);
      }
    },
    getCheckboxProps: (e) => {
    },
  };

  const loadChart = (datos) => {
    let auxiliar = datos
    if (datos) {
      let sesion = data.data.find((item) => item.conector_id == datos.conector_id)
      if (sesion) {
        apiSessions.getSessionMeters(sesion.session_id)
          .then((rslt) => {
            rslt.forEach(element => {
              if (element) {
                let json = {
                  "session_id": sesion.session_id,
                  "evse_id": element.evse_id,
                  "connector_id": element.connector_id,
                  "soc": element.soc ? parseFloat(element.soc.toFixed(2)) : 0,
                  "client": (element.client_id ? element.client_id : data.data.client_id),
                  "power_active_unit": element.power_active_unit,
                  "power_active": element.power_active ? parseFloat(element.power_active.toFixed(2)) : 0,
                  "uv": element.power_active ? parseFloat(element.power_active.toFixed(2)) : 0,
                  "event": "METER_VALUES",
                  "value": element.value ? parseFloat(element.value.toFixed(2)) : 0,
                  "pv": element.value ? parseFloat(element.value.toFixed(2)) : 0,
                  "name": moment(element.time, 'YYYY-MM-DDTHH:mm:ss.000000Z').format('YYYY-MM-DD HH:mm:ss').toString(),
                  "location_id": element.location_id || null,
                };

                let index = auxiliar.recarga_activa.findIndex((item) => getDateLocalString(item.name) == getDateLocalString(json.name))
                if (index < 0) {
                  auxiliar.recarga_activa.unshift(json);
                  auxiliar.recarga_activa = auxiliar.recarga_activa
                    .sort((a, b) => {
                      return moment(getDateLocalString(a.name), 'YYYY-MM-DD HH:mm:ss') - moment(getDateLocalString(b.name), 'YYYY-MM-DD HH:mm:ss')
                    })
                    .reduce((acumulador, elemento) => {
                      if (acumulador.indexOf(item => item.name === elemento.name || moment(item.name).isSame(moment(elemento.name))) === -1) {
                        acumulador.push(elemento);
                      }
                      return acumulador;
                    }, []);
                  dispatch(locationActions.updateLocation({
                    name: json.name,
                    pv: json.pv,
                    soc: json.soc,
                    uv: json.uv,
                  }));

                }
              }
            });

            if (auxiliar && auxiliar.recarga_activa && auxiliar.recarga_activa.length) {
              let obj = auxiliar.recarga_activa[auxiliar.recarga_activa.length - 1];

              if (obj) {
                setPowerBd(obj.uv || '0.00');
                setSocBd(obj.soc || '0.00');
                setEnergyBd(obj.pv || '0.00');
                updateEnergyTable({ ...obj, session_id: sesion.session_id });
              }
            }
          }).catch((error) => {
            console.log('-- loadChart - error: ', error)
          });
      }
    }
  }

  const updateEnergyTable = (item) => {
    if (item && data && data.data) {
      let datos = data.data.map(dato => {
        let aux = dato
        if (aux && item && aux.session_id && item.session_id && (aux.session_id === item.session_id)) {
          if (aux.energia) {
            aux.energia = item.pv
          }
        }
        return aux
      })

      setData({
        data: datos,
        loading: false,
      });
    }
  }

  const cargarData = () => {
    setData({ ...data, loading: true });
    apiSessions.getActiveSession({
      evse_uid: cargadorId
    }).then((rslt) => {
      let datos = rslt.map((v, i) => {
        return {
          key: i,
          evse_id: cargadorId,
          session_id: v.id,
          location_id: v?.location_id?.id,
          client_id: v?.client_id?.id,
          conector_id: v.connector_id.conn_id,
          conector_name: v.connector_id.standard,
          rfid: v.tag_id,
          start: v.start_date_time,
          start_millis: new Date(v.start_date_time).getTime(),
          end: v.end_date_time,
          end_millis: new Date(v.end_date_time).getTime(),
          duracion: v.start_date_time,
          energia: `${v.measures?.value ? v.measures?.value : 0}`,
        }
      })

      setData({
        data: datos,
        loading: false,
      });
      console.log('datos: ', datos)
			
			if (datos[0]) {
				let locView = locationsItems.locations.filter((e) => e.id === cargadorId).filter((e) => e.conector_id === datos[0].conector_id)[0];
				
				console.log('locView: ', locView)
        console.log('******* setObserver 333 *********');
				if (locView) {
					setObserver(locView);
					loadChart(locView);
				} else {
					setObserver(defaultSessionObserver);
					// findLocation(e, i);
				}
			}

      /* let arrayLocations = locationsItems || null;
      if (arrayLocations && arrayLocations.length) {
        datos.forEach(element => {
          let index = arrayLocations.findIndex(location => (location.id && location.id == element.location_id) || (location.raw_id && location.raw_id == element.evse_id))
          if (index >= 0) {
            console.log('index location.id: ', index, arrayLocations[index]);
            arrayLocations[index].session_id = element.session_id;
            arrayLocations[index].recarga_activa = arrayLocations[index].recarga_activa || [];
          }
        });
        dispatch({ type: update, payload: arrayLocations });
      } */
    }).catch((err) => {
      console.log('error: ', err);
      message.warn(textLanguage.REQUEST_CON_ERROR);
      setData({ data: [], loading: false });
    });
  }

  const CustomizedAxisTick = (props) => {
    const { x, y, stroke, payload } = props;
    let hours = moment(payload.value, 'YYYY-MM-DD HH:mm:ss').hours();
    let min = moment(payload.value, 'YYYY-MM-DD HH:mm:ss').minutes();
    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={10} textAnchor="start" fill="#666" transform="rotate(15)">{`${hours > 9 ? hours : `0${hours}`}:${min > 9 ? min : `0${min}`}`}</text>
      </g>
    );
  };

  return (
    <Row gutter={[16, 16]} className={'tabla-recargas'}>
      <Col span={24} style={{ position: 'absolute', top: 0, right: 5 }} className={'recargas_filtro'}>
        <Button onClick={getSessionsCSV} style={{ float: 'right' }} type={'primary'}>{t('global.word.download')}</Button>
      </Col>

      <Col sm={24} lg={selectedRows.data.length ? 16 : 24} className={'recargas_tablas'}>
        <Table
          rowSelection={{
            columnTitle: t('states.showgraph'),
            type: 'radio',
            selectedRowKeys: selectedRows.data,
            ...rowSelection,
          }}
          onChange={(p, f, s, { currentDataSource }) => setData({ ...data, dataFiltered: currentDataSource ? currentDataSource : null })}
          columns={columns}
          dataSource={data.data}
          loading={data.loading}
        />
      </Col>
      {
        selectedRows.data.length > 0 ? (
          <Col sm={24} lg={8}>
            <Card title={t('metrics.card2.title')}>
              <Button type={'primary'} className={'btn-close-chart'} shape={'circle'} icon={<CloseOutlined color={'white'} />} onClick={() => setSelectedRows({ data: [] })} />
              {
                sessionObserver && sessionObserver.recarga_activa.length > 0 ?
                  (
                    <>
                      <p style={{ marginBottom: 0 }}>
                        {t('global.word.period')}:
                        <strong className="pl-1">{getDateLocalString(rechargeSelected.start)}</strong>
                      </p>
                      <p>
                        {t('global.word.duration')}:
                        <strong className="pl-1">{current_duration(rechargeSelected.start)}</strong>
                      </p>
                      <ResponsiveContainer height={300}>
                        <AreaChart
                          data={sessionObserver.recarga_activa.map((e) => ({
                            ...e,
                            name: `${getDateLocalString(e.name)}`,
                          }))
                          }
                          margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                          }}
                        >
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis dataKey="name" tick={<CustomizedAxisTick />} />
                          <YAxis yAxisId="left" />
                          <YAxis yAxisId="right" orientation="right"/>
                          <Tooltip />
                          <Legend />

                          <Area yAxisId={'left'} type="monotone" name={`${t('global.word.power')} (kW)`} dataKey="uv" fill={platform.main_color} fillOpacity={0.5} stroke={platform.main_color} activeDot={{ r: 8 }} />

                          <Area yAxisId={'right'} type="monotone" name={`Soc (%)`} dataKey="soc" fill={'gray'} fillOpacity={0.5} stroke={'gray'} activeDot={{ r: 8 }} />

                        </AreaChart>
                      </ResponsiveContainer>
                      <div className="content-chart-values">
                        <div className="d-flex justify-content-evenly">
                          <div className="chart-values">
                            {powerBd} (kW)
                          </div>
                          <div className="chart-values">
                            {socBd} (%)
                          </div>
                        </div>
                      </div>

                      <Divider />
                      <ResponsiveContainer height={300}>
                        <AreaChart
                          data={
                            sessionObserver.recarga_activa.sort((a, b) => {
                              return moment(getDateLocalString(a.name), 'YYYY-MM-DD HH:mm:ss') - moment(getDateLocalString(b.name), 'YYYY-MM-DD HH:mm:ss')
                            }).map((e) => ({
                              ...e,
                              name: `${getDateLocalString(e.name)}`,
                            }))
                          }
                          margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                          }}
                        >
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis dataKey="name" tick={<CustomizedAxisTick />} />
                          <YAxis yAxisId="left" />
                          <YAxis yAxisId="right" orientation="right" tickFormatter={formatYAxis} />
                          <Tooltip />
                          <Legend />

                          <Area yAxisId={'left'} type="monotone" name={`${t('global.word.power')} (kW)`} dataKey="uv" fill={platform.main_color} fillOpacity={0.5} stroke={platform.main_color} activeDot={{ r: 8 }} />

                          <Area yAxisId={'right'} type="monotone" name={`${t('global.word.energy')} (kwh)`} dataKey="pv" fill={'gray'} fillOpacity={0.5} stroke={'gray'} activeDot={{ r: 8 }} />

                        </AreaChart>
                      </ResponsiveContainer>
                      <div className="content-chart-values">
                        <div className="d-flex justify-content-evenly">
                          <div className="chart-values">
                            {powerBd} (kW)
                          </div>
                          <div className="chart-values">
                            {energyBd} (kwh)
                          </div>
                        </div>
                      </div>
                    </>
                  )
                  : <Empty style={{ marginTop: 10 }} />
              }
            </Card>
          </Col>
        ) : (
          ''
        )
      }
    </Row>

  );
}

export default TablaActivos;