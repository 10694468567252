import React, * as react from 'react';
import PropTypes from 'prop-types';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import './style.less';
import { Button, Row, Col, Card } from 'antd';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { apiProfiles } from '@utils/services/cpo';
import { useSelector, useDispatch } from 'react-redux';
import { chargeProfileActions } from '@store/actions';
import LayoutStep from '../components/LayoutStep/LayoutStep';
import Table from './components/Table/';
import Vehicules from './VehiculeCard/VehiculeCard';
import { FormularioPerfil } from './components';

const priorities = [[], [], []];

const taskStatus = {
  priority1: {
    name: 'Priority 1',
    items: priorities[2],
  },
  priority2: {
    name: 'Priority 2',
    items: priorities[1],
  },
  priority4: {
    name: 'No Priority',
    items: priorities[0],
  },
};

const onDragEnd = (result, columns, setColumns, dispatch) => {
  if (!result.destination) return;
  const { source, destination } = result;

  if (source.droppableId !== destination.droppableId) {
    const sourceColumn = columns[source.droppableId];
    const destColumn = columns[destination.droppableId];
    const sourceItems = [...sourceColumn.items];
    const destItems = [...destColumn.items];
    const [removed] = sourceItems.splice(source.index, 1);
    destItems.splice(destination.index, 0, removed);
    const data1 = {
      ...columns,
      [source.droppableId]: {
        ...sourceColumn,
        items: sourceItems,
      },
      [destination.droppableId]: {
        ...destColumn,
        items: destItems,
      },
    };
    const priority1 = data1.priority1.items;
    const priority2 = data1.priority2.items;
    const priority4 = data1.priority4.items;
    dispatch(chargeProfileActions.setChargeProfileVehicules([priority4, priority2, priority1]));
    setColumns(data1);
  } else {
    const column = columns[source.droppableId];
    const copiedItems = [...column.items];
    const [removed] = copiedItems.splice(source.index, 1);
    copiedItems.splice(destination.index, 0, removed);
    const data2 = {
      ...columns,
      [source.droppableId]: {
        ...column,
        items: copiedItems,
      },
    };
    const priority1 = data2.priority1.items;
    const priority2 = data2.priority2.items;
    const priority4 = data2.priority4.items;
    dispatch(chargeProfileActions.setChargeProfileVehicules([priority4, priority2, priority1]));
    setColumns(data2);
  }
};

function GridVehicules(props) {
  const dispatch = useDispatch();
  const { onPrevious, onSave, modeCharge } = props;
  const [columns, setColumns] = react.useState(taskStatus);
  const [formPerfilVisible, setFormPerfilVisible] = react.useState(false);
  const vehiculesProfile = useSelector(({ profiles }) => profiles.vehicules);
  const chargersSelected = useSelector(({ profiles }) => profiles.chargers);

  const numberOfChargers = useSelector(({ profiles }) => {
    if (profiles.chargers.selected.length > 0) {
      const chargers = profiles.chargers.selected.map((item) => {
        return item && item.connectors && item.connectors.length;
      });

      return chargers.reduce((a, b) => a + b, 0);
    } else {
      // console.log('no se pudo');
      return 0;
    }
  });
  const [idVehicule, setIdVehicule] = react.useState('');
  const [vehicules, setVehicules] = react.useState(vehiculesProfile);
  const [profiles, setProfiles] = react.useState({
    editProfile: false,
    id: '',
    name: '',
    charger: '',
    connector: 0,
  });

  const handleNuevoPerfilClick = () => {
    setProfiles({
      ...profiles,
      editProfile: false,
    });
    setFormPerfilVisible(true);
  };
  const handleEditPerfilClick = (item) => {
    setProfiles({
      editProfile: true,
      id: item.id,
      name: item.content.name,
      charger: item.content.charger,
      connector: item.content.connector,
    });
    setFormPerfilVisible(true);
  };
  const handleHidePerfilForm = () => {
    setProfiles({
      editProfile: false,
      id: '',
      name: '',
      charger: '',
      connector: 0,
    });
    setFormPerfilVisible(false);
  };

  react.useEffect(() => {
    setVehicules(vehiculesProfile);
    if (vehiculesProfile !== [[], [], []]) {
      setColumns({
        priority1: {
          name: 'Priority 1',
          items: vehiculesProfile[2],
        },
        priority2: {
          name: 'Priority 2',
          items: vehiculesProfile[1],
        },
        priority4: {
          name: 'No Priority',
          items: vehiculesProfile[0],
        },
      });
    }
  }, [vehiculesProfile]);

  react.useEffect(() => {
    if (idVehicule !== '') {
      const newProfiles = vehiculesProfile.map((priority) => {
        if (priority.length > 0) {
          const posObject = priority.findIndex((o) => o.id === idVehicule);
          if (posObject >= 0) {
            priority.splice(posObject, 1);
          }
          return priority;
        }
        return priority;
      });
      setIdVehicule('');
      dispatch(chargeProfileActions.setChargeProfileVehicules(newProfiles));
    }
  }, [idVehicule]);

  react.useEffect(() => {
    // console.log('respuesta ', columns);
  }, [columns]);

  return (
    <LayoutStep onSave={onSave} onPrevious={onPrevious}>
      <Row className="container-cargadores row-card">
        <Col span={11}>
          <Card className="cargadores-tabla-info">
            <div className="container-selected_item center-text">
              <p className="cards-text">
                {`${`Tienes seleccionado ${chargersSelected.selected.length} cargador${
                  (chargersSelected.selected.length > 1 && 'es') || ''
                }`} `}
              </p>
            </div>
          </Card>
        </Col>
        <Col span={2} />
        <Col span={11}>
          <Card className="cargadores-tabla-info">
            <div className="container-selected_item center-text">
              {chargersSelected.selected.length > 0 ? (
                <p className="potencia-info cards-text">{`Potencia total: ${chargersSelected.maximum_consumption.toFixed(
                  2
                )} kW`}</p>
              ) : (
                ''
              )}
            </div>
          </Card>
        </Col>
      </Row>
      <div style={{ display: 'flex', justifyContent: 'center', height: '100%' }}>
        <Button
          className="btn-nuevo_perfil"
          onClick={handleNuevoPerfilClick}
          type="primary"
          icon={<FontAwesomeIcon icon={faPlus} className="btn-icon" />}
        >
          Nuevo Vehiculo
        </Button>
      </div>
      <div>
        <div style={{ display: 'flex', justifyContent: 'center', height: '100%' }}>
          {modeCharge !== 'SOC' ? (
            <DragDropContext
              onDragEnd={(result) => onDragEnd(result, columns, setColumns, dispatch)}
            >
              {Object.entries(columns).map(([columnId, column], index) => {
                return (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                    key={columnId}
                  >
                    <h2>{column.name}</h2>
                    <div style={{ margin: 8 }}>
                      <Droppable droppableId={columnId} key={columnId}>
                        {(provided, snapshot) => {
                          let colorBack;
                          let colorBack2;
                          if (column.name === 'Priority 1') {
                            colorBack = '#f79786';
                            colorBack2 = '#f64424';
                          } else if (column.name === 'Priority 2') {
                            colorBack = '#f7dc86';
                            colorBack2 = '#f0bd1c';
                          } else {
                            colorBack = 'lightgrey';
                            colorBack2 = 'lightblue';
                          }
                          return (
                            <div
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                              style={{
                                background: snapshot.isDraggingOver ? colorBack2 : colorBack,
                                padding: 4,
                                width: 250,
                                minHeight: 500,
                              }}
                            >
                              {column.items.map((item, index) => {
                                return (
                                  <Draggable key={item.id} draggableId={item.id} index={index}>
                                    {(provided, snapshot) => {
                                      return (
                                        <div
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          style={{
                                            userSelect: 'none',
                                            padding: 2,
                                            margin: '0 0 8px 0',
                                            height: '300px',
                                            borderRadius: '10',
                                            backgroundColor: snapshot.isDragging
                                              ? '#263B4A'
                                              : '#456C86',
                                            ...provided.draggableProps.style,
                                          }}
                                        >
                                          <Vehicules
                                            item={item}
                                            setIdVehicule={setIdVehicule}
                                            handleNuevoPerfilClick={handleEditPerfilClick}
                                          />
                                        </div>
                                      );
                                    }}
                                  </Draggable>
                                );
                              })}
                              {provided.placeholder}
                            </div>
                          );
                        }}
                      </Droppable>
                    </div>
                  </div>
                );
              })}
            </DragDropContext>
          ) : (
            <Table
              numberOfChargers={numberOfChargers}
              columns={columns}
              setIdVehicule={setIdVehicule}
              handleNuevoPerfilClick={handleEditPerfilClick}
            />
          )}
        </div>
      </div>

      <FormularioPerfil
        profiles={profiles}
        visible={formPerfilVisible}
        handleHidePerfilForm={handleHidePerfilForm}
      />
    </LayoutStep>
  );
}

GridVehicules.propTypes = {
  onSave: PropTypes.func.isRequired,
  onPrevious: PropTypes.func.isRequired,
};

export default GridVehicules;
