import React,{ useEffect, useState } from 'react';
import { Row,Col,Card, Table, Form, Input, Select, message } from 'antd';
import './style.less';
import { apiProfiles,apiCommands,apiLocations } from '@utils/services/cpo'
import LayoutStep from '../components/LayoutStep';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux'; 
import { chargeProfileActions } from '@store/actions';

const Cargadores = (props) => {
    const dispatch = useDispatch();
    // const listChargers = useSelector(({ profiles }) => profiles.listChargers);
    const profileSetup = useSelector(({ profiles }) => profiles.profileSetup);
    const chargers = useSelector(({ profiles }) => profiles.chargers);
    const { Option } = Select;
    const { onPrevious, onNext, setIdChargers, listChargers} = props;
    const [ form ] = Form.useForm();
    const [ profiles , setProfiles ] = useState({
        data:[
            // {
            //     value:'1',
            //     name:'profile 1'
            // }
        ],
        loading:false,
    });

    const [ connectors , setConnectors ] = useState({
        selected:listChargers,
        maximum_consumption:0,
        filters:{
            locations:[],
            profiles:[],
            connectors:[],
        },
        data:[
            // {
            //     key: '1',
            //     location: 'Unicentro',
            //     connector: 'connector_1',
            //     profile: 'profile 1',
            //     description:'prueba'
            // },
            // {
            //     key: '2',
            //     location: 'Unicentro',
            //     connector: 'connector_1',
            //     profile: 'profile 1',
            //     description:'prueba'
            // },
        ],
        loading:false,
    });

    const columns = [
        {
            title: 'Cargador',
            dataIndex: 'connector',
            key: 'connector',
            filters: connectors.filters.connectors,
            onFilter: (value, record) => record.connector.indexOf(value) === 0,
            sorter: (a, b) => a.connector.length - b.connector.length,
        },
        {
            title: 'Estación',
            dataIndex: 'location',
            key: 'location',
            filters: connectors.filters.locations,
            onFilter: (value, record) => record.location.indexOf(value) === 0,
            sorter: (a, b) => a.location.length - b.location.length,
        },
        // {
        //     title: 'Perfil',
        //     dataIndex: 'profile',
        //     key: 'profile',
        //     filters: connectors.filters.profiles,
        //     onFilter: (value, record) => record.profile.indexOf(value) === 0,
        //     sorter: (a, b) => a.profile.length - b.profile.length,
        // },
        {
            title: 'Descripción',
            dataIndex: 'description',
            key: 'description',
        },
    ]

    useEffect( () => {
        loadProfiles();
        loadConnectors();
    },[]);


    useEffect(()=>{
        if(profileSetup.editProfile){
            setConnectors({...connectors, selected: listChargers, maximum_consumption: chargers.maximum_consumption})
            setIdChargers(chargers.selected);
        }
    },[])

    const loadProfiles = () => {
        setProfiles({...profiles,loading:true});
        apiProfiles.getProfiles().then( (rslt) => {
            setProfiles({selected:[],data:rslt,loading:false});
        }).catch( (err) => {
            setProfiles({...profiles,loading:false})
        });
    }

    const loadConnectors = () => {
        setConnectors({...connectors,loading:true});
        apiLocations.getEvsesProfiles().then( (rslt) => {

            let locationFilter = [];
            let perfilFilter = [];
            let connectorsFilter = [];

            const dataConnectors = rslt.map((v,i)=>{
                let profiles = '';
                let descriptions = '';
                locationFilter.push(v.location.name);

                // const connectorData = await apiLocations.getEvsesDetail(v.evse_id);
                // console.log('detail evse id: ', connectorData);
                if(v.profiles.length > 0){
                    let profTemps = v.profiles.filter( (v,i,self) => {
                        return v.active;
                    });

                    if(profTemps[0]){
                        perfilFilter.push(profTemps[0].profile.name);
                        profiles = profTemps[0].profile.name;
                        descriptions = `${profTemps[0].profile.value} ${profTemps[0].profile.unit}`;
                    } 
                } 

                connectorsFilter.push(v.evse_id);

                return {
                    key:v.evse_id,
                    location:v.location.name,
                    connector:v.evse_id,
                    profile: profiles === '' ? 'Ninguno' : profiles,
                    description:profiles === '' ? 'Ninguno' : `Max ${descriptions}`,
                    maximum_consumption:v.maximum_consumption,
                }
            });

            locationFilter = locationFilter.filter( (item,pos,self) => {
                return self.indexOf(item) === pos;
            }).map( (v,i) => {
                return {
                    text:v,
                    value:v,
                }
            });

            perfilFilter = perfilFilter.filter( (item,pos,self) => {
                return self.indexOf(item) === pos;
            }).map( (v,i) => {
                return {
                    text:v,
                    value:v,
                }
            });

            connectorsFilter = connectorsFilter.filter( (item,pos,self) => {
                return self.indexOf(item) === pos;
            }).map( (v,i) => {
                return {
                    text:v,
                    value:v,
                }
            });
            

            setConnectors({filters:{connectors:connectorsFilter,profiles:perfilFilter,locations:locationFilter},selected:listChargers,data:dataConnectors,loading:false, maximum_consumption:chargers.maximum_consumption});
        }).catch( (err) => {
            setConnectors({...connectors,loading:false});
            console.log('err: ',err);
        });
    }

    const handleOnSelectChange = async ( selectedRowKey ) => {
        let tPotencia = 0;
        for(let i = 0 ; i < selectedRowKey.length ; i++){
            const rawItem = connectors.data.filter( (v,i2,self) => v.key === selectedRowKey[i])[0];
            if(rawItem) tPotencia += rawItem.maximum_consumption;
        }

        const getConnectors = await Promise.all(selectedRowKey.map(async (value)=>{
            message.info(`Cargando Conectores Relacionado al Cargador ${value}`)
            const connectorData = await apiLocations.getEvsesDetail(value);
            return {
                name: value,
                connectors: connectorData.connectors
            };
        }))
        const chargersSelected = {...connectors,selected:selectedRowKey,maximum_consumption:tPotencia};
        setConnectors(chargersSelected);
        const {maximum_consumption, selected} = chargersSelected;
        dispatch(chargeProfileActions.setChargeProfileChargers({maximum_consumption, selected: getConnectors}))
        setIdChargers(selected);
    }

    return(
        <LayoutStep onPrevious={onPrevious} onNext={onNext}>
            <Row className={'container-cargadores'}>
            <Col span={24} >
                <Card className={'cargadores-tabla-info'}>
                    <div className={'container-selected_item'}>
                        <p>{
                        `${connectors.selected.length > 0 
                        ? `Tienes seleccionado ${connectors.selected.length} cargador${connectors.selected.length > 1 && 'es' || ''}` 
                        : `No tienes seleccionado un cargador`} `}
                        </p>
                        {
                            connectors.selected.length > 0 ? (
                                <p className={'potencia-info'}>{`Potencia total: ${ connectors.maximum_consumption } W`}</p>
                            ) : ''
                        }
                    </div>
                </Card>
            </Col>

            <Col span={24}>
                <Card>
                    <Table 
                    rowSelection={{
                        selectedRowKeys: connectors.selected,
                        onChange:handleOnSelectChange,
                    }}
                    loading={connectors.loading} 
                    dataSource={connectors.data} 
                    columns={columns}/>
                </Card>
            </Col>
            </Row>
        </LayoutStep>
    );
}

Cargadores.propTypes = {
    onNext: PropTypes.func.isRequired,
    onPrevious: PropTypes.func.isRequired,
};

export default Cargadores;