import React,{ useEffect, useState } from 'react';
import { Card, Table,Row,Col } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faPlug,faBolt, faClock, faMapMarkerAlt, faChargingStation, faSortUp, faInfoCircle, faQuestionCircle, faCircle, faBuilding } from '@fortawesome/free-solid-svg-icons';
import GetStatusDict from '@utils/helpers/estadosConector';
import getDateLocalString from '@utils/helpers/UtcResponseReceive'
import { useTranslation } from 'react-i18next'
const TablaConectores = ( props ) => {
    const { t, i18n } = useTranslation();
    const { conectores,cambioComand,loading } = props;

    const [ data , setData ] = useState([]);

    useEffect(()=> {
        // console.log(conectores);
        setData(conectores.map((v,i) => {
            const ls = getDateLocalString(v.last_charger_data.start_date_time);
            const le = getDateLocalString(v.last_charger_data.end_date_time);
            const te = v.last_charger_data.total_energy;
            return {
                key:i,
                conector:v.standard,
                estado:v.status_connector,
                potencia:`${v.max_electric_power}kW (${v.max_amperage}A)`,
                rfid:v.last_charger_data.card_code ? `${v.last_charger_data.card_code}` : t('global.word.notfound'),
                last_start: ls && ls !== 'No registra' ? v.last_charger_data.start_date_time : t('global.word.notfound'),
                last_start_millis:new Date(v.last_charger_data.start_date_time).getTime(),
                last_end:le && le !== 'No registra' ? v.last_charger_data.end_date_time : t('global.word.notfound'),
                last_end_millis:new Date(v.last_charger_data.last_end_millis).getTime(),
                energia:te ? `${(te>=1000?(te/1000):te).toFixed(2)} ${te>=1000?'MWh':'kWh'}` : t('global.word.notfound'),
                duracion:v.last_charger_data.total_period_time_secs  ? v.last_charger_data.total_period_time_secs : t('global.word.notfound'),
            }
        }));
    },[conectores,cambioComand]);

    const columns = [
        {
            title: t('global.word.connector'),
            dataIndex: 'conector',
            key: 'conector',
            fixed:'left',
            sorter: (a, b) => {
                return `${a.conector}`.localeCompare(b.conector);   
            },
            filters: [ ...new Set(data.map((e) => `${e.conector}`))].map((e) => ({text:`${e}`,value:`${e}`})),
            onFilter: (value, record) => record.conector.indexOf(value) === 0,
        },
        {
            title: t('global.word.state'),
            dataIndex: 'estado',
            key: 'estado',
            sorter: (a, b) => `${a.estado}`.localeCompare(b.estado),
            render:(v)=>{
                return(
                    <p><FontAwesomeIcon size={'1x'} style={{marginRight:6}} color={`${GetStatusDict(v)[1]}`}  icon={faCircle} />{GetStatusDict(v,t)[0]}</p>
                )
            },
            filters: [ ...new Set(data.map((e) => `${e.estado}`))].map((e) => ({text:`${GetStatusDict(e)[0]}`,value:`${e}`})),
            onFilter: (value, record) => record.estado.indexOf(value) === 0,
        },
        {
            title: t('global.word.power'),
            dataIndex: 'potencia',
            key: 'potencia',
            sorter: (a, b) => `${a.potencia}`.localeCompare(b.potencia),
        },
        {
            title: 'RFID',
            dataIndex: 'rfid',
            key: 'rfid',
            sorter: (a, b) => `${a.rfid}`.localeCompare(b.rfid),
            filters: [ ...new Set(data.map((e) => `${e.rfid}`))].map((e) => ({text:`${e}`,value:`${e}`})),
            onFilter: (value, record) => record.rfid.indexOf(value) === 0,
        },
        {
            title: t('evse.column.laststart'),
            dataIndex: 'last_start',
            key: 'last_start',
            sorter: (a, b) => `${a.last_start_millis}`.localeCompare(b.last_start_millis),
            render:(e) => getDateLocalString(e),
        },
        {
            title: t('evse.column.lastend'),
            dataIndex: 'last_end',
            key: 'last_end',
            sorter: (a, b) => `${a.last_end_millis}`.localeCompare(b.last_end_millis),
            render:(e) => getDateLocalString(e),
        },
        {
            title: t('global.word.energy'),
            dataIndex: 'energia',
            key: 'energia',
            sorter: (a, b) => `${a.energia}`.localeCompare(b.energia),
        },
        {
            title: t('global.word.duration'),
            dataIndex: 'duracion',
            key: 'duracion',
            sorter: (a, b) => `${a.duracion}`.localeCompare(b.duracion),
        },
    ]

    return(
        <Row>
            <Col span={24} className={'tabla-conectores'}>
                <Table key={'tabla-conectores'}
                    columns={columns}
                    dataSource={data}
                    loading={loading}
                />
            </Col>
        </Row>
        
    );
}


export default TablaConectores;