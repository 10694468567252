import React, { useEffect } from 'react';
import { Modal, Form, Select, Input, Button } from 'antd';
import './style.less';
import { apiProfiles } from '@utils/services/cpo'
const FormularioPerfil = (props) => {

  const {
    visible,
    handleHidePerfilForm,
    loadProfiles,
    editProfile,
  } = props;
  const { Option } = Select;

  const [form] = Form.useForm();

  const handleCancelarClick = () => {
    form.resetFields();
    handleHidePerfilForm();
  }

  const handleSubmitPerfil = () => {
    form.validateFields().then((rslt) => {
      // console.log(rslt);
      if (rslt.errorFields) return;
      if (editProfile) {
        apiProfiles.updateProfile({
          ...rslt,
          id: editProfile.id
        }).then((rslt) => {
          // console.log(rslt);
          loadProfiles();
          handleCancelarClick();
        }).catch((err) => {
          console.log('err: ', err)
        });
      } else {
        apiProfiles.postProfile(rslt).then((rslt) => {
          // console.log(rslt);
          loadProfiles();
          handleCancelarClick();
        }).catch((err) => {
          console.log('err: ', err)
        });
      }
    }).catch((e) => {
      console.error(e);
    });
  }

  useEffect(() => {
    if (!editProfile) {
      form.resetFields();
    } else {
      // console.log(editProfile);
      form.setFieldsValue({
        name: editProfile.name,
        unit: editProfile.unit.substring(0, 1),
        value: editProfile.value,
      });
    }
  }, [editProfile]);

  return (
    <Modal onCancel={handleCancelarClick} footer={false} className={'container-form-perfil'} visible={visible}>
      <p>{editProfile ? `Editar perfil\n${editProfile.name}` : `Nuevo perfil`}</p>
      <Form form={form} layout={'vertical'}>
        {/* <Form.Item 
                name={'profile_id'}
                rules={[{required:true,message:'Debes seleccionar un perfil'}]} 
                label={'Tipo de perfil'}>
                    <Select placeholder={'Selecciona un perfil'}>
                    </Select>
                </Form.Item> */}
        <Form.Item
          name={'name'}
          rules={[{ required: true, message: 'Debes asignar un nombre' }]}
          label={'Nombre'}>
          <Input placeholder={'Ingrese un nombre'} />
        </Form.Item>
        <Form.Item
          name={'unit'}
          initialValue={null}
          rules={[{ required: true, message: 'Debes asignar una unidad' }]}
          label={'Unidad'}>
          <Select placeholder={'Seleccione unidad'}>
            <Option value={'A'}>Amperes</Option>
            <Option value={'W'}>Watts</Option>
          </Select>
        </Form.Item>
        <Form.Item
          name={'value'}
          rules={[{ required: true, message: 'Debes asignar un valor' }]}
          label={'Valor máximo'}>
          <Input type={'number'} placeholder={'Ingrese valor (A/W)'} />
        </Form.Item>

        <div className={'btn-form'}>
          <Button onClick={handleCancelarClick}>Cancelar</Button>
          <Button onClick={handleSubmitPerfil} type={'primary'}>Guardar y enviar</Button>
        </div>
      </Form>
    </Modal>
  );
}

FormularioPerfil.defaultProps = {
  visible: false
}

export default FormularioPerfil;