import React from 'react';
import { Breadcrumb } from 'antd';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import './style.less';

const BreadcrumbComp = (props) => {
  const { breadcrumbData, extra } = props;
  return (
    <div id="breadcrumb">
      <Breadcrumb>
        {breadcrumbData.map((itemArray) => {
          const [path, name] = itemArray;
          return (
            <Breadcrumb.Item key={name}>
              {path ? <Link to={path}>{name}</Link> : <>{name}</>}
            </Breadcrumb.Item>
          );
        })}
      </Breadcrumb>
      {extra}
    </div>
  );
};

BreadcrumbComp.propTypes = {
  breadcrumbData: PropTypes.arrayOf(PropTypes.array),
  extra: PropTypes.element,
};

BreadcrumbComp.defaultProps = {
  breadcrumbData: [],
  extra: null,
};

export default BreadcrumbComp;
