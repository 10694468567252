import React, { useEffect, useState } from 'react';
import {/* Typography, */ Card, Table, Tabs } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faCheck,faTimes } from '@fortawesome/free-solid-svg-icons';
import { comprobar_permiso } from '@utils/auth/permissions';
import { IconDeletePopup } from '@components/ui';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next'
// const { Paragraph } = Typography;
const { TabPane } = Tabs;



const TablaUsuarios = (props) => {
    const { t, i18n } = useTranslation();
    const [pageSize, setPageSize] = useState(10);
    const { user,platform } = useSelector((state) => state);
    const { data, loading, refreshData, re_invitar, editar_user, eliminar_user  } = props;

    const onChange = (page, pageSize) => {
        setPageSize(pageSize);
      };
    

    const columns = [
        {
            title:t('global.word.name'),
            dataIndex:'nombre',
            key:'nombre',
            defaultSortOrder: 'ascend',
            fixed:'left',
            sorter:(a,b) => a.nombre.localeCompare(b.nombre),
            filters: [ ...new Set(data.map((e) => `${e.nombre}`))].map((e) => ({text:`${e}`,value:`${e}`})),
            onFilter: (value, record) => record.nombre.indexOf(value) === 0,
        },
        {
            title:t('global.word.email'),
            dataIndex:'correo',
            key:'correo',
            sorter:(a,b) => a.correo.localeCompare(b.correo),
            filters: [ ...new Set(data.map((e) => `${e.correo}`))].map((e) => ({text:`${e}`,value:`${e}`})),
            onFilter: (value, record) => record.correo.indexOf(value) === 0,
        },
        {
            title:t('global.word.rol'),
            dataIndex:'rol',
            key:'rol',
            sorter:(a,b) => {
                // console.log(a,b);
                if(a.rol !== undefined && b.rol !== undefined){
                    return a.rol[0].localeCompare(b.rol[0])
                }else if(a.rol === undefined && b.rol !== undefined){
                    return -1;
                }else if(a.rol !== undefined && b.rol === undefined){
                    return 1;
                }
                
            },
            filters: [ ...new Set(data.map((e) => `${e.rol ? e.rol : t('users.tables.noprofile')}`))].map((e) => ({text:`${e}`,value:`${e}`})),
            onFilter: (value, record) => record.rol ? record.rol === value : value === t('users.tables.noprofile'),   
            render:(e) => e ? e : t('users.tables.noprofile')
        },
        {
            title:t('global.word.lastactivity'),
            dataIndex:'last_login',
            key:'last_login',
            sorter:(a,b) => {
                let dateA = new Date(a.last_login);
                let dateB = new Date(b.last_login);
                return dateA.getTime() - dateB.getTime();
            },
        },
        {
            title:t('global.word.state'),
            dataIndex:'estado',
            key:'estado',
            sorter: (a,b) => a.estado - b.estado,
            filters: [ {text:t('global.word.active') , value:1},{text:t('global.word.deactive') , value:0}],
            onFilter: (value, record) => record.estado === value,
            render: ( text , record, index ) => {
                return(
                    <IconDeletePopup icon={text === 1 ? faCheck : faTimes} style={{  
                        fontSize: 20,
                        color:text === 1 ? `${platform.main_color}` : "gray", 
                        cursor:`${comprobar_permiso(user,12) ? 'pointer' : 'arrow'}`,
                        pointerEvents:`${comprobar_permiso(user,12) ? 'all' : 'none'}`,
                    }} eliminarCliente={() => eliminar_user(record)} title={text === 0 ? `${t('users.tables.popchecked')}` : `${t(`users.tables.popnochecked`)}`} record={record}  />
                );
            }
        },
        {
            title:t('global.word.actions'),
            dataIndex:'acciones',
            key:'acciones',
            render: (text, record, index) => {
                return (
                <div style={{
                    display:"flex",
                    justifyContent:"space-around",
                }}>
                    
                    <p style={{ fontSize: 16,color:`${platform.main_color}`,margin:0, cursor:"pointer"} } onClick={() => re_invitar(record)} >{t('users.btn.sendinvitation')}</p>
                    <FontAwesomeIcon icon={faPen} onClick={() => editar_user(record)} style={{ fontSize: 20,color:`${platform.main_color}`,cursor:"pointer", visibility:`${comprobar_permiso(user,20) ? `visible` : `hidden`}`, }} />
                    {/* <IconDeletePopup title={record.estado !== "activo" ? `¿Deseas reactivar este usuario?` : `¿Estas seguro que deseas eliminarlo?` } record={record} eliminarCliente={() => eliminar_user(record)}/> */}
                </div>
                )
            }
        },
    ]

    useEffect( () => {
        refreshData();
    },[]);

    return(
        <Card>
            <h4>{t('users.table.title')}</h4>
            <div className="filtros-tabla">
                <Table
                columns={columns}
                dataSource={data}
                tableLayout="auto"
                loading={loading}
                pagination={{
                pageSize,
                pageSizeOptions: [10, 20, 50],
                showSizeChanger: true,
                onChange:onChange,
                }} />
            </div>
        </Card>
    );
}

export default TablaUsuarios;