import { CHARGE_PROFILE_VEHICULES, CHARGE_PROFILE_GENERAL_INFO, CHARGE_PROFILE_CHARGERS, CHARGE_PROFILE_SETUP, CHARGE_PROFILE_LIST } from '../constants/chargeProfilesConstants';

const setChargeProfileVehicules = (values) => (dispatch) => {
  dispatch({
    type: CHARGE_PROFILE_VEHICULES,
    payload: values,
  });
};

const setChargeProfileGeneralInfo = (values) => (dispatch) => {
  dispatch({
    type: CHARGE_PROFILE_GENERAL_INFO,
    payload: values,
  });
};

const setChargeProfileChargers = (values) => (dispatch) => {
  dispatch({
    type: CHARGE_PROFILE_CHARGERS,
    payload: values,
  });
};

const setChargeProfileSetup = (values) => (dispatch) => {
  dispatch({
    type: CHARGE_PROFILE_SETUP,
    payload: values,
  });
};

const setChargeProfileList = (values) => (dispatch) => {
  dispatch({
    type: CHARGE_PROFILE_LIST,
    payload: values,
  });
};

export default { setChargeProfileVehicules, setChargeProfileGeneralInfo, setChargeProfileChargers, setChargeProfileSetup, setChargeProfileList };
