import React from 'react';
import { Typography } from 'antd';
import PropTypes from 'prop-types';

import './style.less';

const { Title } = Typography;

const InProgressView = ({ title }) => {
  return (
    <div id="in-progress-view">
      <img src="https://media.giphy.com/media/xUA7bdpLxQhsSQdyog/giphy.gif" alt="inProgress" />
      <Title className="title" level={2}>
        {title}
      </Title>
    </div>
  );
};

InProgressView.propTypes = {
  title: PropTypes.string,
};

InProgressView.defaultProps = {
  title: '',
};

export default InProgressView;
