import React from 'react';
import PropTypes from 'prop-types';

import styles from './SpecialCard.module.less';

function SpecialCard(props) {
  const { children, className } = props;
  return <div className={`${styles.card} ${className}`}>{children}</div>;
}

SpecialCard.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

SpecialCard.defaultProps = {
  className: null,
  children: null,
};

export default SpecialCard;
