/* eslint-disable no-undef */
import { setTenantUrl } from '@utils/services/management';
import { apiUser } from '@utils/services';
import { UPDATE_USER } from '../constants/userConst';

const updateUser = (user, clientSelected) => {
  const { user_clients: clients } = user;
  let clientTarget = null;

  if (clients.length > 0) {
    clientTarget = clientSelected || clients[0];
    const { client_domain: domain } = clientTarget;
    setTenantUrl(domain);
  }

  return { type: UPDATE_USER, payload: { ...user, clientSelected: clientTarget } };
};

const fetchUser = () => async (dispatch, getState) => {
  let user;

  if (!user) {
    try {
      user = await apiUser.getUserData();
    } catch (error) {
      return null;
    }
  } else {
    user = { user_data: user };
  }

  const { clientSelected } = getState().user;

  const { user_data: userData } = user;
  return dispatch(updateUser(userData, clientSelected));
};

export default { updateUser, fetchUser };
