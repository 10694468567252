const default_settings = {
  /**
     * Backend endpoint
     */
  default_endpoint: 'https://backend.cargadores.celsia.com', // DEV
  // default_endpoint: 'https://cargadores-qa-backend.celsia.com',  // QA
  // default_endpoint: 'https://backend.cargadores.celsia.com',
  // default_endpoint: 'http://localhost:8000',
  // default_endpoint: 'http://52.167.27.217',
  // default_endpoint: window.ENV.URL_BACKEND,

  /**
     * Sockets endpoint
     */
  socket_default_endpoint: 'https://realtime.cargadores.celsia.com', // DEV
  // socket_default_endpoint: 'https://cargadores-qa-realtime.celsia.com/', // QA
  // socket_default_endpoint: 'https://realtime.cargadores.celsia.com',
  // socket_default_endpoint: window.ENV.URL_REALTIME,

  /**
     * FAQ md enpoint
     */
  default_md_endpoint: 'https://cpobackenddev001.blob.core.windows.net', // DEV
  // default_md_endpoint: 'https://cpobackendqa001.blob.core.windows.net', // QA
  // default_md_endpoint: 'https://cpobackendqa001.blob.core.windows.net',
  // default_md_endpoint: window.ENV.URL_FAQ_MD
};
export default default_settings;
