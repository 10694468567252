import React from 'react';
import { Button } from 'antd';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { faArrowRight, faArrowLeft, faSave } from '@fortawesome/free-solid-svg-icons';

import styles from './LayoutStep.module.less';

function LayoutStep(props) {
  const { t } = useTranslation();
  const { children, onPrevious, onNext, onSave, saveLoading } = props;
  return (
    <div className={styles.wrapper}>
      {children && <div className={styles.content}>{children}</div>}

      <div className={styles.buttons}>
        {onPrevious && (
          <Button onClick={onPrevious}>
            <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: 12 }} />
            { t('menus.back') }
          </Button>
        )}
        {onNext && (
          <Button type="primary" onClick={onNext}>
            { t('menus.next') }
            <FontAwesomeIcon icon={faArrowRight} style={{ marginLeft: 12 }} />
          </Button>
        )}
        {onSave && (
          <Button
            loading={saveLoading}
            className={styles.buttons_save}
            type="primary"
            onClick={onSave}
            icon={<FontAwesomeIcon icon={faSave} style={{ marginRight: 8 }} />}
          >
            { t('menus.save') }
          </Button>
        )}
      </div>
    </div>
  );
}

LayoutStep.propTypes = {
  children: PropTypes.node,
  onNext: PropTypes.func,
  onPrevious: PropTypes.func,
  onSave: PropTypes.func,
  saveLoading: PropTypes.bool,
};

LayoutStep.defaultProps = {
  children: null,
  onNext: null,
  onPrevious: null,
  onSave: null,
  saveLoading: false,
};

export default LayoutStep;
