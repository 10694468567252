import React, { useEffect, useState, } from 'react';
import { Card, Col, Form, Row, Input, Select, message, Button, Upload, Spin } from 'antd';
import { CameraFilled,InboxOutlined } from '@ant-design/icons'
import { apiConfigClientes,apiProfileM } from '@utils/services'
import Breadcrumb from '@containers/Breadcrumb';
import { SketchPicker  } from 'react-color';
import { ColorPicker } from './components'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux';
import { platformActions } from '@store/actions';
import './style.less'

const { Dragger } = Upload;
const { Option } = Select;

const PerfilesUsuarios = ( props ) => {
    const { t, i18n } = useTranslation();
    const [form] = Form.useForm();
    const {platform:platformState,user} = useSelector( (state) =>  state);
    const breadcrumbData = [[null,t('config.profile.header')]];

    const [ mainColor, setMainColor ] = useState('');
    const [ secondaryColor, setSecondaryColor ] = useState('');

    const [ clientes, setClientes ] = useState({
        loading:false,
        data:[],
        selected:null,
    }); 
    
    const [ fileUpload, setFileUpload ] = useState({
        loading:false,
        imageUrl:''
    })

    const [ iconUpload, setIconUpload ] = useState({
        loading:false,
        imageUrl:''
    })

    useEffect(() => {
        loadClients();
    },[]);

    useEffect(() => {
        // console.log(clientes.selected);
        if(clientes.selected) getProperties(clientes.selected)
    },[clientes.selected]);

    const handleFont = (value) => {
        window.less.modifyVars({
            
        });
    }

    const getProperties = ( id ) => {
        // console.log(id);
        setClientes({...clientes,loading:true});
        setIconUpload({...iconUpload,loading:true});
        setFileUpload({...fileUpload,loading:true});
        apiProfileM.getProfile(id).then( (rslt) => {
            // console.log(rslt);
            if(!rslt[0]){
                form.resetFields([
                    "company_name",
                    "font"
                ]);
                setIconUpload('');
                setFileUpload('');
                setMainColor("");
                setSecondaryColor("");
            }else{
                const properties = rslt[0];
                form.setFieldsValue({
                    company_name:properties.name,
                    font:'Roboto',
                })
                setIconUpload({loading:false,imageUrl:properties.icon});
                setFileUpload({loading:false,imageUrl:properties.logo});
                setMainColor(properties.color_1);
                setSecondaryColor(properties.color_2);
            } 
            setClientes({...clientes,loading:false});
        }).catch( (err) => {
            console.log('err: ',err);
        });
    }

    const loadClients = () => {
        setClientes({...clientes,loading:true});
        apiConfigClientes.clientes().then( (rslt) => {
            setClientes({loading:false,data:rslt});
        }).catch( (err) => {
            setClientes({...clientes,loading:false});
            message.warn('algo paso!');
            console.log('err: ',err);
        });
    }

    const handleReset = () => {
        getProperties(clientes.selected)
    }


    function getBase64(img, callback) {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    }

    const getBase64FromURL = ( img ) => {
        var canvas = document.createElement("canvas");
        canvas.setAttribute('position','absolute')
        canvas.width = img.width;
        canvas.height = img.height;
        // console.log(canvas.width,canvas.height);
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0,img.width,img.height);
        var dataURL = canvas.toDataURL("image/png");
        return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
    }

    const handleSaveProfile = () => {
        // console.log(platformState);

        // MODO LOCAL
        // if(user.clientSelected.client_id === form.getFieldValue('client')){
        //     window.less.modifyVars({
        //         '@primary-color':mainColor,
        //     });
            
        //     platformActions.update_platform(platformState,{icon:iconUpload.imageUrl,logo:fileUpload.imageUrl,main_color:mainColor});
        // }
        


        // MODO ONLINE
        form.validateFields().then( (rslt) => {
            // console.log(rslt,mainColor,secondaryColor);
            // window.less.modifyVars({
            //     '@primary-color':`${mainColor}`,
            // });
            apiProfileM.updateProfile({
                client:rslt.client,
                name:`${rslt.company_name}`,
                logo:`${fileUpload.imageUrl}`.startsWith('https') ? getBase64FromURL(document.getElementById('logo-preview')) : `${fileUpload.imageUrl}`,
                icon:`${iconUpload.imageUrl}`.startsWith('https') ? getBase64FromURL(document.getElementById('icon-preview')) : `${iconUpload.imageUrl}`,
                url:`${rslt.font}`,
                color_1:`${mainColor}`,
                color_2:`${secondaryColor}`,
            }).then( (rslt) => {
                // console.log(rslt);
                if(!rslt.client){
                    message.warn("Debes completar todos los campos, inlcuido logos / iconos");
                    // return;
                }

                if(user.clientSelected.client_id === rslt.client){
                    document.title = `${rslt.name}`;
                    document.querySelector("link[rel*='icon']").href = `${rslt.icon}`;
                    window.less.modifyVars({
                        '@primary-color':mainColor,
                        '@font-family':`"${rslt.url}", sans-serif`,
                    });
                    
                    window.less.refreshStyles();
                    
                    platformActions.update_platform(platformState,{icon:rslt.icon,logo:rslt.logo,main_color:mainColor});
                }
                
                message.success('Actualizado correctamente');
            }).catch( (err) => {
                message.warn(rslt.client ? `${t('profile.form.warning.noclient')}` : `${t('profile.form.warning.inputs')}`)
                console.log('err: ',err);
            });

            
            
        }).catch( (err) => {
            console.log('err: ',err);
            message.warn('Error al subir la información')
        });
        
    }

    const previewImage = (file) => {
        // console.log(file);
        getBase64(file, (url) => {
            // console.log(url);
            setFileUpload({...fileUpload,imageUrl:url});
        })
        
        return false;
    }

    const previewIcon = (file) => {
        // console.log(file);
        getBase64(file, (url) => {
            // console.log(url);
            setIconUpload({...fileUpload,imageUrl:url});
        })
        
        return false;
    }

    return(
        <Row className={'container-user-profile'}>
            <Col span={24}>
                <Breadcrumb breadcrumbData={breadcrumbData}/>
            </Col>

            <Col sm={24} lg={24} className={'main-container'}>
                <Card className={'container-profile'}>
                    <Spin className={'loading-profile'} spinning={clientes.loading} tip={'loading'}/>
                    <Row>
                        <Col sm={14} lg={10} >
                            <div className={'form-container'}>
                                <Form form={form} name={'form-settings'} labelCol={{span:12}} wrapperCol={{span:12}}>
                                    <Form.Item name={'client'} label={t('global.word.client')}>
                                        <Select  onChange={(e) => setClientes({...clientes,selected:e})} loading={clientes.loading} className={'input-perfil'} placeholder={t('global.word.extraclient')}>
                                            {
                                                clientes.data.map((e,i) => (
                                                    <Option key={`${e.name}_${i}`} value={e.id}>
                                                        {e.name}
                                                    </Option>
                                                ))
                                            }
                                        </Select>
                                    </Form.Item>
                                    <Form.Item name={'company_name'} label={t('profile.form.companyname')}>
                                        <Input className={'input-perfil'} placeholder={t('global.word.extraname')} />
                                    </Form.Item>
                                    <Form.Item name={'main_color'} label={t('profile.form.maincolor')}>
                                        <ColorPicker colorDefault={mainColor} setColorHandler={setMainColor}  className={'input-perfil'} />
                                    </Form.Item>
                                    <Form.Item name={'secondary_color'} label={t('profile.form.altercolor')}>
                                        <ColorPicker colorDefault={secondaryColor} setColorHandler={setSecondaryColor} className={'input-perfil'} />
                                    </Form.Item>
                                    <Form.Item name={'font'} label={t('global.word.font')}>
                                        <Select disabled  placeholder={t('global.word.selectone')} value={'Roboto'}>
                                            <Option value={'Roboto'}>Roboto</Option>    
                                            <Option value={'Raleway'}>Raleway</Option>    
                                        </Select> 
                                        {/* <Input className={'input-perfil'} placeholder={'https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap'} /> */}
                                    </Form.Item>

                                    <div className={'btn-container'}>
                                        <Button type={'primary'} onClick={handleSaveProfile}>{t('profile.form.save')}</Button>
                                        <Button type={'default'} onClick={handleReset}>{t('profile.form.clear')}</Button>
                                    </div>
                                </Form>
                            </div>
                        </Col>
                        <Col sm={10} lg={14}>
                            <Row>
                                <Col sm={24} lg={24} className={'container-preview'}>
                                    <p className={'container-title'}>{t('profile.form.title.logo')}</p>
                                    <Dragger 
                                    name={'logo'}
                                    listType={'picture'}
                                    className={'input_file'}
                                    multiple={false}
                                    beforeUpload={previewImage}
                                    // action={'https://www.mocky.io/v2/5cc8019d300000980a055e76'}
                                    showUploadList={false}>
                                        {
                                            fileUpload.imageUrl ? <img crossOrigin={'anonymous'} id={'logo-preview'} className={'image-preview'} src={fileUpload.imageUrl} /> : (
                                                <>
                                                    <p className="ant-upload-drag-icon">
                                                    <InboxOutlined />
                                                    </p>
                                                    <p className="ant-upload-text">{t('profile.form.img.logo')}</p>
                                                    <p className="ant-upload-text">{t('profile.form.img.logo.size')}</p>
                                                </>
                                            )
                                        }
                                        
                                    </Dragger>
                                </Col>
                                <Col sm={24} lg={24} className={'container-preview'}>
                                    <p className={'container-title'}>{t('profile.form.title.icon')}</p>
                                    <Dragger 
                                    name={'icono'}
                                    listType={'picture'}
                                    className={'input_file'}
                                    multiple={false}
                                    beforeUpload={previewIcon}
                                    // action={'https://www.mocky.io/v2/5cc8019d300000980a055e76'}
                                    showUploadList={false}>
                                        {
                                            iconUpload.imageUrl ? <img crossOrigin={'anonymous'} id={'icon-preview'} className={'image-preview'} src={iconUpload.imageUrl} /> : (
                                                <>
                                                    <p className="ant-upload-drag-icon">
                                                        <InboxOutlined />
                                                    </p>
                                                    <p className="ant-upload-text">{t('profile.form.img.icon')}</p>
                                                    <p className="ant-upload-text">{t('profile.form.img.icon.size')}</p>
                                                </>
                                            )
                                        }
                                        
                                    </Dragger>
                                </Col>
                            </Row>
                            
                        </Col>
                        
                    </Row>        
                </Card>
            </Col>
        </Row>
    );

}

export default PerfilesUsuarios;