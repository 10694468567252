
import moment from 'moment';
import 'moment/locale/es';

const getLocalString = (date) => {
  let localDate = date;
  let dates = '';
	
	try {
    dates = `${localDate.getFullYear()}-${localDate.getMonth() + 1}-${localDate.getDate()} ${localDate.getHours()}:${localDate.getMinutes()}:${localDate.getSeconds()}`
  } catch (err) {
    localDate = new Date(`${localDate}`);
		let ano = localDate.getFullYear()
		let mes = localDate.getMonth() + 1
		let dia = localDate.getDate()
		let hora = localDate.getHours()
		let minuto = localDate.getMinutes()
		let segundos = localDate.getSeconds()

    if (isNaN(ano) || !ano || ano == 'NAN') {
			dates = moment(localDate, 'YYYY-MM-DDTHH:mm:ss.000000Z').format('YYYY-MM-DD HH:mm:ss').toString();
		} else {
			dates = `${ano}-${mes}-${dia} ${hora}:${minuto}:${segundos}`
		}
  }
	return dates;
}

export default getLocalString;