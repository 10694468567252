import React from 'react';
import { Route, Switch, useRouteMatch, Redirect } from 'react-router-dom';

import { TableOrganizaciones } from './views';
const ConfigClientes = () => {
  const match = useRouteMatch();

  const clientesRoute = `${match.path}`;

  return (
    <Switch>
      <Route path={clientesRoute} component={TableOrganizaciones} />
      <Redirect to={clientesRoute} />
    </Switch>
  );
};

export default ConfigClientes;