import React from 'react';
import { Modal, Row, Col, Form, Input, Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons'
import { apiLocations } from '@utils/services/cpo'
import { useTranslation } from 'react-i18next'
const FormularioBrand = (props) => {
  const { t, /* i18n */ } = useTranslation();
  const {
    isVisible,
    setVisible,
    dataFormSend,
    setDataForm,
  } = props;
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  const [formBrand] = Form.useForm();

  const handleCancel = () => {
    setVisible(false);
  }

  const limpiarForm = () => {
    formBrand.resetFields();
    handleCancel();
  }

  const sendRequest = () => {
    formBrand.validateFields().then((rslt) => {
      // console.log(rslt);
      if (rslt.errorFields) return;
      apiLocations.createBrand(rslt)
        .then((rslt) => {
          // console.log(rslt);
          limpiarForm();
          if (dataFormSend && setDataForm) {
            setDataForm({ ...dataFormSend, evse_brand: rslt.id })
          }
        })
        .catch((err) => {
          console.log('err: ', err);
        });
    }).catch((err) => {
      //console.log(err);
    });

  }

  return (
    <Modal visible={isVisible} onCancel={handleCancel}
      width={500}
      style={{
        textAlign: 'center',
      }}
      footer={false}>
      <Row style={{
        justifyContent: 'center'
      }}>
        <Col span={18}>
          <h2
            style={{
              marginBottom: '30px'
            }}
          >{t('form.brand.header')}</h2>
          <Form style={{ marginBottom: 15 }} {...layout}
            name={'form-brand'}
            form={formBrand}>
            <Form.Item
              name={'description'}
              label={t('form.charger.brand')}
              rules={[{ required: true, message: t('global.word.inputrequire') }]}>
              <Input placeholder={t('global.word.extravalue')} />
            </Form.Item>
          </Form>

        </Col>
        <Col span={24} style={{ marginTop: 10 }}>
          <Button type={'default'} onClick={limpiarForm} style={{ marginRight: 10 }} >{t('form.brand.btn.cancel')}</Button>
          <Button onClick={sendRequest} icon={<PlusOutlined />} type='primary'>{t('form.brand.btn.add')}</Button>
        </Col>
      </Row>
    </Modal>
  );
}

FormularioBrand.defaultProps = {
  isVisible: true,
  form: {
    brand: '',
    model: '',
  },
}


export default FormularioBrand;