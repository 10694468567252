import { apiAlertas } from '../../utils/services';
import alertsConst from '@store/constants/alertasConst'

const getAlertas = (user) => async (dispatch, getState) => {
    const {
        ALERTS_FETCHING_ITEMS: fetching,
        ALERTS_SUCCESS_ITEMS: success,
        ALERTS_ERROR_ITEMS: error,
    } = alertsConst;

    let response;
    dispatch( { type:fetching });
    try{
        response = await apiAlertas.obtener_alertas(user);
    }catch(err){
        return dispatch({type:error,payload:err});
    }

    const { results, next, previous } = response;

    const payload = { 
        alertas:results, 
        nextPage:next,
        previusPage:previous,
     }
    return dispatch({ type: success, payload});
}

const updateAlertas = ( json ) => async (dispatch, getState) => {
    // const { } = getState();
    console.log(json);
    const alertasObject = getState().alertas;
    const arrayAlertas = alertasObject.alertas;

    const { 
        ALERTS_UPDATE_ITEMS : update,
        ALERTS_SUCCESS_ITEMS: success,
    } = alertsConst;

    dispatch({type:update});

    const itemUpdated = {
        check: false,
        connector_id: json.connector_id,
        created: json.created,
        evse: json.evse_uid,
        id: 0,
        last_report: json.created,
        location: json.location,
        operator: json.operator,
        type_alert: json.message.type_alert_code,
        type_use: json.type_use,
    }

    const payload = [ ...arrayAlertas , itemUpdated ];

    const dResult = dispatch({ type: update , payload })
    return dResult;
    

}

export default {
    getAlertas,
    updateAlertas
}