import React from 'react';
import { Route, Switch, useRouteMatch, Redirect } from 'react-router-dom';
import './style.less'
import PerfilesComponent from './perfil';
import PerfilesCargaCrear from '@views/PerfilesCarga';

const PerfilesCarga = () => {

  const match = useRouteMatch();

  const mapRoute = `${match.path}/tablas`;
  const vehicleRouteCreate = `${match.path}/crear-perfil`;
  const vehicleRouteEdit = `${match.path}/editar-perfil`;
  // const breadcrumbData = [[null, 'Perfiles de carga']]

  return (

    <Switch>
      <Route path={mapRoute} component={PerfilesComponent} />
      <Route path={vehicleRouteCreate} component={PerfilesCargaCrear} />
      <Route path={vehicleRouteEdit} component={PerfilesCargaCrear} />
      <Redirect to={mapRoute} />
    </Switch>

  );
}


export default PerfilesCarga;