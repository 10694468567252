/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartBar, faCar, faChartLine, faEye } from '@fortawesome/free-solid-svg-icons';
import { mainMenuIconBaseProps as iconProps } from '@utils/params';
import { SpecialIcons } from '@components/ui';
import InProgressView from '@views/InProgressView';
import PerfilesCargaView from '@views/TablaPerfilesCarga';

const { FleetIcon } = SpecialIcons;

const path = '/perfilescarga';

const PerfilesRecarga = ( t ) => {
  return{
    path,
    name: t('menus.chargeprofile'),
    icon: <FontAwesomeIcon {...iconProps} icon={faChartBar} />,
    key: 'perfilescarga',
    component:PerfilesCargaView,
    children:[
      {
        path: `${path}/tablas`,
        name: '',
        key: 'perfilescarga',
      },
      {
        path: `${path}/crear-perfil`,
        name: '',
        key: 'perfilescarga',
      },
    ]
  }
  
};

export default PerfilesRecarga;
