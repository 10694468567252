import React, { useEffect,useState } from 'react';
import { useTranslation } from 'react-i18next'
import { Table } from 'antd';
import './style.less';
const TableRecharges = ( props ) => {
    const { t, i18n } = useTranslation();
    const { dataRequest } = props;
    const [ data , setData ] = useState({
        data:[],
        loading:false,
    });

    const columns = [
        {
            title: t('global.word.station'),
            dataIndex: 'location',
            key: 'location',
            fixed: 'left',
            filters: [ ...new Set(data.data.map((e) => e.location))].map((e) => ({text:e,value:e})),
            onFilter: (value, record) => record.location.indexOf(value) === 0,
            sorter: (a, b) => `${a.location}`.localeCompare(b.location),
        },
        {
            title: t('global.word.charger'),
            dataIndex: 'evse',
            key: 'evse',
            filters: [ ...new Set(data.data.map((e) => e.evse))].map((e) => ({text:e,value:e})),
            onFilter: (value, record) => record.evse.indexOf(value) === 0,
            sorter: (a, b) => `${a.evse}`.localeCompare(b.evse),
        },
        {
            title: t('global.word.connector'),
            dataIndex: 'connector',
            key: 'connector',
            filters: [ ...new Set(data.data.map((e) => e.connector))].map((e) => ({text:e,value:e})),
            onFilter: (value, record) => record.connector.indexOf(value) === 0,
            sorter: (a, b) => `${a.connector}`.localeCompare(b.connector),
        },
        {
            title: t('global.word.type'),
            dataIndex: 'type',
            key: 'type',
            filters: [ ...new Set(data.data.map((e) => e.type))].map((e) => ({text:e,value:e})),
            onFilter: (value, record) => record.type.indexOf(value) === 0,
            sorter: (a, b) => `${a.type}`.localeCompare(b.type),
        },
        {
            title: t('global.word.energy'),
            dataIndex: 'energy',
            key: 'energy',
            // sorter: (a, b) => `${a.energy}`.localeCompare(b.energy),
            sorter: (a, b) => a.energy === b.energy ? 0 : (a.energy > b.energy ? 1 : -1),
            render: (obj) => (`${obj.toFixed(2)}kWh`)
        },
        {
            title: t('metrics.available.percent'),
            dataIndex: 'status',
            key: 'status',
            sorter: (a, b) => parseFloat(a.status) - parseFloat(b.status),
            render: (obj) => (`${parseInt(obj)} % `)
        },
    ]

    useEffect(() => {
        if(dataRequest && dataRequest.data) dataRequestInterpeter();
    },[]);

    useEffect(() => {
        dataRequestInterpeter();
    },[dataRequest.data]);

    const dataRequestInterpeter = () => {
        const arrayData = dataRequest.data;
        const arrayConnectors = [];

        for(let i = 0 ; i < arrayData.length ; i++){
            let location = arrayData[i];
            let connectors = location.connectors;
            const sOnline = location.status && location.status.ONLINE ? location.status.ONLINE : 0;
            const sAvailable = location.status && location.status.AVAILABLE ? location.status.AVAILABLE : 0;
            for(let c = 0 ; c < connectors.length ; c++){
                let connector = connectors[c];

                arrayConnectors.push({
                    key:`${i}${c}`,
                    location:location.location_name,
                    evse:location.evse_uid,
                    connector:connector.conn_id,
                    type:connector.standard,
                    energy:connector.energy,
                    status: sOnline + sAvailable
                });
            }
        }

        setData({...data,data:arrayConnectors});
    }

    return(
        <Table 
            className={'table-recharges'}
            loading={!dataRequest ? data.loading : dataRequest.loading}
            dataSource={data.data}
            columns={columns}
        />
    );
}

TableRecharges.defaultProps = {
    dataRequest:{
        data:[],
        loading:false
    }
}

export default TableRecharges;