import React from 'react';
import { Route, Switch, useRouteMatch, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { TablesView } from './views';
const GeneralAlertas = () => {
  const match = useRouteMatch();
  const user = useSelector((state) => state.user);
  const alertaRoute = `${match.path}`;

  return (
    <Switch>
      {/* <Route path={tableRoute} component={TablesView} /> */}
      <Route path={alertaRoute} component={TablesView} />
      <Redirect to={alertaRoute} />
    </Switch>
  );
};

export default GeneralAlertas;
