import React, { useEffect, } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { useAuth } from '@utils/auth/createAuthProvider';
import { userActions } from '@store/actions';
import Main from '@views/Main';
import Sign from '@views/Sign';
import RecuperarContrasena from '@views/RecuperarContrasena';
// import queryString from 'querystring';
import { useTranslation } from 'react-i18next'
// import NotFound from './views/NotFound';
// import { useSelector } from 'react-redux';
import Faq from './views/FAQ';
// import { apiConfigClientes } from '@utils/services';
// import { platformActions } from '@store/actions';

const MainRoutes = (props) => {
  // const platform = useSelector((state) => state.platform);

  const { userClients, fetchUser } = props;
  // const { t, i18n } = useTranslation();
  const [logged] = useAuth();
  // const location = useLocation();

  useEffect(() => {
    if (logged && userClients.length === 0) {
      fetchUser();
    }
    // eslint-disable-next-line
  }, [userClients, fetchUser]);

  return (
    <>
      <Switch>
        <Route path="/recuperar-contrasena/:uid/:token" component={RecuperarContrasena} />
        <Route path="/faq" component={Faq} />
        {!logged && (
          <>
            {/* <Route component={NotFound} /> */}
            <Route exact={false} path="/ingreso-cpo" component={Sign} />
            <Redirect to="/ingreso-cpo" />
          </>
        )}

        {logged && userClients.length > 0 && (
          <>
            <Route path="/" component={Main} />
          </>
        )}
      </Switch>
    </>
  );
};

MainRoutes.propTypes = {
  userClients: PropTypes.arrayOf(PropTypes.object).isRequired,
  fetchUser: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    userClients: state.user.user_clients,
  };
};

const mapDispatchToProps = (dispatch) => {
  const { fetchUser } = userActions;
  return {
    fetchUser: () => dispatch(fetchUser()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MainRoutes);
