import React from 'react';
import { Typography } from 'antd';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faPlug,faBolt, faClock, faMapMarkerAlt, faChargingStation, faSortUp, faInfoCircle, faQuestionCircle, faCircle, faBuilding } from '@fortawesome/free-solid-svg-icons';

const { Paragraph } = Typography;

const ChargerInfoItem = (props) => {
  const { className, name, value } = props;  

  const iconMap = ( name ) => {
    switch(name){
      case 'Estación':
        return <FontAwesomeIcon size={'1x'} style={{marginRight:6}} color={'gray'}  icon={faBuilding} />;
      case 'Uso':
        return <FontAwesomeIcon size={'1x'} style={{marginRight:6}} color={'gray'}  icon={faBuilding} />;
      case 'Operador':
        return <FontAwesomeIcon size={'1x'} style={{marginRight:6}} color={'gray'}  icon={faBuilding} />;
      case 'Marca':
        return <FontAwesomeIcon size={'1x'} style={{marginRight:6}} color={'gray'}  icon={faChargingStation} />;
      case 'Modelo':
        return <FontAwesomeIcon size={'1x'} style={{marginRight:6}} color={'gray'}  icon={faBuilding} />;
      case 'Ubicación':
        return <FontAwesomeIcon size={'1x'} style={{marginRight:6}} color={'gray'}  icon={faMapMarkerAlt} />;
      case 'Última petición recibida':
        return <FontAwesomeIcon size={'1x'} style={{marginRight:6}} color={'gray'}  icon={faClock} />;
    }
    return null;
  }

  return (
    <div className={className} style={{display:'flex'}}>
      <Paragraph strong style={{marginRight:'5px'}}>{iconMap(name)}</Paragraph>
      <Paragraph type="secondary">{`${value}`}</Paragraph>
    </div>
  );
};

ChargerInfoItem.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
};

ChargerInfoItem.defaultProps = {
  className: null,
  name: null,
  value: null,
};

export default ChargerInfoItem;
