import store from '@store';
import { useEffect, useState } from 'react';

import { userActions } from '@store/actions';
import createTokenProvider from './createTokenProvider';

const { dispatch } = store;

export const createAuthProvider = () => {
  const tokenProvider = createTokenProvider();

  const login = (newTokens, userData) => {
    tokenProvider.setToken(newTokens, userData);
    dispatch(userActions.updateUser(userData));
  };

  const logout = () => {
    tokenProvider.setToken(null);
    dispatch({ type: 'RESET_STORE' });
  };

  const useAuth = () => {
    const [isLogged, setIsLogged] = useState(tokenProvider.isLoggedIn());

    useEffect(() => {
      const listener = (newIsLogged) => {
        setIsLogged(newIsLogged);
      };

      tokenProvider.subscribe(listener);
      return () => {
        tokenProvider.unsubscribe(listener);
      };
    }, []);

    return [isLogged];
  };

  const getToken = async () => {
    const token = await tokenProvider.getToken();
    return token;
  };

  return {
    useAuth,
    login,
    logout,
    getToken,
  };
};

export const { useAuth, login, logout, getToken } = createAuthProvider();
