import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCreditCard } from '@fortawesome/free-solid-svg-icons';
import TarjetasGeneral  from '@views/TarjetasRecargas';

const path = '/tarjetas-para-recarga/tarjetas-clientes';

const tarjetasParaRecarga = ( t ) => {
  return {
    path,
    name: t('menus.rfid'),
    icon: <FontAwesomeIcon icon={faCreditCard} className="anticon" style={{ marginRight: 10, fontSize: 21, minWidth: "auto" }}  />,
    key: 'tarjetas-para-recarga',
    // isSubMenu: true,
    component:TarjetasGeneral,
    children: [/*{
      path: `${path}/tarjetas-clientes`,
      name: 'Administrar tarjetas clientes',
      component: () => <TarjetasGeneral/>,
      key: 'item-1',
    },*/],
  };
}

export default tarjetasParaRecarga;
