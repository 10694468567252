import React from 'react';
import { Spin, Typography, Space } from 'antd';
import PropTypes from 'prop-types';

const { Paragraph } = Typography;

const AbsoluteLoading = ({ size, text, direction, spaceSize, align }) => {
  return (
    <Space direction={direction} align={align} size={spaceSize}>
      <Spin size={size} />
      {text && <Paragraph strong>{text}</Paragraph>}
    </Space>
  );
};

AbsoluteLoading.propTypes = {
  size: PropTypes.oneOf(['default', 'small', 'large']),
  spaceSize: PropTypes.oneOf(['default', 'small', 'large']),
  direction: PropTypes.oneOf(['horizontal', 'vertical']),
  text: PropTypes.string,
  align: PropTypes.oneOf(['center', 'start', 'end']),
};

AbsoluteLoading.defaultProps = {
  size: 'default',
  direction: 'horizontal',
  text: null,
  spaceSize: 'large',
  align: 'center',
};

export default AbsoluteLoading;
