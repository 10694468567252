import React,{ useEffect, useState } from 'react';
import {Row,Col, Select} from 'antd';
import { useHistory,useLocation } from 'react-router-dom';
import { useSelector, } from 'react-redux';
import { useTranslation } from 'react-i18next'
import './style.less';

const LanguageSelector = ( props ) => {
    const { selectStyle } = props;
    const history = useHistory();
    const location = useLocation();
    const { Option } = Select;
    const { t, i18n } = useTranslation();
    const [ language, setLanguage ] = useState(localStorage.getItem('ln') || 'es');
    useEffect(() => {
        if(location.search !== ''){
            const urlParams = new URLSearchParams(location.search);
            console.log(urlParams.get('ln'));
            if(urlParams.get('ln')) setLanguage(urlParams.get('ln')); 
        }else{
            i18n.changeLanguage(language);
        }
    },[]);
    
    useEffect(()=>{
        console.log(language);
        i18n.changeLanguage(language);
        localStorage.setItem('ln',language); 
    },[language]);



    return(
        <div  className={'language_background'}>
            <Select className={selectStyle ? selectStyle : ''} value={language} onChange={(e)=>setLanguage(e)}>
                <Option value={'es'} title={'Spanish'}>ES</Option>
                <Option value={'en'} title={'English'}>EN</Option>
            </Select>
        </div>
    );
}

export default LanguageSelector;