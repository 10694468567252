import React, {useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Button, Card, Popover  } from 'antd';

const IconDeletePopup = ( props ) => {

    const { eliminarCliente, record, title ,icon, style } = props;

    const [ visible, setVisible ] = useState(false);

    const contentEliminar = () => {
        return (
            <Card bordered={false}>
                <h3>{title ? `${title}` : `¿Estas seguro que deseas eliminarlo?`}</h3>
                <div 
                style={{
                    display:'flex',
                    justifyContent:"flex-end"
                }}>
                    <Button
                    style={{
                        borderColor:'#EE7400',
                        color:'#EE7400',
                        marginLeft:'10px'
                    }}
                    onClick={ () => setVisible(false)}
                    >
                        No
                    </Button>
                    <Button
                    style={{
                        backgroundColor:'#EE7400',
                        color:'white',
                        marginLeft:20
                    }}
                    onClick={ () => eliminar()}
                    >
                        Si
                    </Button>
                </div>
            </Card>
        );
    }

    const eliminar = () => {
        eliminarCliente(record);
        setVisible(false);
    }

    const handleVisibleChange = ( v ) => {
        setVisible(v);
    }


    return(
        <Popover placement="right" content={contentEliminar} trigger="click" visible={visible} onVisibleChange={handleVisibleChange}  >
            <FontAwesomeIcon  icon={icon ? icon : faTrashAlt} style={style ? style: { fontSize: 20,color:"#FF812E", cursor:"pointer" }} />
        </Popover>
    )
}

export default IconDeletePopup;