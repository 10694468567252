import React, { useEffect, useState } from 'react';
import { Card, Table, Col, Button, Input, Select, message, Row, Empty, Divider } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { DateSelectorGroup } from '@components/ui';
import { apiSessions } from '@utils/services/cpo'
import getUTCString from '@utils/helpers/UtcRequestConverter'
import getDateLocalString from '@utils/helpers/UtcResponseReceive'
import { Spanish } from '@utils/helpers/dictIdiomas'
import { export_to_csv } from '@utils/helpers/exportCSVUtil'
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import useSocket from '@hooks/useSocket';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend } from 'recharts';

const TablaRecargas = (props) => {
  useSocket();
  const { t, i18n } = useTranslation();
  const textLanguage = Spanish({ screen: 'evse-view' })
  const { recargas, cargadorId,cambioComand } = props;
  const { user, platform } = useSelector((state) => state);
  const [data, setData] = useState({
    data: [],
    dataFiltered: [],
    loading: false,
  });

  const [rechargeSelected, setRechargeSelected] = useState(null);
  const [maxValueEnergia, setMaxValueEnergia] = useState(0);

  const [selectedRows, setSelectedRows] = useState({
    data: []
  });

  const rangoHoy = () => {
    let hoyTemp1 = new Date();
    let hoyTemp2 = new Date();

    hoyTemp1.setHours(0);
    hoyTemp1.setMinutes(0);
    hoyTemp1.setMilliseconds(0);

    hoyTemp2.setHours(23);
    hoyTemp2.setMinutes(59);
    hoyTemp2.setMilliseconds(59);

    return [hoyTemp1, hoyTemp2]
  }

  const [rangoDate, setRangoDate] = useState(rangoHoy());

  const onChangeDateRange = (start, end) => {
    let dateStart = new Date(start);
    let dateEnd = new Date(end);

    setRangoDate([dateStart, dateEnd]);
  };

  const dataGraphic = [
    {
      name: 'Page A',
      uv: 4000,
      pv: 2400,
      amt: 2400,
    },
    {
      name: 'Page B',
      uv: 3000,
      pv: 1398,
      amt: 2210,
    },
    {
      name: 'Page C',
      uv: 2000,
      pv: 9800,
      amt: 2290,
    },
    {
      name: 'Page D',
      uv: 2780,
      pv: 3908,
      amt: 2000,
    },
    {
      name: 'Page E',
      uv: 1890,
      pv: 4800,
      amt: 2181,
    },
    {
      name: 'Page F',
      uv: 2390,
      pv: 3800,
      amt: 2500,
    },
    {
      name: 'Page G',
      uv: 3490,
      pv: 4300,
      amt: 2100,
    },
  ];

  const columns = [
    {
      title: 'RFID',
      dataIndex: 'rfid',
      key: 'rfid',
      fixed: 'left',
      sorter: (a, b) => `${a.rfid}`.localeCompare(b.rfid),
      filters: [...new Set(data.data.map((e) => `${e.rfid}`))].map((e) => ({ text: `${e}`, value: `${e}` })),
      onFilter: (value, record) => record.rfid.indexOf(value) === 0,
    },
    {
      title: t('global.word.connector') + ' ID',
      dataIndex: 'conector_id',
      key: 'conector_id',
      sorter: (a, b) => `${a.conector_id}`.localeCompare(b.conector_id),
      filters: [...new Set(data.data.map((e) => `${e.conector_id}`))].map((e) => ({ text: `${e}`, value: `${e}` })),
      onFilter: (value, record) => record.conector_id.indexOf(value) === 0,
    },
    {
      title: t('global.word.connector'),
      dataIndex: 'conector_name',
      key: 'conector_name',
      sorter: (a, b) => `${a.conector_name}`.localeCompare(b.conector_name),
      filters: [...new Set(data.data.map((e) => `${e.conector_name}`))].map((e) => ({ text: `${e}`, value: `${e}` })),
      onFilter: (value, record) => record.conector_name.indexOf(value) === 0,
    },
    {
      title: t('global.word.start'),
      dataIndex: 'start',
      key: 'start',
      sorter: (a, b) => `${a.start}`.localeCompare(b.start),
      render: (e) => getDateLocalString(e),
    },
    {
      title: t('global.word.end'),
      dataIndex: 'end',
      key: 'end',
      sorter: (a, b) => `${a.end}`.localeCompare(b.end),
      render: (e) => getDateLocalString(e),
    },
    {
      title: t('global.word.duration'),
      dataIndex: 'duracion',
      key: 'duracion',
      sorter: (a, b) => `${a.duracion}`.localeCompare(b.duracion),
    },
    {
      title: t('global.word.energy'),
      dataIndex: 'energia',
      key: 'energia',
      sorter: (a, b) => `${a.energia}`.localeCompare(b.energia),
      render: (e) => <p>{parseFloat(e >= 1000 ? (e / 1000) : e)}{e >= 1000 ? 'MWh' : 'kWh'}</p>,
    },
  ]

  const formatYAxis = (value) => {
    const roundedValue = value.toFixed(2); // Redondea a 2 decimales
  
    if (roundedValue.endsWith('.00')) {
      return roundedValue.slice(0, -3); // Remueve los decimales y el punto si son cero
    }
  
    return roundedValue;
  };

  useEffect(() => {
    cargarData();
  }, [cambioComand]);

  useEffect(() => {
    cargarData();
  }, [rangoDate,cambioComand]);


  const getSessionsCSV = () => {
    message.loading(textLanguage.REQUEST_DOWNLOAD_CSV);
    export_to_csv(
      data.dataFiltered ? data.dataFiltered.map((e) => ({ ...e, start: getDateLocalString(e.start), end: getDateLocalString(e.end) })) : null,
      data.data.map((e) => ({ ...e, start: getDateLocalString(e.start), end: getDateLocalString(e.end) })),
      'Recargas');
  }

  const rowSelection = {
    onSelectInvert: (s, sr, cr) => {
      console.log('onSelectInvert: ', s, sr, cr);
    },
    onSelect: (s, sr, cr) => {
      setSelectedRows({ data: [s.key] });
    },
    onChange: (e, i) => {
      if (i[0]) {
        apiSessions.getSessionMeters(i[0].session_id).then((se) => {
          console.log('onChange: ', se);
          let max_power_active = 0;
          let seTemp = [...se].sort((a, b) => {
            return a.id - b.id
          });

          let itemObjeto = seTemp.map((e) => {
              if (e.power_active && max_power_active < e.power_active) max_power_active = e.power_active;
              return {
                name: `${getDateLocalString(e.time)}`,
                pv: typeof e.value === 'string' ? parseFloat(e.value).toFixed(2) : (e.value).toFixed(2),
                uv: e.power_active ? (typeof e.power_active === 'string' ? parseFloat(e.power_active).toFixed(2) : (e.power_active).toFixed(2)) : 0,
                soc: e.soc ? (typeof e.soc === 'string' ? parseFloat(e.soc).toFixed(2) : (e.soc).toFixed(2)) : 0
              }
            });
          
          let itemMaxEnergia = Math.max(...itemObjeto.map(item => item.pv));
          let itemMaxPotencia = Math.max(...itemObjeto.map(item => item.uv));
          let valormax=0;

          if(itemMaxEnergia>itemMaxPotencia){
            valormax = parseFloat(itemMaxEnergia)+0.5;
            setMaxValueEnergia(valormax);  
          }else{
            valormax = parseFloat(itemMaxPotencia)+0.5;
            setMaxValueEnergia(valormax); 
          }

          setRechargeSelected({
            data: itemObjeto,
            duration: i[0].duracion,
            max_power_active: (typeof max_power_active === 'string' ? parseFloat(max_power_active).toFixed(2) : (max_power_active).toFixed(2)),
            start: getDateLocalString(i[0].start),
            end: getDateLocalString(i[0].end)
          })

          
          console.log('valormax ', valormax); 

        }).catch((e) => {
          console.log('error: ', e);
        });
      } else {
        setRechargeSelected(null)
      }
    },
    getCheckboxProps: (e) => {
    },
  };

  const cargarData = () => {

    console.log('TablaRecargas cambioComand',cambioComand);
    setData({ ...data, loading: true });
    apiSessions.getHistoSession({
      start_date: `${getUTCString(rangoDate[0])}`,
      end_date: `${getUTCString(rangoDate[1])}`,
      evse_uid: cargadorId
    }).then((rslt) => {
      setData({
        data: rslt.map((v, i) => {
          return {
            key: i,
            session_id: v.id,
            conector_id: v.connector_id.conn_id,
            conector_name: v.connector_id.standard,
            rfid: v.tag_id,
            start: v.start_date_time,
            start_millis: new Date(v.start_date_time).getTime(),
            end: v.end_date_time,
            end_millis: new Date(v.end_date_time).getTime(),
            duracion: v.duration,
            energia: `${v.kwh}`,
          }
        }),
        loading: false,
      });
    }).catch((err) => {
      console.log('error: ', err);
      message.warn(textLanguage.REQUEST_CON_ERROR);
      setData({ data: [], loading: false });
    });
  } 

  const CustomizedAxisTick = (props) => {
    const { x, y, stroke, payload } = props;
    let dateTemp = new Date(payload.value);
    let hours = dateTemp.getHours();
    let min = dateTemp.getMinutes();
    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={10} textAnchor="start" fill="#666" transform="rotate(15)">{`${hours > 9 ? hours : `0${hours}`}:${min > 9 ? min : `0${min}`}`}</text>
      </g>
    );

  };

  return (
    <Row gutter={[16, 16]} className={'tabla-recargas'}>
      <Col span={24} className={'recargas_filtro'}>
        <DateSelectorGroup
          onChangeDateRange={onChangeDateRange} />

        <Button onClick={getSessionsCSV} style={{ float: 'right' }} type={'primary'}>{t('global.word.download')}</Button>
      </Col>

      <Col sm={24} lg={selectedRows.data.length ? 16 : 24} className={'recargas_tablas'}>
        <Table
          rowSelection={{
						columnTitle: t('states.showgraph'),
            type: 'radio',
            selectedRowKeys: selectedRows.data,
            ...rowSelection,
          }}
          onChange={(p, f, s, { currentDataSource }) => setData({ ...data, dataFiltered: currentDataSource ? currentDataSource : null })}
          columns={columns}
          dataSource={data.data}
          loading={data.loading}
        />
      </Col>
      {
        selectedRows.data.length > 0 ? (
          <Col sm={24} lg={8}>
            <Card title={t('metrics.card2.title')}>
              <Button type={'primary'} className={'btn-close-chart'} shape={'circle'} icon={<CloseOutlined color={'white'} />} onClick={() => setSelectedRows({ data: [] })} />
              {
                rechargeSelected && rechargeSelected.data.length > 0 ?
                  (
                    <>
                      <p style={{ marginBottom: 0 }}>
                        {t('global.word.period')}: <strong>{getDateLocalString(rechargeSelected.start)}</strong> - <strong>{getDateLocalString(rechargeSelected.end)}</strong>
                      </p>
                      <p style={{ marginBottom: 0 }}>
                        {'Potencia activa maxima'}: <strong>{rechargeSelected.max_power_active}Kw</strong>
                      </p>
                      <p>
                        {t('global.word.duration')}: <strong>{rechargeSelected.duration}</strong>
                      </p>
                      <ResponsiveContainer height={300}>
                        <AreaChart
                          data={rechargeSelected.data}
                          margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                          }}
                        >
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis dataKey="name" tick={<CustomizedAxisTick />} />
                          <YAxis yAxisId="left" />
                          <YAxis yAxisId="right" orientation="right" />
                          <Tooltip />
                          <Legend />

                          <Area yAxisId={'left'} type="monotone" name={`${t('global.word.power')} (Kw)`} dataKey="uv" fill={platform.main_color} fillOpacity={0.5} stroke={platform.main_color} activeDot={{ r: 8 }} />

                          <Area yAxisId={'right'} type="monotone" name={`Soc (%)`} dataKey="soc" fill={'gray'} fillOpacity={0.5} stroke={'gray'} activeDot={{ r: 8 }} />

                          {/* <Line type="monotone" dataKey="amt" stroke="gray" /> */}
                        </AreaChart>
                      </ResponsiveContainer>
                      <Divider />
                      <ResponsiveContainer height={300}>
                        <AreaChart
                          data={rechargeSelected.data}
                          margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                          }}
                        >
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis dataKey="name" tick={<CustomizedAxisTick />} />
                          <YAxis yAxisId="left" />
                          <YAxis yAxisId="right" orientation="right" domain={[0, maxValueEnergia]} tickFormatter={formatYAxis}/>
                          <Tooltip />
                          <Legend />

                          <Area yAxisId={'left'} 
                                type="monotone" 
                                dataKey="uv" 
                                name={`${t('global.word.power')} (Kw)`} 
                                fill={platform.main_color} 
                                fillOpacity={0.5} 
                                stroke={platform.main_color} 
                                activeDot={{ r: 8 }}   
                          />

                          <Area yAxisId={'right'}
                                type="monotone"
                                dataKey="pv"
                                name={`${t('global.word.energy')} (Kwh)`}
                                fill={'gray'}
                                fillOpacity={0.5}
                                stroke={'gray'}
                                activeDot={{ r: 8 }}  
                          />

                          {/* <Line type="monotone" dataKey="amt" stroke="gray" /> */}
                        </AreaChart>
                      </ResponsiveContainer>
                    </>
                  )
                  : <Empty style={{ marginTop: 10 }} />
              }
            </Card>
          </Col>
        ) : (
          ''
        )
      }
    </Row>

  );
}


export default TablaRecargas;