/* eslint-disable no-param-reassign */
import { combineReducers } from 'redux';

import user from './userReducer';
import alertas from './alertasReducer';
import locations from './locationReducer';
import platform from './platformSettings';
import profiles from './chargeProfiles';

const appReducer = combineReducers({
  user,
  alertas,
  locations,
  platform,
  profiles,
  socket: null,
});

const rootReducer = (state, action) => {
  const { type } = action;
  if (type === 'RESET_STORE') {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
