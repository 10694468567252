import ConfigUsuarios from '@views/ConfigUsuarios'

const path = '/configuracion-cuenta/usuarios';
const path2 = '/configuracion-cuenta/:cliente/usuarios';

const usuariosMenu = ( t ) => {
  return {
    path,
    path2,
    name: t('menus.users'),
    bread:'Configuración de cuenta/usuarios',
    breadCompose:(cliente) => {
      return cliente ? [[null,t('config.header')],["/configuracion-cuenta/clientes",t('config.clients.header')],[null,`${cliente}`],[null,t('config.users.header')]] : [[null,t('config.header')],[null,t('config.users.header')]];
    },
    icon: null,
    component:ConfigUsuarios,
    key: 'config-user-usuario',
    children: [],
  }
}

export default usuariosMenu;