import React, { useEffect, useState } from 'react';
import { Layout } from 'antd';
import PropTypes from 'prop-types';
import './style.less';
import { useSelector } from 'react-redux';

import logoCompuesto from '@assets/img/logo/LogoCelsiaBlanco.png';
import logoSimbolo from '@assets/img/logo/SimboloCelsia.png';
import useSocket from '@hooks/useSocket';
import HeaderCelsia from '../../containers/Header';
import MainContent from './MainContent';
import SideNavSwitch from '../SideNavSwitch';
import { apiProfileM } from '@utils/services';
import { platformActions } from '@store/actions';
import { useLocation } from 'react-router';

const { Header, Sider, Content } = Layout;

const Main = (props) => {
  const { user, platform } = useSelector((state) => state);
  useSocket('socket', user);
  const { history } = props;
  // const location = useLocation();
  const [isMenuCollapsed, setMenuCollapsed] = useState(true);

  // const classMenu = isMenuCollapsed ? 'menu--collapsed' : 'menu';

  const logo = isMenuCollapsed ? logoCompuesto : logoCompuesto;
  const isConfig = () => {
    return (history.location.pathname.startsWith('/configuracion-cuenta') || history.location.pathname.startsWith('/configuracion-sitio'));
  }

  useEffect(() => {
    loadProfile();
  }, []);

  const loadProfile = () => {
		try {
			// console.log(platform);
			if (platform.icon !== '') document.querySelector("link[rel*='icon']").href = `.${platform.icon}`;
			if (platform.name !== '') document.title = `${platform.name}`;
			if (platform.main_color !== '') {
				window.less.modifyVars({
					'@primary-color': platform.main_color,
				});
			}
			window.less.refreshStyles();
		} catch (error) {
			console.log('error: ', error)
		}

    apiProfileM.getProfile(user.clientSelected.client_id).then((rslt) => {
      // console.log(rslt);
      if (!rslt[0]) return;
      const properties = rslt[0];
      document.title = `${properties.name}`;
      document.querySelector("link[rel*='icon']").href = `${properties.icon}`;
      window.less.modifyVars({
        '@primary-color': properties.color_1,
      });
      window.less.refreshStyles();

      platformActions.update_platform(platform, { name: properties.name, icon: properties.icon, logo: properties.logo, main_color: properties.color_1 });
    }).catch((err) => {
      // console.log('err: ', err);
    });
  }

  const evaluateIsCollapse = () => {
    return isConfig() ? false : isMenuCollapsed;
  }
  const padding = isConfig() ? 12 : isMenuCollapsed ? 16 : 12;

  const evualuateLogo = () => {
    if (evaluateIsCollapse()) {
      if (platform.icon !== '') {
        return `${platform.icon}`.startsWith('.') ? `.${platform.icon}` : platform.icon;
      }
    } else {
      if (platform.logo !== '') {
        return `${platform.logo}`.startsWith('.') ? `.${platform.logo}` : platform.logo;
      }
    }

    return isConfig() ? logoCompuesto : logo;
  }

  return (
    <Layout id="main">
      <Sider trigger={null} collapsed={evaluateIsCollapse()} className="menu-wrapper" width={isConfig() ? 200 : 270}>
        <div style={{ backgroundColor: `${platform.main_color}` }} className="menu-wrapper__logo">
          <img src={
            (
              evualuateLogo()
            )} alt="Logo" style={{ padding }} />
        </div>
        <SideNavSwitch {...props} />
        {/* <MainMenu className={`menu-wrapper__${classMenu}`} history={history} /> */}
        {/* <ConfigMenu  className={`menu-wrapper__${classMenu}`} history={history} /> */}
      </Sider>
      <Layout className="content-wrapper">
        <Header style={{ backgroundColor: `${platform.main_color}` }} className="content-wrapper__header">
          <HeaderCelsia
            toggleMenuCollapsed={() => setMenuCollapsed(!isMenuCollapsed)}
            isMenuCollapsed={evaluateIsCollapse()}
          />
        </Header>
        <Content className="content-wrapper__main-content">
          <MainContent />
        </Content>
      </Layout>
    </Layout>
  );
};

Main.propTypes = {
  history: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default Main;
