import React from 'react';
import { Table } from 'antd';
// import { apiSessions } from '@utils/services/cpo'
// import filters from '@utils/services/cpo/filters';
// import getLocalDateString from '@utils/helpers/UtcResponseReceive'
// import getUTCString from '@utils/helpers/UtcRequestConverter'
// import { ExportToCsv } from 'export-to-csv';
import getDateLocalString from '@utils/helpers/UtcResponseReceive'
import { useTranslation } from 'react-i18next'
const TablaRecargas = (props) => {
  const { t, /* i18n */ } = useTranslation();
  const {
    //filterDates,
    dataSource,
    filteredTable, setFilteredTable,
    filtersRecargas, // setFiltersRecargas
  } = props;

  const columns = [
    {
      title: t('global.word.city'),
      dataIndex: 'city',
      key: 'city',
      sorter: (a, b) => `${a.city}`.localeCompare(b.city),
      filters: filtersRecargas.ciudades,
      filteredValue: filteredTable.filteredInfo && filteredTable.filteredInfo.city ? filteredTable.filteredInfo.city : null,
      onFilter: (value, record) => record.city === value,
    },
    {
      title: t('global.word.station'),
      dataIndex: 'location',
      key: 'location',
      sorter: (a, b) => `${a.location}`.localeCompare(b.location),
      filters: filtersRecargas.estaciones,
      filteredValue: filteredTable.filteredInfo && filteredTable.filteredInfo.location ? filteredTable.filteredInfo.location : null,
      onFilter: (value, record) => record.location === value,
    },
    {
      title: t('global.word.charger'),
      dataIndex: 'evse',
      key: 'cargador',
      sorter: (a, b) => `${a.evse}`.localeCompare(b.evse),
      filters: filtersRecargas.cargadores,
      filteredValue: filteredTable.filteredInfo && filteredTable.filteredInfo.cargador ? filteredTable.filteredInfo.cargador : null,
      onFilter: (value, record) => record.evse === value,
    },
    {
      title: t('global.word.connector'),
      dataIndex: 'connector',
      key: 'connector',
      sorter: (a, b) => `${a.connector}`.localeCompare(b.connector),
      filters: filtersRecargas.conector,
      filteredValue: filteredTable.filteredInfo && filteredTable.filteredInfo.connector ? filteredTable.filteredInfo.connector : null,
      onFilter: (value, record) => record.connector === value,
    },
    {
      title: 'RFID',
      dataIndex: 'rfid',
      key: 'rfid',
      sorter: (a, b) => `${a.rfid}`.localeCompare(b.rfid),
      filters: filtersRecargas.rfid,
      filteredValue: filteredTable.filteredInfo && filteredTable.filteredInfo.rfid ? filteredTable.filteredInfo.rfid : null,
      onFilter: (value, record) => record.rfid === value,
      // sortOrder: filteredTable.sortedInfo.columnKey === 'rfid' && filteredTable.sortedInfo.order,
    },
    {
      title: t('global.word.start'),
      dataIndex: 'start',
      key: 'start',
      sorter: (a, b) => `${a.start}`.localeCompare(b.start),
      render: (e) => getDateLocalString(e),
    },
    {
      title: t('global.word.end'),
      dataIndex: 'end',
      key: 'end',
      sorter: (a, b) => `${a.end}`.localeCompare(b.end),
      render: (e) => getDateLocalString(e),
    },
    {
      title: t('global.word.duration'),
      dataIndex: 'duration',
      key: 'duration',
      sorter: (a, b) => `${a.duration}`.localeCompare(b.duration),
    },
    {
      title: t('global.word.energy'),
      dataIndex: 'energy',
      key: 'energy',
      sorter: (a, b) => `${a.energy}`.localeCompare(b.energy),
    },
  ];


  const handleChange = (pagination, filters, sorter, extra) => {
    setFilteredTable({
      filteredInfo: filters,
      sortedInfo: sorter,
      filteredData: extra.currentDataSource
    });
  };




  return (
    <Table
      onChange={handleChange}
      dataSource={dataSource.data}
      loading={dataSource.loading}
      columns={columns} />
  );
}

TablaRecargas.defaultProps = {
  filterDates: {
    start_date: new Date(),
    end_date: new Date()
  }
}

export default TablaRecargas;