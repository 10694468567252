import React, { useState, useEffect } from 'react';
import { tarjetasParaRecarga } from '@utils/menus/main/itemsMenu';
import { TableTarjetas, TableTransaction } from '../../components';
import Breadcrumb from '@containers/Breadcrumb';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCreditCard } from '@fortawesome/free-solid-svg-icons';
import { apiConfigClientes } from '@utils/services/index';
import { DateSelectorGroup } from '@components/ui';
import { Button, Card, Form, Input, Select, Drawer, message, Checkbox, Row, Col, Divider, Tag, Tabs } from 'antd';
import { apiTarjetas, apiMetricas } from '@utils/services';
import { useSelector } from 'react-redux';
import { comprobar_permiso } from '@utils/auth/permissions';
import getLocalDateString from '@utils/helpers/UtcResponseReceive'
import getUTCString from '@utils/helpers/UtcRequestConverter'
import { ExportToCsv } from 'export-to-csv';
import { Spanish } from '@utils/helpers/dictIdiomas'
import { export_to_csv } from '@utils/helpers/exportCSVUtil'
import { useTranslation } from 'react-i18next'
import './style.less';

const { Search } = Input;
const { Option } = Select;
const textLanguage = Spanish({ screen: 'cpo-rfid' })

const AdministrarTarjetasClientes = () => {
  const { t, i18n } = useTranslation();
  const { name } = tarjetasParaRecarga;
  const { TabPane } = Tabs;
  const breadcrumbData = [[null, t('rfid.header')]];
  const { user, platform } = useSelector((state) => state);
  const [clientesSelect, setClientes] = useState([]);
  const [searchBar, setSearchBar] = useState('');
  const [tabSelected, setTabSelected] = useState('1');
  const [client_filter, setClientFilter] = useState(0);
  const [form] = Form.useForm();

  const [formularioTarjetaState, setFormularioTarjetaState] = useState({
    id: ``,
    titulo: ``,
    cliente: ``,
    codigo: ``,
    descripcion: ``,
    active: true,
    visible: false,
  });

  const [tableEnergy, setTableEnergy] = useState({
    data: [],
    loading: false,
  });
  const [tableState, setTableState] = useState({
    data: [
      // {
      //     key:1,
      //     id:1,
      //     fecha:"20/20/2020",
      //     cliente:"Celsia",
      //     descripcion:"test",
      //     codigo:`000`,
      //     estado:0,
      // },{
      //     key:2,
      //     id:2,
      //     fecha:"20/20/2020",
      //     cliente:"Celsia2",
      //     descripcion:"test",
      //     codigo:`000`,
      //     estado:1,
      // }
    ],
    dataRaw: [],
    clientsCount: {},
    lastData: null,
    loading: false,
  });

  const cargarClientes = () => {
    if (comprobar_permiso(user, 5)) {
      apiConfigClientes.clientes().then((rslt) => {
        setClientes(rslt);
      }).catch((err) => {
        console.log('err: ', err);
      });
    }
  }

  const rangoHoy = () => {
    let hoyTemp1 = new Date();
    let hoyTemp2 = new Date();

    hoyTemp1.setHours(0);
    hoyTemp1.setMinutes(0);
    hoyTemp1.setMilliseconds(0);

    hoyTemp2.setHours(23);
    hoyTemp2.setMinutes(59);
    hoyTemp2.setMilliseconds(59);

    return [hoyTemp1, hoyTemp2]
  }

  const [rangoDate, setRangoDate] = useState(rangoHoy());


  const cargarTarjetas = () => {
    setTableState({ ...tableState, loading: true });
    apiTarjetas.obtener_tarjetas(false).then((rslt) => {
      let lastRFID = null;
      let clients = {};
      for (let i = 0; i < rslt.length; i++) {
        if (!lastRFID || rslt[i].created > lastRFID.created) {
          lastRFID = rslt[i];
        }

        let cTemp = rslt[i].client;
        for (let c = 0; c < cTemp.length; c++) {
          if (!(`${cTemp[c].id}` in clients)) {
            clients[`${cTemp[c].id}`] = {
              ...cTemp[c],
              count: 1,
            }
          } else {
            clients[`${cTemp[c].id}`].count += 1;
          }
        }

      }

      // console.log(rslt);

      setTableState({
        ...tableState,
        loading: false,
        dataRaw: rslt,
        clientsCount: clients,
        lastData: lastRFID,
        data: rslt.map((obj, i) => ({
          key: `${obj.id}_${i}`,
          id: obj.id,
          fecha: getLocalDateString(obj.created),
          cliente: obj.client,
          clients_id: obj.client,
          descripcion: obj.description,
          codigo: obj.code,
          estado: obj.is_active,
        }))
      });
    }).catch((err) => {
      console.log('err: ', err);
      setTableState({ ...tableState, loading: false });
    });
  }

  const obtener_tarjeta_raw = (key) => {
    let dataRaw = tableState.dataRaw;
    for (let i = 0; i < dataRaw.length; i++) {
      if (dataRaw[i].id === key) return dataRaw[i];
    }
  }

  const handleCancel = () => {
    setFormularioTarjetaState({ ...formularioTarjetaState, visible: false })
  }

  const handleNewCard = () => {
    form.resetFields();
    setFormularioTarjetaState({
      ...formularioTarjetaState,
      id: null,
      visible: true,
    });
  }

  useEffect(() => {
    getCardsEnergy();
  }, [rangoDate]);

  const getCardsEnergy = () => {
    setTableEnergy({ ...tableEnergy, loading: true });
    apiMetricas.cards_energy({ start_date: getUTCString(rangoDate[0]), end_date: getUTCString(rangoDate[1]) }).then((rslt) => {
      // console.log(rslt);
      setTableEnergy({ data: rslt, loading: false });
    }).catch((err) => {
      console.log('err: ', err);
      setTableEnergy({ ...tableEnergy, loading: false });
    });
  }

  const handleEditCard = (record) => {
    const tarjetaRaw = obtener_tarjeta_raw(record.id);
    setFormularioTarjetaState({
      ...formularioTarjetaState,
      id: tarjetaRaw.id,
      visible: true
    })
    // console.log(tarjetaRaw);
    form.setFieldsValue({
      client: tarjetaRaw.client.map(e => e.id),
      code: tarjetaRaw.code,
      description: tarjetaRaw.description,
      is_active: tarjetaRaw.is_active
    })
  }


  const handleSwitchCard = (record) => {
    let temp = {
      id: record.id,
      is_active: !record.estado
    }
    // console.log(record,temp);
    apiTarjetas.inactivar_tarjeta(temp).then((rslt) => {
      if (rslt && 'is_active' in rslt) message.success(textLanguage.RFID_CARDS_ACTIVE_INACTIVE({ active: temp.is_active }));
      cargarTarjetas();
    }).catch((err) => {
      console.log('err: ', err);
    });
  }

  const handleOk = () => {
    form.submit();
  }

  const handleWhiteList = () => {
    // console.log(user);
    apiTarjetas.enviar_lista_blanca({
      client_id: comprobar_permiso(user, 5) ? client_filter : user.clientSelected.client_id
    }).then((rslt) => {
      message.info(`${rslt.result === 'ACCEPTED' ? textLanguage.RFID_CARDS_WHITE_REQUEST_SUCCESS : textLanguage.RFID_CARDS_WHITE_REQUEST_FAIL}`);
      cargarTarjetas();
    }).catch((error) => {
      console.log('error: ', error``);
    });

  }

  const onChangeDateRange = (start, end) => {
    let dateStart = new Date(start);
    let dateEnd = new Date(end);

    setRangoDate([dateStart, dateEnd]);
  };

  const handleDownload = () => {
    message.loading(textLanguage.REQUEST_DOWNLOAD_CSV);
    export_to_csv(null, tableState.data, 'Tarjetas')
  }

  useEffect(() => {
    cargarTarjetas();
    cargarClientes();
    getCardsEnergy();
  }, []);

  const onFinishHandler = (values) => {
    // console.log(values);
    if (formularioTarjetaState.id === null) {
      apiTarjetas.crear_tarjeta(values).then((rslt) => {
        if (rslt.id) {
          message.success(t('rfid.card.success'));
        } else {
          let keys = Object.keys(rslt);
          if (keys.includes('code')) message.error(t(`rfid.card.error.code`));

        }
        cargarTarjetas();
        handleCancel();
      }).catch((err) => {
        message.error(textLanguage.RFID_CARDS_C_FAIL);
        console.log('err: ', err);
      })
    } else {
      apiTarjetas.editar_tarjeta({ ...values, id: formularioTarjetaState.id }).then((rslt) => {
        if (Array.isArray(rslt.code)) {
          let keys = Object.keys(rslt);
          if (keys.includes('code')) message.error(t(`rfid.card.error.code`));

          return;
        }

        message.success(t('rfid.card.update'));
        cargarTarjetas();
        handleCancel();
      }).catch((err) => {
        message.error(textLanguage.RFID_CARDS_C_FAIL);
        console.log('err: ', err);
      })
    }

  }

  return (
    <Row id={"tarjetas_general"}>
      <Col span={24}>
        <Breadcrumb breadcrumbData={breadcrumbData} />
      </Col>

      <Col span={24} className={"tarjetas-table"}>
        <Row className={'container-resume-row'}>
          <Col span={24} className={'container-row-resumen'}>
            <Row gutter={[16, 16]}>
              <Col className={'container-resumen'} sm={12} lg={5}>
                <h3><strong>{t('rfid.resume.header.rfid')}</strong></h3>
                <Divider />
                <p>{t('rfid.resume.totalrfid')}: <strong className={'value-resumen'}>{`${tableState.data.length}`}</strong></p>
                <p>{t('rfid.resume.totalactive')}: <strong className={'value-resumen'}>{`${tableState.dataRaw.filter((e) => (e.is_active === true)).length}`}</strong></p>
                <p>{t('rfid.resume.totalinactive')}: <strong className={'value-resumen'}>{`${tableState.dataRaw.filter((e) => (e.is_active === false)).length}`}</strong></p>
              </Col>
              {
                comprobar_permiso(user, 5) && (
                  <>
                    <Col className={'container-resumen'} sm={12} lg={7}>
                      <h3><strong>{t('rfid.resume.header.last')}</strong></h3>
                      <Divider />
                      <p>{t('rfid.resume.client')}: <strong style={{ float: (tableState.lastData && tableState.lastData.client.length > 2 ? 'none' : 'rigth') }} className={'value-resumen'}>{tableState.lastData ? tableState.lastData.client.map((e) => (
                        <Tag
                          key={e.id}
                        >
                          {e.name}
                        </Tag>
                      )) : 'NA'}</strong></p>
                      <p>{t('rfid.resume.code')}: <strong className={'value-resumen'}>{tableState.lastData ? tableState.lastData.code : 'NA'}</strong></p>
                      <p>{t('rfid.resume.state')}: <strong className={'value-resumen'}>{tableState.lastData ? tableState.lastData.is_active ? t('global.word.active') : t('global.word.deactive') : 'NA'}</strong></p>
                      <p>{t('rfid.resume.date')}: <strong className={'value-resumen'}>{tableState.lastData ? getLocalDateString(tableState.lastData.created) : `NA`}</strong></p>
                      <p>{t('rfid.resume.description')}: <strong className={'value-resumen'}>{tableState.lastData ? tableState.lastData.description : `NA`}</strong></p>
                    </Col>
                    <Col className={'container-resumen'} sm={12} lg={6}>
                      <h3><strong>{t('menus.clients')}</strong></h3>
                      <Divider />
                      {
                        Object.keys(tableState.clientsCount).map((e) => {
                          return (<p key={tableState.clientsCount[e].name}>{tableState.clientsCount[e].name}: <strong className={'value-resumen'}>{tableState.clientsCount[e].count}</strong></p>);
                        })
                      }
                    </Col>
                  </>
                )
              }

            </Row>
          </Col>

          <Col sm={12} lg={12}>
            <Row className={'tarjetas-options'}>
              {
                /**
                 * Por definir el permiso especifico
                 */
                comprobar_permiso(user, 5) && (
                  <Col >
                    <Select defaultValue={client_filter !== 0 ? client_filter : 0}
                      onChange={(e) => setClientFilter(e)}
                      style={{ width: 220 }} >
                      <Option key={0} value={0}>
                        {t('global.selector.clients.default.all')}
                      </Option>
                      {
                        clientesSelect.map((e) => (
                          <Option key={e.id} value={e.id}>
                            {e.name}
                          </Option>
                        ))
                      }
                    </Select>
                  </Col>
                )
              }
              <Col>
                <Search placeholder={t('global.word.search')} value={searchBar} onChange={(e) => setSearchBar(e.target.value)} style={{ width: 200 }} />
              </Col>
              {
                (tabSelected === '1' && comprobar_permiso(user, 4)) && (
                  <Col>
                    <Button onClick={() => handleNewCard()} className="nueva_tarjeta_btn" type="primary">{t('rfid.btn.newcard')}</Button>
                  </Col>
                )
              }
            </Row>
            {
              tabSelected === '2' && (
                <Row className={'range-selector'}>
                  <Col>
                    <DateSelectorGroup
                      onChangeDateRange={onChangeDateRange}
                    />
                  </Col>
                </Row>

              )
            }
          </Col>

          <Col sm={12} lg={12} className={'tarjetas-options-right'} >
            <Row>
              {
                tabSelected === '1' && (
                  <Col>
                    <Button className="btn_lista_blanca" type="primary" onClick={handleWhiteList} disabled={false} >{t('rfid.btn.sendwhitelist')}</Button>
                  </Col>
                )
              }
              <Col>
                <Button type="default" onClick={handleDownload}>{t('global.word.download')}</Button>
              </Col>
            </Row>
          </Col>

          <Col span={24} className={"tarjetas_body"}>
            <Tabs
              activeKey={tabSelected}
              onChange={(e) => setTabSelected(e)}>
              <TabPane key={"1"} tab={t('rfid.tabs.rfidcards')}>
                <TableTarjetas
                  user={user}
                  handleSwitchCard={handleSwitchCard}
                  handleEditCard={handleEditCard}
                  dataRaw={tableState.dataRaw}
                  data={
                    (tableState.data.filter(obj => {
                      let keys = Object.keys(obj);
                      if (comprobar_permiso(user, 5)) {
                        if (client_filter !== 0) {
                          const clientesIn = obj.clients_id;
                          // console.log(clientesIn);
                          if (clientesIn.filter(e => e.id === client_filter).length > 0) {
                            for (let i = 0; i < keys.length; i++) {
                              if (`${obj[`${keys[i]}`]}`.toLowerCase().includes(searchBar.toLowerCase())) {
                                return true;
                              }
                            }
                          }
                        } else {
                          for (let i = 0; i < keys.length; i++) {
                            if (`${obj[`${keys[i]}`]}`.toLowerCase().includes(searchBar.toLowerCase())) {
                              return true;
                            }
                          }
                        }
                      } else {
                        const clientesIn = obj.clients_id;
                        if (clientesIn.filter(e => e.id === user.clientSelected.client_id).length > 0) {
                          for (let i = 0; i < keys.length; i++) {
                            if (`${obj[`${keys[i]}`]}`.toLowerCase().includes(searchBar.toLowerCase())) {
                              return true;
                            }
                          }
                        }
                      }
                      return false;
                    }))
                  }
                  loading={tableState.loading}
                  clients={tableState.clientsCount} />
              </TabPane>
              <TabPane key={'2'} tab={t('rfid.tabs.rfidtransaction')}>
                <TableTransaction
                  state={tableEnergy}
                  rfids={tableState.data}
                  clients={tableState.clientsCount} />
              </TabPane>
            </Tabs>

          </Col>
        </Row>


        <Drawer
          style={{
            boxSizing: 'border-box',
            height: '100%',
            float: "right",
            top: 0,
            padding: 0,
          }}
          placement="right"
          width={700}
          placement="right"
          onClose={handleCancel}
          visible={formularioTarjetaState.visible}
          footer={[
            <Button type={'default'} key="Cancelar" onClick={handleCancel}>
              {t('rfid.form.btn.cancel')}
            </Button>,
            <Button key="Enviar" type="primary" onClick={handleOk}>
              {formularioTarjetaState.id === '' ? t('rfid.form.btn.new') : t('rfid.form.btn.save')}
            </Button>,
          ]}
        >
          <div style={{
            textAlign: "center",
            width: '80%',
            margin: 'auto'
          }}>
            <FontAwesomeIcon icon={faCreditCard} style={{ fontSize: 38, color: `${platform.main_color}` }} />
            <h2>{formularioTarjetaState.id === null ? t('rfid.form.header.new') : t('rfid.form.header.update')}</h2>

            <Form
              form={form}
              layout="vertical"
              onFinish={onFinishHandler}
            >
              {
                comprobar_permiso(user, 5) && (
                  <Form.Item
                    label={t('global.word.client')}
                    name={'client'}
                    style={{ textAlign: 'left' }}
                    className="selector_cliente"
                    rules={[{ required: true, message: t('global.word.inputrequire') }]}
                  >

                    <Select
                      mode="multiple"
                      placeholder={t('global.word.extraclient')}
                      style={{ textAlign: 'left' }}
                      allowClear >
                      {
                        clientesSelect.map((e) => (
                          <Option key={e.id} value={e.id}>
                            {e.name}
                          </Option>
                        ))
                      }
                    </Select>
                  </Form.Item>
                )
              }

              <Form.Item
                label={t('global.word.code')}
                name={'code'}
                rules={[{ required: true, message: t('global.word.inputrequire') }]}
                style={{
                  textAlign: "left"
                }}>
                <Input placeholder="CD3_EJEMPLO" />
              </Form.Item>
              <Form.Item
                label={t('global.word.description')}
                className="selector_rol"
                name={'description'}
                // rules={[{required:true,message:t('global.word.inputrequire')}]}
                style={{
                  textAlign: "left"
                }}>
                <Input placeholder="CARD_CD3_EJEMPLO" />
              </Form.Item>
              <Form.Item
                className="check_active"
                name={'is_active'}
                valuePropName={'checked'}
                // rules={[{required:true,message:t('global.word.inputrequire')}]}
                style={{
                  textAlign: "left"
                }}
              >
                <Checkbox >{t('global.word.active')}</Checkbox>
              </Form.Item>
            </Form>
          </div>

        </Drawer>
      </Col>
    </Row>
  );
}

export default AdministrarTarjetasClientes;