const monthTranslator = (t) => {
    return [
        t('global.january'),
        t('global.february'),
        t('global.march'),
        t('global.april'),
        t('global.may'),
        t('global.june'),
        t('global.july'),
        t('global.august'),
        t('global.september'),
        t('global.october'),
        t('global.november'),
        t('global.december'),
    ]
}

export default monthTranslator;