import React, { Suspense } from 'react';
import { Router } from 'react-router-dom';
import { LastLocationProvider } from 'react-router-last-location';
import { Provider } from 'react-redux';
import store from '@store';
import history from '@utils/history';

import MainRoutes from './MainRoutes';
import './App.less';

const App = () => (
  <Suspense fallback={null}>
    <Provider store={store}>
      <Router history={history}>
        <LastLocationProvider>
          <MainRoutes />
        </LastLocationProvider>
      </Router>
    </Provider>
  </Suspense>
);

export default App;
