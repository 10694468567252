import React from 'react';
import PropTypes from 'prop-types';

function FleetIcon({ className, style, width, height, fill }) {
  return (
    <svg
      className={className}
      style={style}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 20.2 13.799"
    >
      <g transform="translate(-58 -420.254)">
        <path
          d="M-1501.5-959.747h-1a1,1,0,0,1-1-1v-1.69a2,2,0,0,1-.5-1.31v-1.5a1.982,1.982,0,0,1,1.291-1.865l.352-.878.623-1.557a3.483,3.483,0,0,1,3.249-2.2h3.969a3.475,3.475,0,0,1,2.454,1h-2.123a3.475,3.475,0,0,0-2.454,1h-1.846a1.493,1.493,0,0,0-1.392.942l-.623,1.558h2.545l-.1.256-.351.878a2.008,2.008,0,0,0-1.292,1.865v2.5h-.8v1A1,1,0,0,1-1501.5-959.747Zm0-6a.945.945,0,0,0-1,1,.945.945,0,0,0,1,1l.215,0h.014c.084,0,.171,0,.258,0a2.17,2.17,0,0,0,.7-.084.421.421,0,0,0,.316-.42A1.916,1.916,0,0,0-1501.5-965.747Z"
          transform="translate(1562 1392)"
          fill={fill}
        />
        <path
          d="M13.708,68.635l-.351-.878L12.734,66.2A3.483,3.483,0,0,0,9.484,64H5.516a3.483,3.483,0,0,0-3.25,2.2l-.623,1.557-.351.878A2,2,0,0,0,0,70.5V72a1.984,1.984,0,0,0,.5,1.31V75a1,1,0,0,0,1,1h1a1,1,0,0,0,1-1V74h8v1a1,1,0,0,0,1,1h1a1,1,0,0,0,1-1V73.31A1.982,1.982,0,0,0,15,72V70.5A2,2,0,0,0,13.708,68.635ZM4.123,66.943A1.5,1.5,0,0,1,5.516,66H9.484a1.5,1.5,0,0,1,1.393.943L11.5,68.5h-8ZM2.5,71.994A1,1,0,1,1,2.5,70,1.924,1.924,0,0,1,4,71.5C4,72.093,3.1,71.994,2.5,71.994Zm10,0c-.6,0-1.5.1-1.5-.5A1.924,1.924,0,0,1,12.5,70a1,1,0,1,1,0,1.994Z"
          transform="translate(63.2 358.053)"
          fill={fill}
        />
      </g>
    </svg>
  );
}

FleetIcon.propTypes = {
  className: PropTypes.string,
  style: PropTypes.objectOf(PropTypes.any),
  width: PropTypes.number,
  height: PropTypes.objectOf(PropTypes.any),
  fill: PropTypes.string,
};

FleetIcon.defaultProps = {
  className: null,
  style: null,
  width: 21,
  height: null,
  fill: 'currentColor',
};

export default FleetIcon;
