import { get, getCSV, post, put } from './management';

const usuarios = (cliente,exactEndpoint = null) => {
    if (!exactEndpoint) {
        return get(`/user/list/?clients=${cliente}`, true);
    }
    return get(exactEndpoint, true, true);
}

const getCSVUsuarios = (cliente) => {
    return getCSV(`/user/list/?clients=${cliente}&export=true`, true);
}

const usuario_send_activation = (body) => {
    return post('/account/users/resend_activation/',body,true);
}

const usuario_invitacion = (body) => {
    return post('/user/invite/',body,true);
}

const editar_usuario = (body) => {
    return post('/user/assign-group/',body,true);
}

const inactivar_usuario = (id, body) => {
    return put(`/user/${id}/`,body,true);
}

const usuario_resend_invitacion = (body) => {
    return post('/user/resend-invitation/',body,true);
}

const obtener_roles = ( ) => {
    return get('/client/groups/',true);
}

export default { usuarios, getCSVUsuarios, usuario_send_activation, usuario_resend_invitacion, obtener_roles, usuario_invitacion, editar_usuario, inactivar_usuario };