/* eslint-disable no-undef */
import React, { useState, useEffect } from 'react';
import { clientes } from '@utils/menus/config_user/itemsMenu';
import Breadcrumb from '@containers/Breadcrumb';
import { TablaClientes } from '@views/ConfigClientes/components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ItemSelectorRol } from '@components/ui';
import { faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { apiConfigClientes } from '@utils/services/index';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next'
import './style.less';
import { Button, Card, Col, Row, Form, Input, Select, Drawer, message  } from 'antd';
import { comprobar_permiso } from '@utils/auth/permissions';
import { Spanish } from '@utils/helpers/dictIdiomas'
import { export_to_csv } from '@utils/helpers/exportCSVUtil'

const { Option } = Select;

const TablesOrganizaciones = () => {
    const textLanguage = Spanish({screen:'config-clients'});
    const { t, i18n } = useTranslation();
    const { user, platform } = useSelector((state) => state);
    const breadcrumbData = [[null,t('config.header')],[null,t('config.clients.header')]]
    const [ modalTitle , setModalTitle ] = useState(t('clients.form.header.new'));
    const [ cliente_id , setClienteID ] = useState(undefined);
    const [ ciudades , setCiudades ] = useState({
        data:[],
        loading:false
    }); 
    const [ paises, setPaises ] = useState([]);
    
    const [ tableData ,setTableData] = useState({
        data:[],
        loading:false,
        dataRaw:[]
    });
    const [ crearUsuario_visible , setCrearUserVisible ] = useState( false );

    const [form] = Form.useForm();



    useEffect( () => {
        cargarClientes();
        obtener_ciudades();
        obtener_paises();
    },[]);


    const showCrearUsuario = () => {
        setCrearUserVisible(true);
    }

    const hideCrearUsuario = () => {
        setCrearUserVisible(false);
    }

    const cargarClientes = () => {
        setTableData({...tableData,loading:true});
        apiConfigClientes.clientes().then( (rslt) => {
            let data = [];
            for(let i = 0 ; i < rslt.length ; i++){
                data.push(tableItem(rslt[i]));
            }
            setTableData({
                data:data,
                loading:false,
                dataRaw:rslt
            });
        }).catch( (err) => {
            message.error(textLanguage.CONFIG_PROFILE_CLIENTS_R_FAIL);
            setTableData({...tableData,loading:false});
        });
    }

    const obtenerClienteRaw = ( key ) => {
        for(let i = 0 ; i < tableData.dataRaw.length ; i++){
            if(key === tableData.dataRaw[i].id) return tableData.dataRaw[i];
        }
        return null
    }

    const obtenerPaisRaw = ( { key, name  } ) => {
        for(let i = 0 ; i < paises.length ; i++){
            if(key && key === paises[i].id) return paises[i];
            if(name && name === paises[i].name) return paises[i];
        }
        return null
    }

    const obtenerCiudadRaw = ( { nombre, key } ) => {
        // console.log(ciudades);
        for(let i = 0 ; i < ciudades.data.length ; i++){
            if(nombre && nombre === ciudades.data[i].name) return ciudades.data[i];
            if(key && key === ciudades.data[i].id) return ciudades.data[i];
        }
        return null
    }

    const tableItem = (data) => {
        return {
            key:`${data.id}`,
            nombre:data.name,
            nit:data.nit,
            telefono:data.phone,
            correo_electronico:data.email,
            pais:data.country,
            ciudad:data.city,
            estado:data.is_active ? 1 : 0,
            acciones:'',

        }
    }

    const handleEliminarCliente = ( data ) => {
        let dataClienteRaw = obtenerClienteRaw(data.key);
        let objEliminarCliente = {
            id:dataClienteRaw.id,
            is_active:!dataClienteRaw.is_active,
        }
        apiConfigClientes.desactivar_cliente(objEliminarCliente).then((rslt) => {
            if('is_active' in rslt) message.success(textLanguage.CONFIG_PROFILE_CLIENTS_D_SUCCESS({name:dataClienteRaw.name,active:rslt.is_active})); //message.success(rslt.success);
            cargarClientes();
        }).catch((err) => {
            console.log(err);
            message.error(textLanguage.CONFIG_PROFILE_CLIENTS_D_FAIL({name:dataClienteRaw.name}));
        });
    }

    const handleEditarCliente = ( data ) => {
        const dataClienteRaw = obtenerClienteRaw(data.key);
        setModalTitle(`${t('clients.form.header.edit')}: ${dataClienteRaw.name}`);
        showCrearUsuario();
        const paisCliente = obtenerPaisRaw({ name:dataClienteRaw.country });
        obtener_ciudades(paisCliente.iso2);
        setClienteID(dataClienteRaw.id);
        form.setFieldsValue({
            name:dataClienteRaw.name,
            nit:dataClienteRaw.nit,
            address:dataClienteRaw.address,
            phone:dataClienteRaw.phone,
            email:dataClienteRaw.email,
            postal_code:'000',
            is_active:true,
            city:`${dataClienteRaw.city_id}`,
            country:`${paisCliente.id}`,
        });
    }

    const handleFormFinish = (  ) => {
        // console.log(`form finish`);
        form.validateFields().then( (values) => {
            // console.log(values);
            if(values.errorFields) return;
            // console.log("enviando request");

            if(cliente_id){
                apiConfigClientes.editar_cliente({
                    id:cliente_id,
                    name:values.name,
                    nit:values.nit,
                    address:values.address,
                    phone:values.phone,
                    email:values.email,
                    postal_code:'000',
                    is_active:true,
                    client_type:'CLIENT',
                    city:values.city,
                    username:values.username,
                }).then( (rslt) => {
                    // console.log('rslt: ', rslt);
                    if(rslt.id){
                        message.success(textLanguage.CONFIG_PROFILE_CLIENTS_U_SUCCESS({name:values.name}));
                    }else{
                        message.error(textLanguage.CONFIG_PROFILE_CLIENTS_U_FAIL({name:values.name}));
                    }   
                    cargarClientes();
                    form.resetFields();
                    hideCrearUsuario();
                }).catch( (err) => {
                    console.log('err: ', err);
                });
            }else{
                apiConfigClientes.crear_cliente({
                    name:values.name,
                    nit:values.nit,
                    address:values.address,
                    phone:values.phone,
                    email:values.email,
                    postal_code:'000',
                    is_active:true,
                    client_type:'CLIENT',
                    city:values.city,
                    username:values.username,
                }).then( (rslt) => {
                    if(rslt.id){
                        message.success(textLanguage.CONFIG_PROFILE_CLIENTS_C_SUCCESS({name:values.name}));
                    }else{
                        message.error(textLanguage.CONFIG_PROFILE_CLIENTS_C_FAIL({name:values.name}));
                    }   
                    // console.log(rslt);
                    cargarClientes();
                    form.resetFields();
                    hideCrearUsuario();
                }).catch( (err) => {
                    // console.log('err: ', err);
                    message.error(textLanguage.CONFIG_PROFILE_CLIENTS_C_FAIL({name:values.name}));
                });
            }
            
        }).catch( (err) => {
            console.log('err: ', err);
        });
    }

    const obtener_ciudades = ( code ) => {
        setCiudades({...ciudades,loading:true});
        apiConfigClientes.obtener_ciudades( code ? code : "CO").then( (rslt) => {
            setCiudades({loading:false,data:rslt});
        }).catch( (err) => {
            console.log('err: ', err);
            setCiudades({...ciudades,loading:false});
        });
    }

    const obtener_paises = () => {
        apiConfigClientes.obtener_paises().then( (rslt) => {
            setPaises(rslt);
        }).catch( (err) => {
            console.log('err: ', err);
        });
    }

    const handleCancel = () => {
        form.resetFields();
        hideCrearUsuario();
    };

    const handleValuesChange = ( values ) => {
        if(values.country){
            form.setFieldsValue({
                city:undefined
            })
            obtener_ciudades(obtenerPaisRaw({ key:values.country }).iso2);
        }
    }

    const handleDownload = () => {
        message.info(textLanguage.REQUEST_DOWNLOAD_CSV);
        export_to_csv(null,tableData.data,'Clientes')
    }

    return(
        <div id="config_general">
            <Breadcrumb breadcrumbData={breadcrumbData} />
            <Card className="organizaciones-top">
                <Row>
                    <Col xs={24} sm={14} lg={16} span={16}>
                        <h3><strong>{t('clients.title')}</strong></h3>
                        <h4>{t('clients.description')}</h4>
                    </Col>
                    <Col xs={24} sm={10} lg={8} span={8}>
                        <Row>
                            <Col  span={24} className={'config-clientes_colbtn_utils'}>
                                <Button
                                type={'primary'}
                                style={{
                                    visibility:`${comprobar_permiso(user,15) ? `visible` : `hidden`}`,
                                }}
                                onClick={ () => {
                                    setClienteID(undefined);
                                    setModalTitle(t('clients.form.header.new'));
                                    showCrearUsuario();
                                }}>
                                    {t('clients.btn.new')}
                                </Button>
                                <Button
                                type={'default'}
                                onClick={handleDownload}>
                                   {t('clients.btn.download')}
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                
            </Card>
            <div className="organizaciones-table">
                <TablaClientes data={tableData.data} loading={tableData.loading} refreshData={cargarClientes} editarCliente={handleEditarCliente} eliminarCliente={handleEliminarCliente}/>
            </div>

            <Drawer
            style={{
                boxSizing:'border-box',
                height:'100%',
                float:"right",
                top:0,
                padding:0
            }}
            footerStyle={{
                display:"flex",
                justifyContent:"flex-end"
            }}
            placement="right"
            width={900}
            visible={crearUsuario_visible}
            onCancel={() => hideCrearUsuario()}
            onClose={() => hideCrearUsuario()}
            footer={[
                <Button type={'default'} key="Cancelar" onClick={handleCancel}>
                  {t('clients.form.btn.cancel')}
                </Button>,
                <Button key="Enviar" type="primary"  onClick={handleFormFinish}  /*onClick={handleOk}*/>
                  { isNaN(cliente_id) ? t('clients.form.btn.save') : t('clients.form.btn.savechanges')}
                </Button>,
              ]}
            >
                <div style={{
                    textAlign:"center",
                    width:'90%',
                    margin:'auto'
                }}>
                    <FontAwesomeIcon icon={faUserPlus} style={{ fontSize: 38,color:`${platform.main_color}` }} />
                    <h2>{modalTitle}</h2>

                    <Form
                    name={'form-creareditar-cliente'}
                    form={form}
                    layout="vertical"
                    onValuesChange={handleValuesChange}
                    >
                        <Row>
                            <Col xs={(!isNaN(cliente_id) ? 16 : 12)} className="columna_crear_formulario"
                            style={{margin:'auto'}}>
                                <Form.Item
                                name={'name'}
                                label={t('clients.form.comp_name')} 
                                rules={[{ required: true, message: t('global.word.inputrequire') }]}
                                extra={t('clients.form.comp_name.description')}>
                                    <Input placeholder={t('global.word.extraname')} />
                                </Form.Item>
                                <Form.Item
                                name={'nit'}
                                label="Nit" 
                                rules={[{ required: true, message: t('global.word.inputrequire')}]}>
                                    <Input type={'number'} placeholder={t('global.word.extranit')} />
                                </Form.Item>
                                <Form.Item
                                name={'email'}
                                label={t('clients.form.email')}
                                normalize={(e)=>`${e}`.toLowerCase()}
                                rules={[{ type:'email' ,required: true, message: t('global.word.inputrequire')}]}
                                extra={t('clients.form.email.description')}>
                                    <Input placeholder={t('global.word.extraemail')}/>
                                </Form.Item>
                                <Form.Item
                                name={'phone'}
                                label={t('global.word.phone')}
                                rules={[{ required: true, message: t('global.word.inputrequire')}]}
                                extra={t('clients.form.phone.description')}>
                                    <Input placeholder={t('global.word.extraphone')}/>
                                </Form.Item>
                                <Form.Item
                                name={'address'}
                                label={t('global.word.address')}
                                rules={[{ required: true, message:t('global.word.inputrequire')  }]}>
                                    <Input placeholder="AV # 123" />
                                </Form.Item>
                                <Form.Item
                                name={'country'}
                                label={t('global.word.country')}
                                rules={[{ required: true, message:t('global.word.inputrequire')  }]}
                                extra={t('global.word.warningcountry')}>
                                    <Select 
                                    className={"selector_simple"} 
                                    placeholder={t('global.word.extracountry')}
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    >
                                        {
                                            paises.map( (value) => {
                                                return (
                                                <Option key={`${value.id}`} value={`${value.id}`}>
                                                    {`${value.name}`}
                                                </Option>
                                                )
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                name={'city'}
                                label={t('global.word.city')}
                                rules={[{ required: true, message: t('global.word.inputrequire')}]}
                                extra={t('global.word.extracity')}>
                                    <Select
                                    className={"selector_simple"} 
                                    loading={ciudades.loading}
                                    placeholder={t('global.word.extracity')}
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }>
                                        {
                                            ciudades.data.map( (value) => {
                                                return (
                                                <Option key={`${value.id}`} value={`${value.id}`}>
                                                    {`${value.name}`}
                                                </Option>
                                                )
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                                
                            </Col>

                            <Col xs={12} className="columna_crear_formulario"
                            style={
                                {
                                    display:( !isNaN(cliente_id) ? "none" : "block")
                                }
                            }>
                                <Form.Item
                                name={'username'}
                                label={t('clients.form.admin_email')}
                                normalize={(e)=>`${e}`.toLowerCase()}
                                rules={[{ type:'email' ,required: isNaN(cliente_id) , message: t('global.word.inputrequire')}]}
                                extra={t('clients.form.admin_email.description')}>
                                    <Input placeholder="descriptive name <emailname@empresa.com>" />
                                </Form.Item>
                                <Form.Item
                                label="Rol" 
                                className="selector_rol"
                                extra={t('clients.form.rol.description')} >
                                    <Select defaultValue="operador" disabled showArrow={false}>
                                        <Option key="operador">
                                            <ItemSelectorRol icon={3} titulo={"Operador de clientes"} descripcion={""}/>
                                        </Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        
                    </Form>
                </div>
                
            </Drawer>
        </div>
    );
}


export default TablesOrganizaciones;