import moment from 'moment';
import 'moment/locale/es';

const current_duration = ( start ) => {
	let dateStart = new Date(`${start}`);
	let hoy = new Date();

	let millis = hoy.getTime() - dateStart.getTime();
	let sec = millis / 1000;
	let hours = parseInt( sec / 3600 );
	sec = sec % 3600;
	let minutes = parseInt( sec / 60 );
	sec = parseInt(sec % 60);

	if (hours < 0) {
		hours = hours * -1;
	}
	if (minutes < 0) {
		minutes = minutes * -1;
	}
	if (sec < 0) {
		sec = sec * -1;
	}

	return `${hours}:${minutes}:${sec}`;
}

const current_duration_custom = (start) => {
  let fecha = start && start!=='' ? moment(start, 'YYYY-MM-DD hh:mm:ss') : moment();
  let ahora = moment()
	
  let seconds = ahora.diff(fecha, 'seconds');
  let minutes = 0;
  let hours = 0;

	do {
		if (seconds>=60) {
			seconds = seconds - 60;
			minutes = minutes + 1;
			
			if (minutes >= 60) {
				hours = hours + 1;
				minutes = minutes - 60;
			}
		}
	} while (seconds>=60);

	return `${hours}:${minutes}:${seconds}`;
}

export default { current_duration, current_duration_custom };