import React from 'react';

import Breadcrumb from '@containers/Breadcrumb';
import { Row, Col, Tabs } from 'antd';
import { Perfiles } from './views';

import './style.less';

const { TabPane } = Tabs;

const PerfilesComponent = () => {
  const breadcrumbData = [[null, 'Garage']];

  return (
    <Row className="container_perfiles">
      <Breadcrumb breadcrumbData={breadcrumbData} />
      <Col span={24} className="perfiles_tabs_container">
        <Tabs defaultActiveKey="1">
          <TabPane tab="Perfiles" key="perfiles">
            <Perfiles />
          </TabPane>
        </Tabs>
      </Col>

    </Row>
  );
};

export default PerfilesComponent;
