import React, { useEffect, useState } from 'react';
import { Row, Col, Form, Select, Radio, Tooltip as TooltipAnt, Button, Popover, message, Empty, Divider, Switch } from 'antd';
import { CardDefault, CardCategory, CardGraphic, CardEnergy, CardCake, TableRecharges } from './components';
import Breadcrumb from '@containers/Breadcrumb';
import { useSelector } from 'react-redux';
import { apiMetricas } from '@utils/services/index';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBolt, faClock, faStopwatch, faChargingStation, faSortUp, faInfoCircle, faQuestionCircle, faLeaf, faWifi, faSimCard } from '@fortawesome/free-solid-svg-icons';
import { DateSelectorGroup } from '@components/ui';
import { apiOperators, apiLocations, apiSessions } from '@utils/services/cpo';
import { Spanish } from '@utils/helpers/dictIdiomas';
import getUTCString from '@utils/helpers/UtcRequestConverter';
import monthTranslator from '@utils/helpers/monthHelper';
import getDateLocalString from '@utils/helpers/UtcResponseReceive';
import moment from "moment";
import { MultiSelect } from "react-multi-select-component";

import {
	BarChart, Legend, Bar, AreaChart, Area, Label, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer,
} from 'recharts';
import { useTranslation } from 'react-i18next'
import './style.less';
const Metricas = () => {
	const textLanguage = Spanish({ screen: 'cpo-metrics' })
	const { t, i18n } = useTranslation();
	const { user, platform } = useSelector((state) => state);
	const [conectorSelected, setConectorSelected] = useState({
		data: [],
		loading: true,
		selected: null
	});
	const [energiaData, setEnergiaData] = useState({
		data: [],
		loading: true,
	});
	const [emissionData, setEmissionData] = useState({
		data: 0,
		loading: true,
	});
	const [sessionsData, setSessionsData] = useState({
		data: [],
		loading: true,
		count: 0,
	});
	const [periodData, setPeriodData] = useState({
		parentPeriod: 'day',
		period: 'day',
	});
	const usoDefaultData = [
		{
			connector_id__standard: "TIPO_1",
			evse_uid__evse_id: "NA",
			kwh: 0,
			time_use: "0:0:0.000000",
		},
		{
			connector_id__standard: "TIPO_2",
			evse_uid__evse_id: "NA",
			kwh: 0,
			time_use: "0:0:0.000000",
		},
		{
			connector_id__standard: "CHADEMO",
			evse_uid__evse_id: "NA",
			kwh: 0,
			time_use: "0:0:0.000000",
		}
	];
	const recargasDefaultData = {
		charge_avg: 0,
		energy_avg: 0,
		energy_delivered: 0,
		peak_hours_recharges: 0,
		recharges_avg: 0,
		recharges_done: 0,
		recharges_remote_command: 0,
		recharges_rfid: 0,
		recharges_app: 0,
		sessions_active: 0,
		time_use: 0,
		loading: false
	}
	const tarjetasDefaultData = {
		active_cards: 0,
		cards_avg: 0
	}
	const dataAreaChart = [
		{
			name: 'Page A', uv: 4000, pv: 2400, amt: 2400,
		},
		{
			name: 'Page B', uv: 3000, pv: 1398, amt: 2210,
		},
		{
			name: 'Page C', uv: 2000, pv: 9800, amt: 2290,
		},
		{
			name: 'Page D', uv: 2780, pv: 3908, amt: 2000,
		},
		{
			name: 'Page E', uv: 1890, pv: 4800, amt: 2181,
		},
		{
			name: 'Page F', uv: 2390, pv: 3800, amt: 2500,
		},
		{
			name: 'Page G', uv: 3490, pv: 4300, amt: 2100,
		},
	];
	const disponibilidadDefaultData = [
		{
			evse_uid: 'Conector', disponible: 0, fallo: 0, desconocido: 0,
		},
	];
	const onChangeSwitch = (checked) => {
		setOnLoading();
		setGt_1kw(checked);
	};
	const [locationsOptions, setLocationsOptions] = useState([]);
	const [locations, setLocations] = useState({
		data: [],
		selected: [],
		loading: true
	});
	const { Option } = Select;
	const breadcrumbData = [[null, t('metrics.header')]]; // 'Mis Metricas'
	const [metricasDispo, setMetricaDispo] = useState(disponibilidadDefaultData);
	const [metricasRecargas, setMetricasRecargas] = useState(recargasDefaultData);
	const [metricasTarjetas, setMetricasTarjetas] = useState(tarjetasDefaultData);
	const [gt_1kw, setGt_1kw] = useState(false);
	const [metricasEnergy, setMetricasEnergy] = useState({
		data: [],
		loading: true
	});
	const [metricasUsos, setMetricasUsos] = useState({ data: usoDefaultData });
	const rangoHoy = () => {
		let hoyTemp1 = new Date();
		let hoyTemp2 = new Date();

		hoyTemp1.setHours(0);
		hoyTemp1.setMinutes(0);
		hoyTemp1.setMilliseconds(0);

		hoyTemp2.setHours(23);
		hoyTemp2.setMinutes(59);
		hoyTemp2.setMilliseconds(59);

		return [hoyTemp1, hoyTemp2]
	}
	const [rangoDate, setRangoDate] = useState(rangoHoy());

	useEffect(() => {
		cargarLocations();
	}, []);

	useEffect(() => {
		setTimeout(() => {
			cargarUsos();
			cargarMeticasTarjetas();
			cargarDisponibilidad();
			cargarCargas();
			cargarEmisiones();
			cargarDataEnergy();
			cargarEnergiaEntregada();
			cargarSesionesMetricas();
		}, 200);
	}, [locations.data, locations.selected, rangoDate, gt_1kw]);

	function refreshData() {
		switch (periodData.parentPeriod) {
			case 'day':
				/* if (periodData.period === 'hour') {
					cargarEnergiaEntregada();
					cargarSesionesMetricas();
				} */
				setPeriodData({ ...periodData, period: 'day' });
				break;
			case 'week':
				/* if (periodData.period === 'day') {
					cargarEnergiaEntregada();
					cargarSesionesMetricas();
				} */
				setPeriodData({ ...periodData, period: 'day' });
				break;
			case 'month':
				/* if (periodData.period === 'day') {
					cargarEnergiaEntregada();
					cargarSesionesMetricas();
				} */
				setPeriodData({ ...periodData, period: 'day' });
				break;
			case 'year':
				/* if (periodData.period === 'month') {
					cargarEnergiaEntregada();
					cargarSesionesMetricas();
				} */
				setPeriodData({ ...periodData, period: 'month' });
				break;
			default:
				/* if (periodData.period === 'month') {
					cargarEnergiaEntregada();
					cargarSesionesMetricas();
				} */
				setPeriodData({ ...periodData, period: 'day' });
				break;
		}
	}

	useEffect(() => {
		refreshData();
	}, [locations.data, locations.selected, i18n.language, periodData.parentPeriod, rangoDate, gt_1kw]);

	useEffect(() => {
		cargarConectores();
	}, [locations.selected, gt_1kw]);

	const format_date_request = (item, flag = null) => {
		switch (flag) {
			case 1:
				return `${item.getUTCFullYear()}-${(item.getUTCMonth() + 1)}-${item.getUTCDate()} 0:0:0`;
				break;
				
			case 2:
				return `${item.getUTCFullYear()}-${(item.getUTCMonth() + 1)}-${item.getUTCDate()} 23:59:59`;
			break;
		
			default:
				return `${item.getUTCFullYear()}-${(item.getUTCMonth() + 1)}-${item.getUTCDate()} ${item.getUTCHours()}:${item.getUTCMinutes()}:${item.getUTCSeconds()}`;
			break;
		}
	}

	function fakeDays(temp, fechaInicio, fechaFin, data) {
		let meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
		let dias = ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"];
		let arrayDias = [];

		if (temp.parentPeriod === 'year') {

			for (let index = 1; index <= 12; index++) {
				arrayDias.push({
					name: index,
					value: 0
				});
			}

		} else {

			while (moment(fechaInicio).isSameOrBefore(fechaFin)) {
				arrayDias.push({
					name: moment(fechaInicio).format('DD'),
					value: 0
				});
				fechaInicio = moment(fechaInicio).add(1, 'days');
			}

		}

		arrayDias.map((fecha) => {
			let dato = fecha
			let index = data.findIndex(element => (fecha.name === element.name) || (parseInt(fecha.name) === element.name))
			if (index >= 0) {
				dato.value = parseFloat(data[index].value.toFixed(2))
			}
			return dato
		})

		return arrayDias;
	}

	const formatDataSesions = (data, temp, rangoDate) => {
		let arrayFechas = [];
		let fechaInicio = ''
		let fechaFin = moment().format('YYYY-MM-DD HH:mm')

		switch (temp.parentPeriod) {
			case 'hour':
				fechaInicio = moment().format('YYYY-MM-DD HH:mm');

				break;
			case 'day':
				fechaInicio = moment().format('YYYY-MM-DD HH:mm');

				break;
			case 'week':
				// fechaInicio = moment().startOf('week').format('YYYY-MM-DD HH:mm');

				fechaInicio = format_date_request(rangoDate[0])
				fechaFin = format_date_request(rangoDate[1])

				break;
			case 'month':
				fechaInicio = moment().startOf('month').format('YYYY-MM-DD HH:mm');

				break;
			case 'year':
				fechaInicio = moment().startOf('year').format('YYYY-MM-DD HH:mm');

				break;
			default:
				fechaInicio = moment(rangoDate[0]).format('YYYY-MM-DD HH:mm');
				fechaFin = moment(rangoDate[1]).format('YYYY-MM-DD HH:mm');

				break;
		}

		arrayFechas = fakeDays(temp, fechaInicio, fechaFin, data);
		return arrayFechas;
	}

	const cargarSesionesMetricas = (periodDefault) => {

		let pTemp = periodData.parentPeriod;
		let arrayTemp = [];

		if (locations.selected && locations.selected.length) {
			arrayTemp.push(locations.selected);
		} else {
			for (let i = 0; i < locations.data.length; i++) {
				arrayTemp.push(locations.data[i].id);
			}
		}

		if (arrayTemp.length >= 1) {

			apiMetricas.getSessionsMetrics({
				locations_id: arrayTemp,
				start_date: format_date_request(rangoDate[0], 1),
				end_date: format_date_request(rangoDate[1], 2),
				step: periodData.parentPeriod === 'year' ? 'month' : 'day',
				gt_1kw: gt_1kw
				// step: periodDefault ? periodDefault : periodData.period
			}).then((rslt) => {
				if (!rslt || Object.keys(rslt).length <= 0 || (rslt.error && rslt.error !== "no recharges in process")) {
					setSessionsData({ data: [], loading: false, count: 0 })
				} else {
					let keys = Object.keys(rslt);
					let arrayTemp = [];
					let totalSessions = 0;

					for (let i = 0; i < keys.length; i++) {

						let nameK = keys[i];
						let format = 'YYYY-MM-DDThh:mm:ssZ';
						let mesTemp = moment.utc(nameK, format).format('MM');
						let dayTemp = moment.utc(nameK, format).format('DD');
						let hourTemp = moment.utc(nameK, format).format('HH');
						let minutesTemp = moment.utc(nameK, format).format('mm');
						let nameTemp = '';
						totalSessions += rslt[`${keys[i]}`];

						switch (pTemp) {
							case 'hour':
								nameTemp = `${hourTemp}:${minutesTemp}`;
								break;
							case 'day':
								nameTemp = dayTemp;
								break;
							case 'week':
								nameTemp = dayTemp;
								break;
							case 'month':
								nameTemp = dayTemp;
								break;
							case 'year':
								nameTemp = mesTemp;
								break;
							default:
								nameTemp = dayTemp;
								break;
						}

						arrayTemp.push({
							name: parseInt(nameTemp),
							value: rslt[`${keys[i]}`]
						});
					}

					setSessionsData({ ...sessionsData, data: formatDataSesions(arrayTemp, periodData, rangoDate), count: totalSessions, loading: false });
				}
			}).catch((err) => {
				console.log('error (cargarSesionesMetricas): ', err);
				setSessionsData({ data: [], loading: false, count: 0 })
			});
		}
	}

	const cargarCargas = () => {
		let arrayTemp = [];

		if (locations.selected && locations.selected.length) {
			arrayTemp.push(locations.selected);
		} else {
			for (let i = 0; i < locations.data.length; i++) {
				arrayTemp.push(locations.data[i].id);
			}
		}

		if (arrayTemp.length >= 1) {
			apiMetricas.obtener_cargas({
				locations_id: arrayTemp,
				start_date: format_date_request(rangoDate[0], 1),
				end_date: format_date_request(rangoDate[1], 2),
				gt_1kw: gt_1kw
			}).then((rslt) => {
				if (!rslt || Object.keys(rslt).length <= 0 || rslt.error) {
					if (rslt.error !== "no recharges in process") {
						message.info(textLanguage.METRICS_REQUEST_FAIL);
					}
					setMetricasRecargas(recargasDefaultData);
				} else {
					setMetricasRecargas({
						charge_avg: rslt.charge_avg,
						energy_avg: rslt.energy_avg,
						energy_delivered: rslt.energy_delivered,
						peak_hours_recharges: rslt.peak_hours_recharges,
						recharges_avg: rslt.recharges_avg,
						recharges_done: rslt.recharges_done,
						recharges_remote_command: rslt.recharges_remote_command,
						recharges_rfid: rslt.recharges_rfid,
						recharges_app: rslt.recharges_app ? rslt.recharges_app : 0,
						sessions_active: rslt.sessions_active,
						time_use: rslt.time_use,
						loading: false
					});
				}
			}).catch((err) => {
				console.log('error (cargarCargas): ', err);
				setMetricasRecargas(recargasDefaultData);
			});
		}
	}

	const cargarEnergiaEntregada = (periodDefault) => {

		let pTemp = periodData.parentPeriod;
		let arrayTemp = [];

		if (locations.selected && locations.selected.length) {
			arrayTemp.push(locations.selected);
		} else {
			for (let i = 0; i < locations.data.length; i++) {
				arrayTemp.push(locations.data[i].id);
			}
		}

		if (arrayTemp.length >= 1) {
			apiMetricas.obtener_energia_entregada({
				locations_id: arrayTemp,
				start_date: format_date_request(rangoDate[0], 1),
				end_date: format_date_request(rangoDate[1], 2),
				step: periodData.parentPeriod === 'year' ? 'month' : 'day',
				gt_1kw: gt_1kw
				// step: periodDefault ? periodDefault : periodData.period
			}).then((rslt) => {
				if (!rslt || Object.keys(rslt).length <= 0 || (rslt.error && rslt.error !== "no recharges in process")) {
					setEnergiaData({ data: [], loading: false });
				} else {
					let keys = Object.keys(rslt);
					let arrayTemp = [];

					for (let i = 0; i < keys.length; i++) {

						let nameK = keys[i];
						let format = 'YYYY-MM-DDThh:mm:ssZ';
						let mesTemp = moment.utc(nameK, format).format('MM');
						let dayTemp = moment.utc(nameK, format).format('DD');
						let hourTemp = moment.utc(nameK, format).format('HH');
						let minutesTemp = moment.utc(nameK, format).format('mm');
						let nameTemp = '';

						switch (pTemp) {
							case 'hour':
								nameTemp = `${hourTemp}:${minutesTemp}`;
								break;
							case 'day':
								nameTemp = dayTemp;
								break;
							case 'week':
								nameTemp = dayTemp;
								break;
							case 'month':
								nameTemp = dayTemp;
								break;
							case 'year':
								nameTemp = mesTemp;
								break;
							default:
								nameTemp = dayTemp;
								break;
						}

						arrayTemp.push({
							name: parseInt(nameTemp),
							value: rslt[`${keys[i]}`]
						});
					}

					setEnergiaData({ ...energiaData, data: formatDataSesions(arrayTemp, periodData, rangoDate), loading: false });
				}
			}).catch((err) => {
				console.log('error (cargarEnergiaEntregada): ', err);
				setEnergiaData({ data: [], loading: false });
			});
		}
	}

	const cargarEmisiones = (periodDefault) => {
		let arrayTemp = [];

		if (locations.selected && locations.selected.length) {
			arrayTemp.push(locations.selected);
		} else {
			for (let i = 0; i < locations.data.length; i++) {
				arrayTemp.push(locations.data[i].id);
			}

		}

		if (arrayTemp.length >= 1) {
			apiMetricas.obtener_emisiones({
				locations_id: arrayTemp,
				start_date: format_date_request(rangoDate[0], 1),
				end_date: format_date_request(rangoDate[1], 2),
				gt_1kw: gt_1kw
			}).then((rslt) => {
				if (!rslt || Object.keys(rslt).length <= 0 || (rslt.error && rslt.error !== "no recharges in process")) {
					setEmissionData({ data: 0, loading: false })
				} else {
					let keys = Object.keys(rslt);
					const ton = keys[0] ? rslt[keys[0]] : 0;
					setEmissionData({ ...energiaData, data: ton, loading: false });
				}
			}).catch((err) => {
				console.log('error (cargarEmisiones): ', err);
				setEmissionData({ data: 0, loading: false })
			});
		}

	}

	const cargarUsos = () => {
		let arrayTemp = [];

		if (locations.selected && locations.selected.length) {
			arrayTemp.push(locations.selected);
		} else {
			for (let i = 0; i < locations.data.length; i++) {
				arrayTemp.push(locations.data[i].id);
			}
		}

		if (arrayTemp.length >= 1) {
			apiMetricas.obtener_usos({
				locations_id: arrayTemp,
				start_date: format_date_request(rangoDate[0], 1),
				end_date: format_date_request(rangoDate[1], 2),
				gt_1kw: gt_1kw
			})
				.then((rslt) => {
					if (rslt.length <= 0 || (rslt.error && rslt.error !== "no recharges in process")) {
						setMetricasUsos({ data: usoDefaultData })
					} else {
						setMetricasUsos({
							data: rslt
						});
					}
				})
				.catch((err) => {
					setMetricasUsos({ data: usoDefaultData })
					console.log('error (cargarUsos): ', err)
				});
		}

	}

	const cargarMeticasTarjetas = () => {
		apiMetricas.obtener_tarjetas({
			client_id: user.clientSelected.client_id,
			start_date: format_date_request(rangoDate[0], 1),
			end_date: format_date_request(rangoDate[1], 2),
			gt_1kw: gt_1kw
		})
			.then((rslt) => {
				if (!rslt || Object.keys(rslt).length <= 0 || (rslt.error && rslt.error !== "no recharges in process")) {
					setMetricasTarjetas(tarjetasDefaultData)
				} else {
					setMetricasTarjetas(rslt);
				}
			})
			.catch((e) => {
				console.log('error (cargarMeticasTarjetas): ', e);
				setMetricasTarjetas(tarjetasDefaultData)
			});
	}

	const cargarDataEnergy = () => {
		setMetricasEnergy({ ...metricasEnergy, loading: true });
		let arrayTemp = [];

		if (locations.selected && locations.selected.length) {
			arrayTemp.push(locations.selected);
		} else {
			for (let i = 0; i < locations.data.length; i++) {
				arrayTemp.push(locations.data[i].id);
			}
		}

		if (arrayTemp.length >= 1) {
			apiMetricas.obtener_energia_conector({
				locations_id: arrayTemp,
				start_date: getUTCString(rangoDate[0]),
				end_date: getUTCString(rangoDate[1]),
				gt_1kw: gt_1kw
			})
				.then((rslt) => {
					if (rslt.length <= 0 || (rslt.error && rslt.error !== "no recharges in process")) {
						setMetricasEnergy({ data: [], loading: false });
					} else {
						setMetricasEnergy({ data: rslt, loading: false });
					}
				})
				.catch((err) => {
					console.log('error (cargarDataEnergy): ', err);
					setMetricasEnergy({ data: [], loading: false });
				});
		}
	}

	const setOnLoading = () => {
		setMetricasRecargas({
			...metricasRecargas,
			// data: [],
			loading: true
		})
		setEmissionData({
			...emissionData,
			// data: [],
			loading: true
		})
		setSessionsData({
			// ...sessionsData,
			data: [],
			loading: true
		})
		setEnergiaData({
			// ...energiaData,
			data: [],
			loading: true
		})
		setMetricasEnergy({
			...metricasEnergy,
			// data: [],
			loading: true
		});
	};

	const onChangeDateRange = (start, end) => {
		let dateStart = new Date(start);
		let dateEnd = new Date(end);

		setRangoDate([dateStart, dateEnd]);
		setOnLoading();
	};

	const [selected, setSelected] = useState([]);
	const onChangeLocation = (value) => {
		console.log('(onChangeLocation) -selected value: ', value)

		setLocations({ ...locations, selected: value.map(item => {
			return item.value
		}) });
		setSelected(value);
		setOnLoading();
	};

	const cargarLocations = () => {

		setLocations({ ...locations, loading: true });
		apiLocations.allLocations(user.user_id).then((rslt) => {

			let connectorArray = [];
			[...rslt].forEach((e) => {
				[...e.evses].forEach((e) => {
					[...e.connectors].forEach((e) => {
						connectorArray.push(e)
					});
				});
			});

			setConectorSelected({
				...conectorSelected,
				data: connectorArray
			});
			
			setLocations({ ...locations, data: rslt, loading: false });

			setLocationsOptions(rslt.map((item) => {
				return {'label': item.name, 'value': item.id}
			}))
		}).catch((err) => {	
			setLocations({ ...locations, loading: false });
			console.log('error (cargarLocations): ', err);
		});
	}

	const cargarDisponibilidad = () => {
		let arrayTemp = [];
		
		if (locations.selected && locations.selected.length) {
			arrayTemp.push(locations.selected);
		} else {
			for (let i = 0; i < locations.data.length; i++) {
				arrayTemp.push(locations.data[i].id);
			}
		}

		if (arrayTemp.length >= 1) {
			apiMetricas.obtener_disponibilidad({
				locations_id: arrayTemp,
				start_date: format_date_request(rangoDate[0], 1),
				end_date: format_date_request(rangoDate[1], 2),
				gt_1kw: gt_1kw
			})
				.then((rslt) => {
					if (!rslt || Object.keys(rslt).length <= 0 || (rslt.error && rslt.error !== "no recharges in process")) {
						setMetricaDispo({ data: disponibilidadDefaultData });
					} else {
						setMetricaDispo(rslt);
					}
				})
				.catch((err) => {
					setMetricaDispo({ data: disponibilidadDefaultData });
					console.log('error (cargarDisponibilidad): ', err)
				});
		}
	}

	const cargarConectores = () => {
		let connectorArray = [];
		if (!locations.data[`${locations.selected}`]) return;
		[...locations.data[`${locations.selected}`].evses].forEach((e) => {
			[...e.connectors].forEach((e) => {
				connectorArray.push(e)
			});
		});
		setConectorSelected({
			...conectorSelected,
			data: connectorArray
		}
		);
	}

	const tooltipPeekRecharges = () => {
		return (
			<Row className={'metricas-tooltip'}>
				<Col span={24}>
					<p className={'metricas-tooltip_title'}>{t('metrics.card6.recharges.tooltip.title')}</p>
					<p dangerouslySetInnerHTML={{ __html: t('metrics.card6.recharges.tooltip.body') }} />
					<p className={'metricas-tooltop_legend'} dangerouslySetInnerHTML={{ __html: t('metrics.card6.recharges.tooltip.footer') }} />
				</Col>
			</Row>
		);
	}

	return (
		<Row className={'metricas-row_main'}>
			<Col span={24}>
				<Breadcrumb breadcrumbData={breadcrumbData}></Breadcrumb>
			</Col>

			<Row className={'metricas-content'} gutter={[16, 16]}>
				<Col className={'metricas-col'} sm={24} lg={24} >
					<Form>
						<Row>
							<Col className={''} sm={24} lg={20}>
								<Row>
									<DateSelectorGroup
										onChangeDateRange={onChangeDateRange}
										setPeriod={setPeriodData}
										periodContainer={periodData} />

									<Col className={'filtro-col'} sm={24} lg={6}>
										<Form.Item className={'metricas-form_filtro'} label={t('global.word.station')}>
											<div className='container-select-multiple'>
												<MultiSelect
													options={locationsOptions}
													value={selected}
													onChange={onChangeLocation}
													labelledBy={(locations.loading ? t('global.selector.loading') : (locations.data.length > 0 ? t('global.selector.stations.default.all') : t('global.selector.stations.default.noitems')))}
													isLoading={locations.loading}
												/>
											</div>
										</Form.Item>
									</Col>
								</Row>
							</Col>
							<Col className={''} sm={24} lg={4}>
								<span className="pr-1">{t('states.show1kwrecharges')}</span>
								<Switch onChange={onChangeSwitch} />
							</Col>
						</Row>
					</Form>
				</Col>

				{/* first cards row */}
				<Col className={'metricas-col'} sm={24} lg={24}>
					<Row gutter={[16, 16]}>
						<Col className={['metricas-card_data w-100']} sm={12} lg={12} >
							<CardCategory loading={metricasRecargas.loading} mainValue={metricasRecargas.energy_avg.toFixed(2)} icon={faBolt} iconColor={'#ee7400'} description={t('metrics.card6.avgenergy.avg')}></CardCategory>
						</Col>
						<Col className={'metricas-card_data w-100'} sm={12} lg={12}>
							<CardCategory loading={metricasRecargas.loading} mainValue={metricasRecargas.charge_avg} icon={faClock} iconColor={'#52a7da'} description={t('metrics.card6.avgtime.title')} ></CardCategory>
						</Col>
					</Row>
				</Col>

				{/* second cards row */}
				<Col className={'metricas-col'} sm={24} lg={24} >
					<Row style={{ marginBottom: 0 }} gutter={[16, 16]}>
						<Col className={'metricas-card_data w-100'} sm={12} lg={8}>
							<CardDefault
								loading={metricasRecargas.loading}
								title={''}
								bottomMsg={[t('metrics.card1.recharges.avg'), t('metrics.card2.progress')]}
								mainValue={''}
								bottomCount={[metricasRecargas.recharges_avg, metricasRecargas.sessions_active]}>
								<Row className={'metricas-card_children'}>
									<Col style={{ fontWeight: 'bolder', fontSize: 25 }} span={10}>{t('metrics.card2.title')}</Col>
									<Col style={{ fontWeight: 'bolder', fontSize: 25 }} className={'metricas-card_children_value'} span={14}>
										<strong>
											{(parseFloat(metricasRecargas.energy_delivered)>=1000?(parseFloat(metricasRecargas.energy_delivered)/1000):parseFloat(metricasRecargas.energy_delivered)).toFixed(2)} {metricasRecargas.energy_delivered>=1000?'MWh':'kWh'}
										</strong>
									</Col>
								</Row>
							</CardDefault>
						</Col>
						
						
						
						
						<Col className={'metricas-card_data w-100'} sm={12} lg={8}>
							<CardDefault
								loading={metricasRecargas.loading}
								title={''}
								bottomMsg={[t('metrics.card1.recharges.remote'), t('metrics.card1.recharges.cards')]}
								mainValue={''}
								bottomCount={[metricasRecargas.recharges_remote_command, metricasRecargas.recharges_rfid]}>
								<Row className={'metricas-card_children'}>
									<Col style={{ fontWeight: 'bolder', fontSize: 25 }} span={15}>
										{t('metrics.card1.recharges.title')}
									</Col>
									<Col style={{ fontWeight: 'bolder', fontSize: 25 }} className={'metricas-card_children_value'} span={9}>
										<strong>{(metricasRecargas?.recharges_remote_command || 0)  + (metricasRecargas?.recharges_rfid || 0)}</strong>
									</Col>
								</Row>
							</CardDefault>
						</Col>

						<Col className={'metricas-card_data w-100'} sm={12} lg={8}>
							<CardCategory loading={emissionData.loading} mainValue={`${emissionData.data}\nTon CO2`} icon={faLeaf} iconColor={'#0dc390'} description={t('metrics.card2.emissions')} ></CardCategory>
						</Col>
					</Row>
				</Col>

				<Col className={'metricas-col w-100'} sm={24} lg={24} >
					<Row style={{ marginBottom: 0 }} className={'w-100'}>
						<Col sm={24} lg={12} className={'w-100 pb-sm-1 pr-md-1'}>
							<CardDefault
								loading={sessionsData.loading}
								title={t('metrics.card4.title')}
								mainValue={`${sessionsData.count}`}>
								<Row>
									<div className={'con-container'}>
										{/* <Radio.Group value={periodData.period} onChange={(e) => setPeriodData({ ...periodData, period: e.target.value })}>
											{periodData.parentPeriod === 'year' || periodData.parentPeriod === 'range' ? <Radio.Button value={'month'}>{t('global.word.month')}</Radio.Button> : ''}
											{periodData.parentPeriod === 'year' || periodData.parentPeriod === 'month' || periodData.parentPeriod === 'range' ? <Radio.Button value={'week'} >{t('global.word.week')}</Radio.Button> : ''}
											{periodData.parentPeriod === 'week' || periodData.parentPeriod === 'month' || periodData.parentPeriod === 'range' ? <Radio.Button value={'day'}>{t('global.word.day')}</Radio.Button> : ''}
											{periodData.parentPeriod === 'day' || periodData.parentPeriod === 'range' ? <Radio.Button value={'hour'}>{t('global.word.hours')}</Radio.Button> : ''}
										</Radio.Group> */}
									</div>
									<Col sm={24} lg={24} >
										{
											sessionsData.data.length > 0 ? (
												<ResponsiveContainer minHeight={50} height={200}>
													<BarChart data={sessionsData.data}>
														<CartesianGrid strokeDasharray="3 3" />
														<XAxis dataKey="name" />
														<YAxis />
														<Tooltip />
														<Legend margin={{ top: 10 }} />
														<Bar name={t('metrics.card4.title')} dataKey="value" fill={platform.main_color} />
													</BarChart>
												</ResponsiveContainer>
											) : (
												<Empty className={'empty-energia_entregada'} />
											)
										}
									</Col>
								</Row>
							</CardDefault>
						</Col>
						<Col sm={24} lg={12} className={'w-100 pt-sm-1 pl-md-1'}>
							<CardDefault
								loading={metricasRecargas.loading}
								title={t('metrics.card2.title')}
								mainValue={`${(parseFloat(metricasRecargas.energy_delivered)>=1000?(parseFloat(metricasRecargas.energy_delivered)/1000):parseFloat(metricasRecargas.energy_delivered)).toFixed(2)+' '+(parseFloat(metricasRecargas.energy_delivered)>=1000?'MWh':'kWh')}`}>
								<Row>
									<div className={'con-container'}>
										{/* <Radio.Group value={periodData.period} onChange={(e) => setPeriodData({ ...periodData, period: e.target.value })}>
											{periodData.parentPeriod === 'year' || periodData.parentPeriod === 'range' ? <Radio.Button value={'month'}>{t('global.word.month')}</Radio.Button> : ''}
											{periodData.parentPeriod === 'year' || periodData.parentPeriod === 'month' || periodData.parentPeriod === 'range' ? <Radio.Button value={'week'} >{t('global.word.week')}</Radio.Button> : ''}
											{periodData.parentPeriod === 'week' || periodData.parentPeriod === 'month' || periodData.parentPeriod === 'range' ? <Radio.Button value={'day'}>{t('global.word.day')}</Radio.Button> : ''}
											{periodData.parentPeriod === 'day' || periodData.parentPeriod === 'range' ? <Radio.Button value={'hour'}>{t('global.word.hours')}</Radio.Button> : ''}
										</Radio.Group> */}
									</div>
									<Col sm={24} lg={24} >
										{
											energiaData.data.length > 0 ? (
												<ResponsiveContainer minHeight={50} height={200}>
													<BarChart data={energiaData.data}>
														<CartesianGrid strokeDasharray="3 3" />
														<XAxis dataKey="name" />
														<YAxis />
														<Tooltip />
														<Legend margin={{ top: 10 }} />
														<Bar name={t('metrics.card2.title')} dataKey="value" fill={platform.main_color} />
													</BarChart>
												</ResponsiveContainer>
											) : (
												<Empty className={'empty-energia_entregada'} />
											)
										}
									</Col>

								</Row>

							</CardDefault>
						</Col>
					</Row>
				</Col>

				<Col className={'metricas-col'} sm={24} lg={24} >
					<Row style={{ marginBottom: 0 }} gutter={[16, 16]}>
						<Col sm={24} lg={12} className={'w-100'}>
							<CardCake loading={metricasEnergy.loading} dataRequest={metricasEnergy} />
						</Col>
						<Col sm={24} lg={12} className={'w-100'}>
							<CardEnergy loading={metricasEnergy.loading} locations={locations} dataRequest={metricasEnergy} rangoDate={rangoDate} />
						</Col>
					</Row>
				</Col>

				<Col className={'metricas-col'} sm={24} lg={24}>
					<Row gutter={[16, 16]}>
						<Col span={24}>
							<TableRecharges loading={metricasEnergy.loading} dataRequest={metricasEnergy} />
						</Col>
					</Row>
				</Col>
			</Row>
		</Row>
	);
}

export default Metricas;