import React, { useCallback, useState } from 'react';
import { Input, Typography, Card, Button, Divider, message, Space } from 'antd';
import PropTypes from 'prop-types';
import { useHistory } from "react-router-dom";
import { apiUser } from '@utils/services';

import './style.less';

const { Title, Paragraph } = Typography;

function RecuperarContrasena(props) {
  const {
    match: {
      params: { uid, token },
    },
  } = props;
  let history = useHistory();
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const onClickBtn = useCallback(
    () => {
      const body = {
        uid,
        token,
        new_password: password,
      };

      setLoading(true);
      apiUser
        .resetPasswordConfirm(body)
        .then((rslt) => {
          setLoading(false)
          // console.log(rslt);

          if(!rslt || !rslt.new_password[0]){
            message.success('');
            history.push("/ingreso-cpo");
          }else if(rslt.new_password){
            let message_array = rslt.new_password;
            let message_concat = '';
            for(let i = 0 ; i < message_array.length ; i++){
              message_concat += `${message_array[i]} `;
            } 
            message.error(message_concat);
          } 
          
        })
        .catch((error) => {
          setLoading(false);
          console.log('error: ',error);
          message.error(error.message);
        });
    },
    // eslint-disable-next-line
    [password]
  );

  return (
    <div id="recover-password">
      <Card>
        <div className="content">
          <header>
            <Space size="large" direction="vertical">
              <img width={60} src={`${process.env.PUBLIC_URL}/logo192.png`} alt="Logo" />
              <Title level={4}>Actualiza tu contraseña</Title>
            </Space>
            <Divider />
          </header>

          <Paragraph>Por favor ingresa tu nueva contraseña</Paragraph>
          <Input.Password
            size="large"
            value={password}
            onChange={({ target }) => setPassword(target.value)}
          />

          <br />
          <Button size="large" onClick={onClickBtn} loading={loading}>
            Actualizar constraseña
          </Button>
        </div>
      </Card>
    </div>
  );
}

RecuperarContrasena.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      uid: PropTypes.string,
      token: PropTypes.string,
    }),
  }).isRequired,
};

export default RecuperarContrasena;
