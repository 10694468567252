import React, { useState } from 'react';
import { Form, Input, Checkbox, Button, Space, Alert } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';

import { login, logout } from '@utils/auth/createAuthProvider';
import { apiUser } from '@utils/services';

import RecoveryPassword from './components/RecoryPassword';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
const Login = (props) => {
  //const { theme } = props;
  const { t, /* i18n */ } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const history = useHistory();
  const onFinish = async (values) => {
    setLoading(true);
    let response;
    try {
      response = await apiUser.createJwt(values);
    } catch (err) {
      setLoading(false);
      setError(err);
      return;
    }

    const { access: accessToken, refresh: refreshToken, user_data: userData } = response;
    login({ accessToken, refreshToken }, userData);

    const { remember } = values;
    if (!remember) {
      // eslint-disable-next-line no-undef
      window.addEventListener('beforeunload', (ev) => {
        ev.preventDefault();
        logout();
      });
    }

    history.replace('/tablero-cpo');
  };

  return (
    <Form style={{ marginTop: 32 }} onFinish={onFinish} size="large">
      <Form.Item
        name="username"
        normalize={(e) => {
          return `${e}`.toLowerCase();
        }}
        rules={[{ required: true, message: t('login.form.email.extra') }]}
      >
        <Input
          size="large"
          prefix={<UserOutlined />}
          placeholder={t('login.form.email')}
        />
      </Form.Item>

      <Form.Item
        name="password"
        rules={[{ required: true, message: t('login.form.password.extra') }]}
      >
        <Input.Password size="large" prefix={<LockOutlined />} placeholder={t('login.form.password')} />
      </Form.Item>

      <Space align="start">
        <Form.Item name="remember" valuePropName="checked">
          <Checkbox>{t('login.form.savesession')}</Checkbox>
        </Form.Item>

        {/* <Button type="link">¿Olvidaste tu contraseña?</Button> */}
        <RecoveryPassword />
      </Space>

      {error && (
        <Alert
          style={{ marginBottom: 20 }}
          message="Error en consulta"
          description={error.message}
          type="error"
          showIcon
        />
      )}

      <Form.Item>
        <Button type="primary" htmlType="submit" size="large" block loading={loading}>
          {t('login.form.login')}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default Login;
