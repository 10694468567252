import React, { useState } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import ConfigMenu from '../../containers/ConfigMenu';
import MainMenu from '../../containers/MainMenu';

const SideNavSwitch = (props) => {
  const match = useRouteMatch();
  const { history } = props;
  const isMenuCollapsed = useState(true);
  const classMenu = isMenuCollapsed ? 'menu--collapsed' : 'menu';
  // const sideNavRoute = `${match.path}`;

  return (
    <Switch>
        <Route strict={true} path={["/configuracion-sitio/","/configuracion-cuenta/"]} render={ () => <ConfigMenu className={`menu-wrapper__${classMenu}`} history={history} />} />
        <Route strict={true} path="/" render={ () => <MainMenu className={`menu-wrapper__${classMenu}`} history={history} />} />
    </Switch>
  );
};

export default SideNavSwitch;