/* eslint-disable no-undef */
import { apiUser } from '@utils/services';

const createTokenProvider = () => {
  let token = JSON.parse(localStorage.getItem('REACT_TOKEN_AUTH') || 'null') || null;
  let observers = [];

  const getExpirationDate = (jwtToken) => {
    if (!jwtToken) return null;

    const jwt = JSON.parse(atob(jwtToken.split('.')[1]));
    return (jwt && jwt.exp && jwt.exp * 1000) || null;
  };

  const isExpired = (exp) => {
    if (!exp) return false;
    return Date.now() > exp;
  };

  const isLoggedIn = () => {
    return !!token;
  };

  const notify = () => {
    const isLogged = isLoggedIn();
    observers.forEach((observer) => observer(isLogged));
  };

  const setToken = (newToken) => {
    if (newToken) {
      localStorage.setItem('REACT_TOKEN_AUTH', JSON.stringify(newToken));
    } else {
      localStorage.removeItem('REACT_TOKEN_AUTH');
    }

    token = newToken;
    notify();
  };

  const getToken = async () => {
    if (!token) return null;
    const { accessToken, refreshToken } = token;

    if (isExpired(getExpirationDate(accessToken))) {
      let resUpdatedToken;
      try {
        resUpdatedToken = await apiUser.updateJwt({ refresh: refreshToken });
      } catch (error) {
        return setToken(null);
      }
      const { access } = resUpdatedToken;
      setToken({ accessToken: access, refreshToken });
    }

    return token && token.accessToken;
  };

  const subscribe = (observer) => {
    observers.push(observer);
  };

  const unsubscribe = (observer) => {
    observers = observers.filter((_observer) => _observer !== observer);
  };

  return {
    getToken,
    isLoggedIn,
    setToken,
    subscribe,
    unsubscribe,
  };
};

export default createTokenProvider;
