import store from '@store/';
import { getToken } from '@utils/auth/createAuthProvider';
import default_settings from './default';

const baseUrl = process.env.REACT_APP_BACKEND_HOST ? process.env.REACT_APP_BACKEND_HOST : default_settings.default_endpoint; //?

function getTenantUrl() {
  const { clientSelected = {} } = store.getState().user || {};
  const { client_domain: domain } = clientSelected;
  if (!domain) return null;
  return `${baseUrl}/tenant/${domain}/api/1.0`;
}

async function baseRequest({ endpoint, requireAuth = false, method = 'GET', body, signal }) {
  const headers = {
     'Content-Type': 'application/json',
  };

  if (requireAuth) {
    const token = await getToken();
    headers.Authorization = `JWT ${token}`;
  }

  const defBody = body ? JSON.stringify(body) : undefined;

  return new Promise((resolve, reject) => {
    // eslint-disable-next-line no-undef
    fetch(endpoint, { headers, method, signal, body: defBody })
      .then((response) => {
        if(response.status === 204) return null;
        if (response.ok) return response.json();
        if (response.status === 400) return response.json();
        const { status, statusText } = response;
        const error = new Error(statusText);
        error.status = status;
        throw error;
      })
      .then(resolve)
      .catch(reject);
  });
}

export { baseUrl, getTenantUrl, baseRequest };
