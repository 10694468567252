import React, { useEffect, useState } from 'react';
import Vehiculo from '../../VehiculeCard/VehiculeCard';
import './Table.less';

export default function Table(props) {
  const { numberOfChargers, columns, setIdVehicule, handleNuevoPerfilClick } = props;
  const [chargers, setChargers] = useState([]);

  useEffect(() => {
    setChargers(Array.from({ length: numberOfChargers }));
  }, [numberOfChargers]);
  return (
    <ul className="table">
      {chargers.map((charger, index) => (
        <li key={index} className="table__item">
          {columns.priority4.items[index] && (
            <Vehiculo
              item={columns.priority4.items[index]}
              setIdVehicule={setIdVehicule}
              handleNuevoPerfilClick={handleNuevoPerfilClick}
            />
          )}
        </li>
      ))}
    </ul>
  );
}
