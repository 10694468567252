import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChargingStation } from '@fortawesome/free-solid-svg-icons';
import TableroCpo from '@views/TableroCPO'
const path = '/tablero-cpo';

/**
 * Tablero CPO Cambia su nombre debido a 
 * nuevos modelos y diseños presentados
 */



const tableroCpo = ( t ) => {

    return {
        path,
        name: t('menus.tablecpo'),
        icon: <FontAwesomeIcon icon={faChargingStation} className="anticon" style={{ marginRight: 10, fontSize: 21, minWidth: "auto" }}  />,
        component: TableroCpo,
        key: 'item-2',
        children: [
        {
            path: `${path}/tablas`,
            name: 'tablas',
            key: 'item-2',
        },
        {
            path: `${path}/mapa`,
            name: 'mapa',
            key: 'item-2',
        },
        ],
    }
    
};

export default tableroCpo;
