import React, { useEffect, useState } from 'react';
import {
  BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Label, Text, Brush
} from 'recharts';
import { Row, Col, Card, Table, Empty, Spin } from 'antd';
import mapColor from '@utils/helpers/colorMap'
import getUTCString from '@utils/helpers/UtcRequestConverter'
import getDateLocalString from '@utils/helpers/UtcResponseReceive'
import './style.less'
import { useTranslation } from 'react-i18next'

const CardEnergy = (props) => {
  const { t, i18n } = useTranslation();
  const { dataRequest, loading } = props;
  const [data, setData] = useState({
    table: [],
    graphic: [],
    connectorsLabel: [],
    colorsLabel: [],
    tableFilters: {
      location: [],
      evse: [],
      connector: [],
      type: [],
    },
    loadingTable: false,
  });
  const [maxValue, setMaxValue] = useState(0);
  const [maxValueRound, setMaxValueRound] = useState(0);

  const columns = [
    {
      title: t('global.word.station'),
      dataIndex: 'location',
      key: 'location',
      fixed: 'left',
      filters: data.tableFilters.location,
      onFilter: (value, record) => record.location.indexOf(value) === 0,
      sorter: (a, b) => a.location.length - b.location.length,
    },
    {
      title: t('global.word.charger'),
      dataIndex: 'evse',
      key: 'evse',
      filters: data.tableFilters.evse,
      onFilter: (value, record) => record.evse.indexOf(value) === 0,
      sorter: (a, b) => a.evse.length - b.evse.length,
    },
    {
      title: t('global.word.connector'),
      dataIndex: 'connector',
      key: 'connector',
      filters: data.tableFilters.connector,
      onFilter: (value, record) => record.connector.indexOf(value) === 0,
      sorter: (a, b) => a.connector.length - b.connector.length,
    },
    {
      title: t('global.word.type'),
      dataIndex: 'type',
      key: 'type',
      filters: data.tableFilters.type,
      onFilter: (value, record) => record.type.indexOf(value) === 0,
      sorter: (a, b) => a.type.length - b.type.length,
    },
    {
      title: t('global.word.energy'),
      dataIndex: 'energy',
      key: 'energy',
      sorter: (a, b) => a.energy.length - b.energy.length,
      render: (obj) => (`${obj.toFixed(2)}kWh`)
    },
  ]

  const simulate_data = () => {
    const data = [];

    for (let i = 0; i < 20; i++) {
      data.push({
        name: 'Page A_' + i,
        disponible: Math.random() * (100 - 0) + 0,
        fallo: Math.random() * (100 - 0) + 0,
        amt: Math.random() * (100 - 0) + 0,
      },);
    }

    return data;
  }

	const formatHighValue = (value) => {
		let valor = value;
		let ico = '';

		if (value >= 1000) {
			valor = (value / 1000).toFixed(0);
			ico = 'k'
		}

		return `${valor}${ico}`;
	}

  const StaticXAxis = () => {
    const dxIni = 0;
    const widthCustom = 100;
    const widthSteps = widthCustom / 10;
    const rotationCustom = 45
    return (
      <div className={'x-axis-wrapper'}>
        <svg className="recharts-surface svg-custom-xaxis" width="100%" >
          <g className="recharts-layer recharts-cartesian-axis recharts-xAxis xAxis"  >
            <line className="recharts-xAxis xAxis" type="number" orientation="bottom" width="800" height="30" x={dxIni} viewBox="0 0 800 300" stroke="#666" fill="none" x1={dxIni} x2={`${widthCustom}%`}  ></line>
            <g className="recharts-cartesian-axis-ticks">

							<g className="recharts-layer recharts-cartesian-axis-tick">
                <line className="recharts-xAxis xAxis" type="number" orientation="bottom" width="380" height="30" x="115" y="0" viewBox="0 0 800 300" stroke="#666" fill="none" x1={`${widthSteps * 0}%`} y1="5" x2={`${widthSteps * 0}%`} y2="0"  ></line>
                <g >
                  <text dx={dxIni} dy="16" textAnchor="left" fill="#666">{parseFloat(maxValueRound * 0)}</text>
                </g>
              </g>

              <g className="recharts-layer recharts-cartesian-axis-tick">
                <line className="recharts-xAxis xAxis" type="number" orientation="bottom" width="380" height="30" x="115" y="0" viewBox="0 0 800 300" stroke="#666" fill="none" x1={`${widthSteps * 2}%`} y1="5" x2={`${widthSteps * 2}%`} y2="0" ></line>
                <g>
                  <text dx={`${widthSteps * 2}%`} dy="16" textAnchor="middle" fill="#666">{formatHighValue(maxValueRound > 1 ? parseInt(maxValueRound * 0.2) : parseFloat(maxValueRound * 0.2).toFixed(2))}</text>
                </g>
              </g>

              <g className="recharts-layer recharts-cartesian-axis-tick">
                <line className="recharts-xAxis xAxis" type="number" orientation="bottom" width="380" height="30" x="115" y="0" viewBox="0 0 800 300" stroke="#666" fill="none" x1={`${widthSteps * 4}%`} y1="5" x2={`${widthSteps * 4}%`} y2="0" ></line>
                <g>
                  <text dx={`${widthSteps * 4}%`} dy="16" textAnchor="middle" fill="#666">{formatHighValue(maxValueRound > 1 ? parseInt(maxValueRound * 0.4) : parseFloat(maxValueRound * 0.4).toFixed(2))}</text>
                </g>
              </g>

              <g className="recharts-layer recharts-cartesian-axis-tick">
                <line className="recharts-xAxis xAxis" type="number" orientation="bottom" width="380" height="30" x="115" y="0" viewBox="0 0 800 300" stroke="#666" fill="none" x1={`${widthSteps * 6}%`} y1="5" x2={`${widthSteps * 6}%`} y2="0" ></line>
                <g>
                  <text dx={`${widthSteps * 6}%`} dy="16" textAnchor="middle" fill="#666">{formatHighValue(maxValueRound > 1 ? parseInt(maxValueRound * 0.6) : parseFloat(maxValueRound * 0.6).toFixed(2))}</text>
                </g>
              </g>

              <g className="recharts-layer recharts-cartesian-axis-tick">
                <line className="recharts-xAxis xAxis" type="number" orientation="bottom" width="380" height="30" x="115" y="0" viewBox="0 0 800 300" stroke="#666" fill="none" x1={`${widthSteps * 8}%`} y1="5" x2={`${widthSteps * 8}%`} y2="0" ></line>
                <g>
                  <text dx={`${widthSteps * 8}%`} dy="16" textAnchor="middle" fill="#666">{formatHighValue(maxValueRound > 1 ? parseInt(maxValueRound * 0.8) : parseFloat(maxValueRound * 0.8).toFixed(2))}</text>
                </g>
              </g>

              <g className="recharts-layer recharts-cartesian-axis-tick">
                <line className="recharts-xAxis xAxis" type="number" orientation="bottom" width="380" height="30" x="115" y="0" stroke="#666" fill="none" x1={`${widthSteps * 10}%`} y1="5" x2={`${widthSteps * 10}%`} y2="0" ></line>
                <g>
                  <text dx={`${widthSteps * 10}%`} dy="16" textAnchor="end" fill="#666">{formatHighValue(maxValueRound > 1 ? parseInt(maxValueRound) : parseFloat(maxValueRound).toFixed(2))}</text>
                </g>
              </g>

            </g>
          </g>
        </svg>
      </div>
    )
  }

  const CustomizedAxisTick = props => {
    const { x, y, payload } = props

    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={16} rotate={45} textAnchor={'start'} fill='#666'>asd{payload.value}%</text>
      </g>
    )


  }

	const formatter_duration = ( value ) => {
    if(isNaN(value)) value = 0;
    if(value < 0) value = (value * -1);

    let sec = parseInt(value);

    let time = ((sec / 60) / 60).toFixed(2);

    return time;
  }

	const CustomTooltip = ( { active, payload, label } ) => {
    if (active && payload && payload.length) {

      const pTemp = payload[0].payload;
      const conns = pTemp.connectors;
      return (
        <div className="uso-tooltip">
          <p className="location">{`${pTemp.location}`}</p>
          <p className="evse">{`${pTemp.name} ${formatter_duration(pTemp.value)} (h)`}</p>
          {
            conns.map((v,i) => {
              return(
                <div key={i}>
                  <p className={'type'}>{`${v.conn_id} - ${v.standard} : ${formatter_duration(v.time_usage)} (h)`}</p>
                </div>
              )
            })
          }
        </div>
      );
    }
    return null;
  }

  const CustomizedYAxisTick = props => {
    const { x, y, payload } = props
    const yname = `${payload.value}`.split('/');
    if (yname.length > 0) {
      const location = yname[0];
      const conn = yname[1];
      return (
        <g transform={`translate(${x},${y})`}>
          <text dy={-3} textAnchor='end' fill='#666' >{location}</text>
          <text dy={11} textAnchor='end' fill='#666' >{conn}</text>
        </g>
      )
    } else {
      return (
        <g transform={`translate(${x},${y})`}>
          <text dy={-3} textAnchor='end' fill='#666' >{'Desconocido'}</text>
          <text dy={11} textAnchor='end' fill='#666' >{'Desconocido'}</text>
        </g>
      )
    }

  }

  useEffect(() => {
    if (dataRequest.data) {
      const arrayData = dataRequest.data;
      const arrayConnectors = [];
      const arrayGraphicConnectors = [];
      const maxArrayConnectors = new Set();
      const maxArrayColors = [];
      let maxV = 0;
      let maxC = 0;
      const locTemp = new Set();
      const evseTemp = new Set();
      const conTemp = new Set();
      const typeTemp = new Set();

      let indice = 0;
      for (let i = 0; i < arrayData.length; i++) {
				indice = maxArrayColors.length;
				let contConector = 0;

        let location = arrayData[i];
        if (!locTemp.has(location.location_name)) locTemp.add(location.location_name);
        if (!evseTemp.has(location.evse_uid)) evseTemp.add(location.evse_uid);
        let connectors = location.connectors;
        let gObject = {
          name: `${location.location_name}/${location.evse_uid}`,
        }

        for (let c = 0; c < connectors.length; c++) {
					let connector = connectors[c];
          if (!conTemp.has(connector.conn_id)) conTemp.add(connector.conn_id);
          if (!typeTemp.has(connector.standard)) typeTemp.add(connector.standard);

          if (!maxArrayConnectors.has(connector.conn_id)) {
            const color = mapColor()[(c + indice) % mapColor().length]
            maxArrayConnectors.add(connector.conn_id);
            maxArrayColors.push(color);
          }

          arrayConnectors.push({
            key: `${i}${c}`,
            location: location.location_name,
            evse: location.evse_uid,
            connector: connector.conn_id,
            type: connector.standard,
            energy: connector.energy.toFixed(2)
          });

          // gObject[`conector_${connector.conn_id}`] = (Math.random() * (100 - 0 ) + 0);//connector.energy;
          if (connector.energy !== 0) {
            gObject[`conector_${connector.conn_id}`] = connector.energy.toFixed(2);
            if (maxV < connector.energy) maxV = connector.energy;
          }

					contConector += connector.energy;
        }

				if (maxC < contConector) maxC = contConector;

        if (Object.keys(gObject).length > 1) arrayGraphicConnectors.push(gObject);
      }

      setMaxValue(maxV);
      setMaxValueRound(Math.round(maxC + 1));

      setData({
        ...data,
        table: arrayConnectors,
        tableFilters: {
          location: [...locTemp].map((e) => ({ text: e, value: e })),
          evse: [...evseTemp].map((e) => ({ text: e, value: e })),
          connector: [...conTemp].map((e) => ({ text: e, value: e })),
          type: [...typeTemp].map((e) => ({ text: e, value: e })),
        },
        loadingTable: false,
        graphic: arrayGraphicConnectors,
        connectorsLabel: [...maxArrayConnectors],
        colorsLabel: [...maxArrayColors]
      });
    }

  }, [dataRequest.data]);

  return (
    <Card className={'card-energy'} style={{ minHeight: '669px' }}>
      {!loading && (
        <div>
          <h2 className={'energy-title'}>{t('metrics.card3.title')}</h2>
          {/* {data.graphic.length > 0 ? <StaticXAxis /> : ''} */}
          <div className={'div-graphic'}>
            <ResponsiveContainer
              className={'graphic-bar'}
              minHeight={(30 * (data.graphic.length + 4))}
            >
              <BarChart
                data={data.graphic}
                layout={'vertical'}
              >
                {data.graphic.length > 0
                  ? <CartesianGrid stroke="#f5f5f5" />
                  : ''
                }
                {data.graphic.length > 0
                  ? <XAxis
                    // hide
                    axisLine
										type={'number'}
                    domain={[0, maxValueRound]}
										/>
                  : '' 
                }
                <YAxis interval={0} tick={CustomizedYAxisTick} width={110} dataKey={'name'} type={'category'} />
                <Tooltip />
								{/* <Tooltip content={<CustomTooltip/>} /> */}
                <Legend align={'center'} verticalAlign={'top'} height={60} />
                {data.connectorsLabel.map((v, i) => {
                  return (
                    <Bar key={i} dataKey={`conector_${v}`} name={`${t('global.word.connector')} ${v} (kW)`} stackId="a" fill={`${data.colorsLabel[i]}`} />
                  )
                })}
              </BarChart>
            </ResponsiveContainer>
            {data.graphic.length === 0 ? <Empty className={'empty-graph-energy'} /> : ''}
          </div>
        </div>
      )}
      {loading && <Spin className={'loading-cake'} />}
    </Card>
  );
}

CardEnergy.defaultProps = {
  loading: true
}

export default CardEnergy;