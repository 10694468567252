const getStatusDict = (key,t) => {
    const estado_definicion = {
        AVAILABLE:[ t ? t('states.available') : "Disponible","#0DC390"],
        BLOCKED:[  t ? t('states.blocked') : "BLOCKED","#262626"],
        CHARGING:[  t ? t('states.charging') : "Cargando","#49A2D8"],
        INTEROPERATIVE:[  t ? t('states.interoperative') : "INTEROPERATIVE","#262626"],
        OUTOFORDER:[  t ? t('states.outoforder') : "Fuera de servicio","#262626"],
        PLANNED:[  t ? t('states.planned') : "Fallo de conexión","#F60141"],
        REMOVED:[  t ? t('states.removed') : "REMOVED","#262626"],
        RESERVED:[  t ? t('states.reserver') : "Reservado","#F0B518"],
        UNKNOWN:[ t ? t('states.unknown') : "Desconocido","#F60141"],
        UNKNOW:[  t ? t('states.unknown') : "Desconocido","#F60141"]
    };

    let estado = estado_definicion[`${key}`];

    if(!estado){
        const keys = Object.keys(estado_definicion);
        for(let i = 0 ; i < keys.length ; i++){
            if(estado_definicion[`${keys[i]}`][0] === key){
                estado = estado_definicion[`${keys[i]}`];
                break;
            }
        }
    }

    return estado;
}

export default getStatusDict;