import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Row,Col,Card, Space, Statistic, Typography  } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './style.less';
const CardMap = ( { items, focusable = true, handleClick } ) => {
    const { Paragraph } = Typography;
    const [ focus , setFocus ] = useState(false);
    const { user, platform, locations:locationsItems } = useSelector((state) => state);

    const handleLocalClick = () => {
        handleClick(!focus);
        if(focusable)setFocus(!focus);
    }

    return(
			items.map(( {icon, title, value, color, active} , i) => {
        return (
        	<Card className={'card-map-info'} style={{borderColor: active ? platform.main_color : 'transparent'}} onClick={handleLocalClick}>
            <Row gutter={[10,10]}>
							<Col key={i} >
									{ icon 
										? <FontAwesomeIcon className="anticon" style={{ color: color ? color :  'black', fontSize: 18, minWidth: "auto", marginBottom:0 }} icon={icon} />
										: ''
									}
									<Statistic valueStyle={{ fontSize: 24, color: 'black' }} value={ value ? value : 0} />
									<p className={'card-title'}>{ title ? title : ''}</p>
							</Col>
            </Row>
        	</Card>
				)
    	})
    );
}

export default CardMap;