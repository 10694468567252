import { get,getCSV } from './management';

const lastSession = (evse_id, conn_id) => {
    return get('/sessions/evse-active-session/'+ evse_id +'/'+ conn_id +'/', true);
};

const getHistoSession = ( {evse_uid,start_date,end_date}) => {
    return get(`/sessions/?status=COMPLETED&start_date_time=${start_date}&end_date_time=${end_date}${evse_uid ? `&evse_uid__uid=${evse_uid}`:``}`, true);
}

const getActiveSession = ( {evse_uid,start_date,end_date}) => {
    return get(`/sessions/?status=ACTIVE${evse_uid ? `&evse_uid__uid=${evse_uid}`:``}`, true);
}

const getHistoSessionCSV = ( {evse_uid,start_date,end_date}) => {
    return getCSV(`/sessions/?start_date_time=${start_date}&end_date_time=${end_date}${evse_uid ? `&evse_uid__uid=${evse_uid}`:``}&export=true`, true);
}

const getSessionMeters = ( session_id ) => {
    return get(`/sessions/measures/?session_id=${session_id}`, true);
}


export default {  getActiveSession, getSessionMeters, lastSession, getHistoSession, getHistoSessionCSV };