import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Row, Col, Space, Select, Typography, DatePicker } from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next'
const { Paragraph } = Typography;
const { Option } = Select;
const { RangePicker } = DatePicker;

const DateSelectorGroup = (props) => {
  const { onChangeDateRange, defaultValue, rangeDefault, setPeriod, periodContainer } = props;
  const { t, i18n } = useTranslation();
  const dateFormat = 'YYYY-MM-DD';

  const [dateSelect, setDateSelect] = useState(defaultValue);
  const [rangeSelect, setRangeSelect] = useState(defaultValue);
  const displayRangePicker = ['range','week','current-week'].includes(dateSelect) ? 'inline-flex' : 'none';
  const rangeDateString = useRef([]);
  const typeValue = useRef(defaultValue);
  const startDate = useRef(null);
  const endDate = useRef(moment());
  const rangeDefault2 = [moment().format(dateFormat), moment().format(dateFormat)];

  const onOpenChange = (open) => {
    if (open) return;
    const [start, end] = rangeDateString.current;
    if (!start && !end) return;

    const defStart = moment(start);
    const defEnd = moment(end);

    onChangeDateRange(defStart, defEnd, typeValue.current);
  };

  const onChange = useCallback(
    (value) => {

      typeValue.current = value;
      let typePeriod = '';
      
			switch (value) {
        case 'current-day':
          startDate.current = moment().startOf('day');
          typePeriod = 'day';
          break;

        case 'current-week':
          startDate.current = moment().startOf('week');
          typePeriod = 'week';

          if(setPeriod) setPeriod({...periodContainer, parentPeriod:typePeriod})
					rangeDateString.current = [startDate.current.format('YYYY-MM-DD').toString(), moment().format('YYYY-MM-DD').toString()];
          onOpenChange(false);
          setDateSelect(value);

          break;

        case 'current-month':
          startDate.current = moment().startOf('month');
          typePeriod = 'month';
          break;

        case 'current-year':
          startDate.current = moment().startOf('year');
          typePeriod = 'year';
          break;

        default:
					typePeriod = 'range';

          if(setPeriod) setPeriod({...periodContainer, parentPeriod:typePeriod})
          onOpenChange(false);
          setDateSelect(value);

          break;
      }

      if(setPeriod) setPeriod({...periodContainer, parentPeriod:typePeriod})

      startDate.current = moment(startDate.current);

      if (startDate.current && moment()) {
        onChangeDateRange(startDate.current, moment(), typeValue.current);
      }

      setDateSelect(value);
    },
    // eslint-disable-next-line
    [onChangeDateRange]
  );

  const onChangeRangePicker = (value, dateString) => {
    //console.log('** value, dateString: ', value, dateString)

    rangeDateString.current = dateString;
  };

  const disabledDate = (current) => {
    return current.diff(moment(), 'min') > 0;
  };

  useEffect(() => {
    if (!dateSelect) onChange('current-day');
  }, [dateSelect, onChange]);
  
  useEffect(() => {
    // setDefaultDate(defStart.format('DD/MM/YYYY'), defEnd.format('DD/MM/YYYY'))
    
    //console.log("defStart.format('DD/MM/YYYY'), defEnd.format('DD/MM/YYYY'): ", defStart.format('DD/MM/YYYY'), defEnd.format('DD/MM/YYYY'))
  }, []);

  return (
    <div className="container-filtro-date d-flex">
			<Row className="container-date px-1">
				<Paragraph style={{ margin: 0 }}>{t('global.date')}:</Paragraph>
				<Select value={dateSelect} style={{ width: 200 }} onChange={onChange}>
					<Option value="current-day">{t('global.date.selector.today')}</Option>
					<Option value="current-week">{t('global.date.selector.week')}</Option>
					<Option value="current-month">{t('global.date.selector.month')}</Option>
					<Option value="current-year">{t('global.date.selector.year')}</Option>
					<Option value="range">{t('global.date.selector.custom')}</Option>
				</Select>
			</Row>
      <RangePicker
        style={{ display: displayRangePicker }}
        format={dateFormat}
        onChange={onChangeRangePicker}
        onOpenChange={onOpenChange}
        disabledDate={disabledDate}
      />
    </div>
  );
};

DateSelectorGroup.propTypes = {
  onChangeDateRange: PropTypes.func.isRequired,
  defaultValue: PropTypes.oneOf([
    'current-day',
    'current-week',
    'current-month',
    'current-year',
    'range',
  ]),
  rangeDefault: PropTypes.arrayOf(PropTypes.any),
};

DateSelectorGroup.defaultProps = {
  defaultValue: null,
  // rangeDefault: [null, null],
  rangeDefault: [moment().startOf('week').format('YYYY-MM-DD').toString(), moment().format('YYYY-MM-DD')],
};

export default DateSelectorGroup;
