import React, { useState, useEffect } from 'react';
import { Row, Col, Table, message, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { DateSelectorGroup } from '@components/ui';
import { Spanish } from '@utils/helpers/dictIdiomas';
import getDateLocalString from '@utils/helpers/UtcResponseReceive';
import getUTCString from '@utils/helpers/UtcRequestConverter';
import { apiSessions } from '@utils/services/cpo'
import { export_to_csv } from '@utils/helpers/exportCSVUtil';
import ExtractDurationTime from '@utils/helpers/ExtractDurationTime';
const TableActive = () => {
  const { current_duration } = ExtractDurationTime;
  const { t, i18n } = useTranslation();
  const textLanguage = Spanish({ screen: 'cpo-recargas' });



  const rangoHoy = () => {
    let hoyTemp1 = new Date();
    let hoyTemp2 = new Date();

    hoyTemp1.setHours(0);
    hoyTemp1.setMinutes(0);
    hoyTemp1.setMilliseconds(0);

    hoyTemp2.setHours(23);
    hoyTemp2.setMinutes(59);
    hoyTemp2.setMilliseconds(59);

    return [hoyTemp1, hoyTemp2]
  }

  const [rangoDate, setRangoDate] = useState(rangoHoy());
  const [dataSource, setDataSource] = useState({
    data: [],
    dataFiltered: [],
    loading: false
  });

  const columns = [
    {
      title: t('global.word.city'),
      dataIndex: 'city',
      key: 'city',
      sorter: (a, b) => `${a.city}`.localeCompare(b.city),
      filters: [...new Set(dataSource.data.map((e) => e.city))].map((e) => ({ text: e, value: e })),
      onFilter: (value, record) => record.city === value,
    },
    {
      title: t('global.word.station'),
      dataIndex: 'location',
      key: 'location',
      sorter: (a, b) => `${a.location}`.localeCompare(b.location),
      filters: [...new Set(dataSource.data.map((e) => e.location))].map((e) => ({ text: e, value: e })),
      onFilter: (value, record) => record.location === value,
    },
    {
      title: t('global.word.charger'),
      dataIndex: 'evse',
      key: 'cargador',
      sorter: (a, b) => `${a.evse}`.localeCompare(b.evse),
      filters: [...new Set(dataSource.data.map((e) => e.evse))].map((e) => ({ text: e, value: e })),
      onFilter: (value, record) => record.evse === value,
    },
    {
      title: t('global.word.connector'),
      dataIndex: 'connector',
      key: 'connector',
      sorter: (a, b) => `${a.connector}`.localeCompare(b.connector),
      filters: [...new Set(dataSource.data.map((e) => e.connector))].map((e) => ({ text: e, value: e })),
      onFilter: (value, record) => record.connector === value,
    },
    {
      title: t('global.word.connector') + ' ID',
      dataIndex: 'connector_id',
      key: 'connector_id',
      sorter: (a, b) => `${a.connector_id}`.localeCompare(b.connector_id),
      filters: [...new Set(dataSource.data.map((e) => e.connector_id))].map((e) => ({ text: e, value: e })),
      onFilter: (value, record) => record.connector_id === value,
    },
    {
      title: 'RFID',
      dataIndex: 'rfid',
      key: 'rfid',
      sorter: (a, b) => `${a.rfid}`.localeCompare(b.rfid),
      filters: [...new Set(dataSource.data.map((e) => e.rfid))].map((e) => ({ text: e, value: e })),
      onFilter: (value, record) => record.rfid === value,
    },
    {
      title: t('global.word.start'),
      dataIndex: 'start',
      key: 'start',
      sorter: (a, b) => `${a.start}`.localeCompare(b.start),
      render: (e) => getDateLocalString(e),
    },
    {
      title: t('global.word.duration'),
      dataIndex: 'duration',
      key: 'duration',
      sorter: (a, b) => `${a.duration}`.localeCompare(b.duration),
      render: (e) => (current_duration(e))
    },
    {
      title: t('global.word.energy'),
      dataIndex: 'energy',
      key: 'energy',
      sorter: (a, b) => `${a.energy}`.localeCompare(b.energy),
    },
  ];

  useEffect(() => {
    getSessions();
  }, []);

  useEffect(() => {
    getSessions();
  }, [rangoDate]);

  const onChangeDateRange = (start, end) => {
    let dateStart = new Date(start);
    let dateEnd = new Date(end);

    setRangoDate([dateStart, dateEnd]);
  };

  const getSessionsCSV = () => {
    message.loading(textLanguage.REQUEST_DOWNLOAD_CSV);

    export_to_csv(
      dataSource.dataFiltered ? dataSource.dataFiltered.map((e) => ({ ...e, start: getDateLocalString(e.start), duration: current_duration(e.start) })) : null,
      dataSource.data.map((e) => ({ ...e, start: getDateLocalString(e.start), duration: current_duration(e.start) })),
      'Historial'
    );
  }



  const getSessions = () => {
    setDataSource({ ...dataSource, loading: true });
    apiSessions.getActiveSession({
      start_date: `${getUTCString(rangoDate[0])}`,
      end_date: `${getUTCString(rangoDate[1])}`,
    }).then((rslt) => {
      let dataToTable = rslt.map((v, i) => {
        let kwh = v.kwh ? (typeof v.kwh === 'string' ? parseFloat(v.kwh) : v.kwh) : 0;
        
        return {
          key: i,
          city: v.location_id.city,
          location: v.location_id.name,
          evse: v.evse_uid.uid,
          connector: v.connector_id.standard,
          connector_id: v.connector_id.conn_id,
          rfid: v.tag_id,
          start: v.start_date_time,
          end: v.end_date_time,
          duration: v.start_date_time,
          energy: `${(kwh >= 1000 ? (kwh / 1000) : kwh).toFixed(2) + (kwh >= 1000 ? 'MWh' : 'kWh')}`
        }
      })

      setDataSource({ data: dataToTable, loading: false });
    }).catch((err) => {
      console.log('err: ', err);
      setDataSource({ data: [], loading: false });
    });
  }


  return (
    <Row>
      <Col span={24} className={'recargas_curso_btn'}>

        <Button onClick={getSessionsCSV} style={{ float: 'right' }} type={'primary'}>{t('global.word.download')}</Button>
      </Col>

      <Col span={24}>
        <Table
          onChange={(p, f, s, { currentDataSource }) => setDataSource({ ...dataSource, dataFiltered: currentDataSource ? currentDataSource : null })}
          dataSource={dataSource.data}
          loading={dataSource.loading}
          columns={columns} />
      </Col>
    </Row>
  );
}


export default TableActive;