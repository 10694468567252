import userConst from '../constants/userConst';

const initState = {
  username: '',
  email: '',
  full_name: '',
  user_clients: [],
  clientSelected: null,
};

const userReducer = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    case userConst.UPDATE_USER:
      return {
        ...state,
        ...payload,
      };

    default:
      return state;
  }
};

export default userReducer;
