import React, { useState } from 'react';
import { Select, Typography, Card, Table, Tabs, Button,message,Row,Col } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWifi } from '@fortawesome/free-solid-svg-icons';
import { DateSelectorGroup } from '@components/ui';
import getUTCString from '@utils/helpers/UtcRequestConverter';
import { ExportToCsv } from 'export-to-csv';
import { Spanish } from '@utils/helpers/dictIdiomas'
import { export_to_csv } from '@utils/helpers/exportCSVUtil'
import { useTranslation } from 'react-i18next'
const { Paragraph } = Typography;
const { TabPane } = Tabs;
const { Option } = Select;
const textLanguage = Spanish({screen:'cpo-alerts'})

const alerta_definiciones = {
    CF:[textLanguage.ALERTS_CPO_TYPE_CF,`#49A2D8`]
}


const TablaAlertas = ( props ) => {
    
    const { data , loading, cargadores } = props;
    const { t, i18n } = useTranslation();
    
    const [pageSize, setPageSize] = useState(10);
    const [ tipoCargador , setTipoCargador ] = useState('');

    const columns = [
        {
            title:t('global.date'),
            dataIndex:'fecha',
            key:'fecha',
            sorter:(a,b) => {
                let dateA = new Date(a.fechaMillis);
                let dateB = new Date(b.fechaMillis);
                return dateA.getTime() - dateB.getTime();
            },
        },
        {
            title:'ID',
            dataIndex:'id',
            key:'id',
            sorter:(a,b) => {
                return `${a.id}`.localeCompare(b.id);
            },
        },
        {
            title:t('global.word.operator'),
            dataIndex:'operario',
            key:'operario',
            filters: [ ...new Set(data.map((e) => `${e.operario}`))].map((e) => ({text:`${e}`,value:`${e}`})),
            sorter:(a,b) => a.operario.localeCompare(b.operario),
            onFilter: (value, record) => record.operario.indexOf(value) === 0,
        },
        {
            title:t('global.word.station'),
            dataIndex:'estacion',
            key:'estacion',
            filters: [ ...new Set(data.map((e) => `${e.estacion}`))].map((e) => ({text:`${e}`,value:`${e}`})),
            sorter:(a,b) => {
                //console.log(a,b);
                return `${a.estacion}`.localeCompare(`${b.estacion}`);
            },
            onFilter: (value, record) => record.estacion.indexOf(value) === 0,
        },
        {
            title:`${t('global.word.connector')}_ID`,
            dataIndex:'conector_id',
            key:'conector_id',
            filters: [ ...new Set(data.map((e) => `${e.conector_id}`))].map((e) => ({text:`${e}`,value:`${e}`})),
            sorter:(a,b) => a.conector_id.localeCompare(b.conector_id),
            onFilter: (value, record) => record.conector_id.indexOf(value) === 0,
        },
        {
            title:t('global.word.connector'),
            dataIndex:'conector_tipo',
            filters: [ ...new Set(data.map((e) => `${e.conector_tipo}`))].map((e) => ({text:`${e}`,value:`${e}`})),
            key:'conector_tipo',
            onFilter: (value, record) => record.conector_tipo.indexOf(value) === 0,
            sorter:(a,b) => a.conector_tipo.localeCompare(b.conector_tipo),
        },
        {
            title:t('global.word.use'),
            dataIndex:'uso',
            key:'uso',
            filters: [ ...new Set(data.map((e) => `${e.uso}`))].map((e) => ({text:`${e}`,value:`${e}`})),
            onFilter: (value, record) => record.uso.indexOf(value) === 0,
            sorter:(a,b) => a.uso.localeCompare(b.uso),
        },
        {
            title:t('global.word.alert'),
            dataIndex:'alerta',
            key:'alerta',
            fixed:'right',
            filters: [ ...new Set(data.map((e) => `${e.alerta}`))].map((e) => ({text:`${e}`,value:`${e}`})),
            onFilter: (value, record) => record.alerta.indexOf(value) === 0,
            sorter:(a,b) => a.alerta.localeCompare(b.alerta),
            render:( obj )=> {
                return (
                    <div style={{
                        color:`${alerta_definiciones[`${obj}`][1]}`
                    }}>
                        <FontAwesomeIcon icon={faWifi} style={{ fontSize: 20, marginRight: 10 }} />
                        {`${alerta_definiciones[`${obj}`][0]}`}
                    </div>
                );
            }
        },
    ]
    // const user = useSelector((state) => state.user);
    // const months = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];

    const onChangeDateRange = (start,end) => {
        let dateStart = new Date(start);
        let dateEnd = new Date(end);

        setRangoDate([dateStart,dateEnd]);
    };

    // useEffect(() => {
    //     //console.log(data);
    // },[data]);

    const rangoHoy = () => {
        let hoyTemp1 = new Date();
        let hoyTemp2 = new Date();

        hoyTemp1.setHours(0);
        hoyTemp1.setMinutes(0);
        hoyTemp1.setMilliseconds(0);

        hoyTemp2.setHours(23);
        hoyTemp2.setMinutes(59);
        hoyTemp2.setMilliseconds(59);

        return [hoyTemp1,hoyTemp2]
    }

    const [rangoDate , setRangoDate] = useState(rangoHoy());

    const onChange = (page, pageSize) => {
        setPageSize(pageSize);
      };

    const handleChargerChange = (e,v) => {
        setTipoCargador(e !== 0 ? v.children : '');
    }

    const handleDownload = () => {
        //console.log("solicitando download");
        message.loading(textLanguage.REQUEST_DOWNLOAD_CSV);
        export_to_csv(null,data,'Alertas');
    }

    return(
        <Card>
            <Row>
                <Col className={'date-selector'}>
                    <DateSelectorGroup style={{ 
                            marginLeft:0,
                            marginRight:10,
                            marginBottom:0,
                            marginTop:0
                    }}
                    onChangeDateRange={onChangeDateRange}/>
                </Col>
                <Col  style={{
                    display:'flex',
                    justifyContent:'center'
                }}>
                    <Paragraph style={{ 
                            marginLeft:0,
                            marginRight:10,
                            marginBottom:0,
                            marginTop:0,
                            display:'flex',
                            alignItems:'center'
                    }}>{t('global.word.charger')}:</Paragraph>
                    <Select
                        className="select-filtro"
                        style={{ 
                            width:'auto',
                        }}
                        defaultValue={0}
                        onChange={handleChargerChange}
                        >
                            <Option key={0} value={0}>
                                        {t('global.selector.chargers.default.all')}
                            </Option>
                            {
                                cargadores.map((value,index)=>{
                                    return (
                                    <Option key={value.id} value={value.id}>
                                        {value.name}
                                    </Option>
                                    );
                                })
                            }
                    </Select>
                </Col>
            </Row>
            <div className="filtros-tabla"
            style={{
                display:"flex",
                alignItems:"center",
            }}>
                
            </div>
            
            <Tabs
            style={{
                marginTop:20
            }} 
            defaultActiveKey='alerta-conexion'
            tabBarExtraContent={
                <Button
                type={'primary'}
                onClick={handleDownload}>
                    {t('global.word.download')}
                </Button>
            }
            >
                
                <TabPane tab={t('alerts.tabs.common')} key="alerta-general" disabled>
                    <Table
                        columns={columns}
                        dataSource={data}
                        loading={loading}
                        tableLayout="auto"
                        pagination={{
                        pageSize,
                        pageSizeOptions: [10, 20, 50],
                        showSizeChanger: true,
                        onChange:onChange,
                        }} />
                </TabPane>
                <TabPane tab={t('alerts.tabs.connection')} key="alerta-conexion">
                    <Table
                        columns={columns}
                        dataSource={
                            data.filter( (obj) => {
                                let dateObj = new Date(obj.fechaMillis);
                                return (rangoDate[0] < dateObj && dateObj < rangoDate[1]) && obj ; 
                            })
                        }
                        loading={loading}
                        tableLayout="auto"
                        pagination={{
                        pageSize,
                        pageSizeOptions: [10, 20, 50],
                        showSizeChanger: true,
                        onChange:onChange,
                        }} />
                </TabPane>
                <TabPane tab={t('alerts.tabs.Infrastructure')} key="alerta-infraestructura" disabled>
                    <Table
                        columns={columns}
                        dataSource={data}
                        loading={loading}
                        tableLayout="auto"
                        pagination={{
                        pageSize,
                        pageSizeOptions: [10, 20, 50],
                        showSizeChanger: true,
                        onChange:onChange,
                        }} />
                </TabPane>
                <TabPane tab={t('alerts.tabs.warnings')} key="alerta-avisos" disabled>
                    <Table
                        columns={columns}
                        dataSource={data}
                        loading={loading}
                        tableLayout="auto"
                        pagination={{
                        pageSize,
                        pageSizeOptions: [10, 20, 50],
                        showSizeChanger: true,
                        onChange:onChange,
                        }} />
                </TabPane>
            </Tabs>
            
            
        </Card>
    );
}

export default TablaAlertas;