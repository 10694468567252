import React, { useEffect } from 'react';
import { Row,Col, Table } from 'antd';
import convertDateIn from '@utils/helpers/UtcResponseReceive';
import { useTranslation } from 'react-i18next'
const TablaComandos = ( props ) => {
    const { t, i18n } = useTranslation();
    const { data } = props;
  
    useEffect(()=>{
        console.log(data);
    },[data]);

    let columns = [
        {
            title: t('global.word.type'),
            dataIndex: 'type',
            key: 'type',
            sorter: (a, b) => `${a.type}`.localeCompare(b.type),
            filters: [{ text:t('map.charger.command.start'),value:'START_SESSION'} , { text:t('map.charger.command.end'),value:'STOP_SESSION'}],
            onFilter: (value, record) => record.type.indexOf(value) === 0,
        },
        {
            title: t('global.word.response'),
            dataIndex: 'response',
            key: 'response',
            sorter: (a, b) => `${a.response}`.localeCompare(b.response),
            filters: [ ...new Set(data ? data.map((e) => `${e.response}`) : [])].map((e) => ({text:`${e}`,value:`${e}`})),
            onFilter: (value, record) => record.response.indexOf(value) === 0,
        },
        {
            title: t('global.word.justification'),
            dataIndex: 'justification',
            key: 'justification',
            sorter: (a, b) => `${a.justification}`.localeCompare(b.justification),
            filters: [ ...new Set(data ? data.map((e) => `${e.justification}`) : [])].map((e) => ({text:`${e}`,value:`${e}`})),
            onFilter: (value, record) => record.justification.indexOf(value) === 0,
            render:(e)=>(e ? e : 'NA' )
        },
        {
            title:  t('global.user'),
            dataIndex: 'user',
            key: 'user',
            sorter: (a, b) => `${a.user}`.localeCompare(b.user),
            filters: [ ...new Set(data ? data.map((e) => `${e.user}`) : [])].map((e) => ({text:`${e}`,value:`${e}`})),
            onFilter: (value, record) => record.user.indexOf(value) === 0,
            render:(e)=>(e ? e : 'NA' )
        },
        
        {
            title: t('global.date'),
            dataIndex: 'date',
            key: 'date',
            sorter: (a, b) => `${a.date}`.localeCompare(b.date),
            render:(e)=>(convertDateIn(e))
        },
    ]


    return(
        <Row>
            <Col span={24}>
                <Table
                columns={columns}
                dataSource={data}
                />
            </Col>
        </Row>
    );
}

export default TablaComandos;