import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './style.less';
import { useRouteMatch } from 'react-router-dom';
import { Card, Space, message, Row, Col,Tabs } from 'antd';

import Breadcrumb from '@containers/Breadcrumb';

import { ChargerInfo, CardSendCommand } from './containers';
import { apiCommands, apiLocations } from '@utils/services/cpo';
import { ConnectorInfoItem,TablaComandos,TablaConectores,TablaRecargas } from './components';
import { Spanish } from '@utils/helpers/dictIdiomas'
import { useTranslation } from 'react-i18next'
import TablaActivos from './components/TablaActivos';
const { TabPane } = Tabs;

const CargadorView = (props) => {
  const { t, i18n } = useTranslation();
  const textLanguage = Spanish({screen:'evse-view'})
  const {
    match: {
      params: { cargadorId },
    },
  } = props;
  const [cambioComand,setCambioComand] = useState(0)

  const { path } = useRouteMatch();
  const months = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
  const splitPath = path.split("/");
  const breadcrumbData = [
    [null, t('evse.header')],
    [null, `${cargadorId}`],
  ];

  const [evseData, setevseData] = useState({
    id: null,
    data: [],
    commands: []
  });
  const [connectorsData, setconnectorsData] = useState({
    data:[],
    loading:false,
  });

  const date_formate = ( date ) => {
      let dateTemp = new Date(date);
      let dateUTC = new Date(Date.UTC(dateTemp.getFullYear(),dateTemp.getMonth(),dateTemp.getDate(),dateTemp.getHours(),dateTemp.getMinutes()));
      return `${dateUTC.getDate()} ${months[dateUTC.getMonth()].substring(0,3).toLowerCase()} ${dateUTC.getFullYear()} ${dateUTC.getHours()}:${dateUTC.getMinutes()}`;
  }

  useEffect(() => {
    
    cargarEvseConectorData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // if(evseData.id) cargarComandosHistorial();
  },[evseData.id])

  const cargarComandosHistorial = () => {
		// console.log('evseData: ', evseData)
    
		apiCommands.getCommands(evseData.id).then((rslt) => {
      // console.log('.....................rslt: ', rslt);
      setevseData({...evseData, commands:rslt.map((e,i)=>({
        key:i,
        connector_id:e.evse_id,
        type:e.command,
        justification:e.justification,
        response:e.command_return,
        user:e.user_id,
        date:e.exec_timestamp,
      }))});
    }).catch((err) => {
      console.log('err: ', err);
    });
  }

  const cargarEvseConectorData = () => {
    setconnectorsData({...connectorsData,loading:true});
    apiLocations
      .getEvse(cargadorId)
      .then(data => {
				// console.log('cargarEvseConectorData - getEvse - data: ', data)
        let evseData = [
					{
						name: 'id',
						value: data.uid,
					},
					{
						name: 'Estación',
						value: data.connectors[0].evse.evse_location_name,
					},
					{
						name: 'Uso',
						value: data.type_use,
					},
					{
						name: 'Operador',
						value: data.operator,
					},
					{
						name: 'Marca',
						value: `${data.evse_brand}`,
					},
					
					{
						name: 'Modelo',
						value: `${data.evse_model} `,
					},
					{
						name: 'Ubicación',
						value: data.address,
					},
					{
						name: 'Última petición recibida',
						value: date_formate(data.last_updated),
					},
					/* {
						name: 'Cliente',
						value: data.client_id,
					},
					{
						name: 'Locación',
						value: data.location,
					}, */
        ];
        let connectorsData = data.connectors;
        setevseData({...evseData, id: data.id , data: evseData});
        setconnectorsData({loading:false,data:connectorsData});
      })
      .catch((error) => {
        message.error(textLanguage.EVSE_EVSE_R_FAIL({name:cargadorId}));
        setconnectorsData({...connectorsData,loading:true});
    });
  }

  const changeCommands = (comando) => {

    setTimeout(() => {
      console.log('changeCommands ',comando);
      let contador = cambioComand + 1;
      cargarEvseConectorData();
      setCambioComand(contador);
    }, 3000); 
    
  }

  return (
    <>
      <Breadcrumb breadcrumbData={breadcrumbData} />

      <Row style={{display:'flex',justifyContent:'center'}}>
        <Col sm={24} lg={24}>
          <Row className={'cargador-row-container'} gutter={[24, 16]} style={{margin:0}} >
            <Col span={16}>
              <ChargerInfo className="charger-info" evseData={evseData.data} />
            </Col>
            <Col span={8}>
              <CardSendCommand 
              className="card-info" 
              evseId={cargadorId} 
              evseConnectors={connectorsData.data} 
              changeCommands={changeCommands}
              />
            </Col>

            <Col span={24}>
              <Tabs key={'tabs-vista'} defaultActiveKey={"conectores"}>
                <TabPane tab={t('evse.tabs.connectors')} key="conectores">
                  <TablaConectores loading={connectorsData.loading} conectores={connectorsData.data} cambioComand={cambioComand}/>
                </TabPane>
                <TabPane tab={t('evse.tabs.recharges')} key="recargas">
                  <TablaRecargas cargadorId={cargadorId} cambioComand={cambioComand} />
                </TabPane>
                <TabPane tab={t('evse.tabs.logs')} key="logs">
                  <TablaComandos cargadorId={cargadorId} data={evseData.commands}/>
                </TabPane>
                <TabPane tab={t('evse.tabs.progress')} key="recargacurso"  >
                  <TablaActivos cargadorId={cargadorId} cambioComand={cambioComand}/>
                </TabPane>
              </Tabs>
            </Col>
            
          </Row>
        </Col>
      </Row>
      
    </>
  );
};

CargadorView.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      cargadorId: PropTypes.string,
    }),
    url: PropTypes.string,
  }).isRequired,
};

export default CargadorView;
