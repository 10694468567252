import React from 'react';
import { Route, Switch, useRouteMatch, Redirect } from 'react-router-dom';

import { TableOrganizaciones } from './views';
const ConfigUsuarios = () => {
  const match = useRouteMatch();

  const usuariosRoute = `${match.path}`;

  return (
    <Switch>
      <Route path={usuariosRoute} component={TableOrganizaciones} />
      <Redirect to={usuariosRoute} />
    </Switch>
  );
};

export default ConfigUsuarios;