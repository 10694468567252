/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartBar, faCar, faChartLine } from '@fortawesome/free-solid-svg-icons';
import { mainMenuIconBaseProps as iconProps } from '@utils/params';
import { SpecialIcons } from '@components/ui';
import InProgressView from '@views/InProgressView';
import Metricas from '@views/Metricas';

const { FleetIcon } = SpecialIcons;

const path = '/metricas';

const reportes = ( t ) => {
  return {
    path,
    name: t('menus.metrics'),
    icon: <FontAwesomeIcon {...iconProps} icon={faChartLine} />,
    key: 'metricas',
    component:Metricas,
    children:[]
    
  };
} 

export default reportes;
