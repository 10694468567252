import { useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { alertasActions, locationActions } from '@store/actions';
import io from 'socket.io-client';
import { getToken } from '@utils/auth/createAuthProvider';
import defaultSettings from '@utils/services/default';
import { message } from 'antd';

const useSocket = (type, user) => {
  const dispatch = useDispatch();

	const [store, setStore] = useState({});
	// setStore({...store, '0': 0})

  const { user_clients: clients } = useSelector((state) => state.user);
  let stateSocket = useSelector((state) => state.socket);
  // const { connector } = useSelector((state) => state.user);
  // const { client_id: idClient = '' } = clients[0] || [];

  const socketConn = useCallback(() => {
    if (!stateSocket && clients) {
      clients.forEach(element => {
				setStore({...store, [element.client_id]: null});
        stateSocket = io(defaultSettings.socket_default_endpoint);
        const room = `cid_${element.client_id}`;
				stateSocket.on('connect', async () => {
					const jwt = await getToken();
          const auth = {
            room,
            jwt,
          };
          stateSocket.emit('join', auth);
        });
      });
    }

    stateSocket.on('message', (data) => {
      // console.log('stateSocket.on - message: ', data);
    });

    stateSocket.on('message-all', (data) => {
      // console.log('stateSocket.on - message-all: ', data);
    });

    stateSocket.on('message-room', (data) => {
			let json = null;

      try {
        json = JSON.parse(data);
      } catch (err) {
        if (`${data}`.startsWith('Socket')) return;
        json = data;
      }

      /**
       * 
       * 
       * client: 1
        connector_id: 68
        event: "METER_VALUES"
        evse_id: 55
        power_active: 4325
        power_active_unit: "w"
        session_id: 6082
        soc: 34
        value: 400
       */

      switch (json.event) {
        case "OFFLINE":
          // console.log("event OFFLINE: ", json);
          // message.warning(`${json.id} Requiere tu atención\nEvento: Desconexión`);
          /**
           * alerta para popup
           */
				break;
        case "CHANGE_STATUS":
          dispatch(locationActions.updateLocation(json));
				break;
        case "METER_VALUES":
					json = {
						...data,
						value: json.value ? parseFloat(json.value.toFixed(2)) : 0,
						soc: json.soc ? parseFloat(json.soc.toFixed(2)) : 0,
						power_active: json.power_active ? parseFloat(json.power_active.toFixed(2)) : 0
					};
					// console.log('METER_VALUES: ', json)
					dispatch(locationActions.updateLocation(json));
				break;
        default:
          // console.log("event default: ", json);
				break;
      }
    });

    stateSocket.on('error', (data) => {
      console.log("stateSocket.on - error: ", data);
    });

    // eslint-disable-next-line
  }, []);

  const fetchAlertas = useCallback(async () => {
    const getAlertas = () => dispatch(alertasActions.getAlertas(user));

    await Promise.all([getAlertas()]);
    socketConn();

    // eslint-disable-next-line
  }, []);

  const fetchEvses = useCallback(async () => {
    const getEvses = () => dispatch(locationActions.getLocations(user));

    await Promise.all([getEvses()]);
    socketConn();
  }, []);

  useEffect(() => {
    fetchAlertas();
    fetchEvses();
    // eslint-disable-next-line
  }, []);
};

export default useSocket;
