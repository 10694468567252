import React, { useState, useEffect } from 'react';
import { Card } from 'antd';
import { Form, Select, Button, Typography, Alert, Input } from 'antd';
import { apiCommands, apiSessions } from '@utils/services/cpo';
import { apiTarjetas } from '@utils/services'
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next'
import { faPlug, faBolt, faEdit, faCreditCard } from '@fortawesome/free-solid-svg-icons';
const { Option } = Select;
const { Paragraph } = Typography;

const CardSendCommand = (props) => {
	const { t/* , i18n */ } = useTranslation();
	const [form] = Form.useForm();
	const [, forceUpdate] = useState();
	const [loading, setLoading] = useState(false);
	const user = useSelector((state) => state.user);
	const [error, setError] = useState(null);
	const [commandDescription, setCommandDescription] = useState({
		selectDescription: null,
		inputOther: null,
	});
	const [command, setCommand] = useState('');
	const [success, setSuccess] = useState(false);
	const [cardsRFID, setCardsRFID] = useState({
		data: [],
		loading: false,
	});
	const connectorsData = props.evseConnectors;
	const evseId = props.evseId;
	
	const {changeCommands} = props;
	
	const getCards = () => {
		setCardsRFID({ ...cardsRFID, loading: true })
		apiTarjetas.obtener_tarjetas(false).then((rslt) => {
			setCardsRFID({ ...cardsRFID, data: rslt, loading: false })
		}).catch((err) => {
			console.log(err);
			setCardsRFID({ ...cardsRFID, loading: false })
		});
		return
	}

	useEffect(() => {
		forceUpdate({});
		getCards();
	}, []);

	useEffect(() => {
		console.log('evseId ',evseId);
	}, [evseId]);

	const onFinish = async (values) => {
		setLoading(true);
		try {
			let postData = null;
			switch (values.select_command) {
				case "start":
					
					postData = {
						"evse_uid": evseId,
						"connector_id": values.select_connector,
						"justification": values.description_command !== t('evse.commands.description5') ? values.description_command : values.description
					}

					if (values.tag_id) postData.tag_id = values.tag_id;

					console.log("START", postData);

					await apiCommands.sendStart(postData);
					changeCommands("start");
					setSuccess(true);
					setLoading(false);
					break;
				case "stop":
					
					let sessionId;
					await apiSessions.lastSession(evseId, values.select_connector).then(data => {
						console.log('data: ', data)
						sessionId = parseInt(data);
					}).catch((error) => {
						setLoading(false);
						error.message = "No hay cargas activas para el conector seleccionado";
						setError(error);
					}); 

					if (sessionId) {

						let arrConnectorId = connectorsData?.filter((item)=> values.select_connector == item.conn_id) || [];
						let connector_id = arrConnectorId[0]?.id || '';

						postData = {
							"session_id": sessionId,
							"justification": (values.description_command !== t('evse.commands.description5') ? values.description_command : values.description),
							"evse_id": ((connectorsData && connectorsData.length && connectorsData[0].evse && connectorsData[0].evse.id) ? connectorsData[0].evse.id : evseId),
							"connector_id": connector_id
						}

						await apiCommands.sendStop(postData, user.user_id);
						changeCommands("stop");
						setSuccess(true);
						setLoading(false);
						
					}else{
						setError({message: t('evse.commands.sesion.notfound')})
					}
					break;
				case "restart":
					console.log("RESTART")
					postData = {
						"evse_uid": evseId,
						"response_url": "http://localhost:8080",
						"type": "Hard",
						"justification": values.description_command !== t('evse.commands.description5') ? values.description_command : values.description
					}
					await apiCommands.sendRestart(postData);
					changeCommands("RESTART");
					setSuccess(true);
					setLoading(false);
					break;
				default:
					break;
			}
		} catch (err) {
			setLoading(false);
			setError(err);
			return;
		}
	};

	const handleClose = () => {
		setSuccess(false);
	};

	return (
		<Card
			bodyStyle={{ overflow: 'hidden', height: '100%', padding: '30px' }}
			className={'card_comandos'}
		// title="Enviar comandos"
		>
			<h3 style={{ marginBottom: '40px', fontWeight: 'bold', fontSize: '16px' }}>{t('evse.commands.header')}</h3>
			<Form form={form} name="horizontal_login" wrapperCol={{ span: 20 }} labelCol={{ span: 4 }} labelAlign={'left'} style={{ textAlign: 'center' }} onFinish={onFinish}>
				<Form.Item
					name="select_connector"
					label={<FontAwesomeIcon color={'gray'} icon={faPlug} />}
					rules={[
						{
							required: true,
							message: 'Por favor seleccione un toma!',
						},
					]}
				>
					<Select defaultValue="0" >
						<Option value="0">{t('map.charger.command.socket')}</Option>
						{
							connectorsData.map(result => {
								return (
									<Select.Option key={result.conn_id} value={result.conn_id}>
										{result.conn_id}
									</Select.Option>
								);
							})
						}
					</Select>
				</Form.Item>
				<Form.Item
					name="select_command"
					label={<FontAwesomeIcon color={'gray'} icon={faBolt} />}
					rules={[
						{
							required: true,
							message: 'Por favor seleccione un comando!',
						},
					]}
				>
					<Select defaultValue="0" onChange={(e) => setCommand(e)} >
						<Option value="0">{t('map.charger.command.action')}</Option>
						<Option value="start">{t('map.charger.command.start')}</Option>
						<Option value="stop">{t('map.charger.command.end')}</Option>
						<Option value="restart">{t('map.charger.command.restart')}</Option>
					</Select>
				</Form.Item>
				{
					command === 'start' && <Form.Item
						name={'tag_id'}
						rules={[
							{
								required: false,
								message: 'Por favor selecciona un RFID!',
							},
						]}
						label={<FontAwesomeIcon color={'gray'} style={{ marginLeft: 10 }} icon={faCreditCard} />}
					>
						<Select
							showSearch={true}
							optionFilterProp={'children'}
							style={{ textAlign: 'left' }}
							onChange={(e) => setCommandDescription({ selectDescription: e })}
							placeholder={t('map.charger.command.description')} >
							<Option value={null}>Sin RFID</Option>
							{
								cardsRFID.data.map((e, i) => (
									<Option key={i} value={e.code}>{e.code}</Option>
								))
							}
						</Select>
					</Form.Item>
				}
				<Form.Item
					name="description_command"
					label={<FontAwesomeIcon color={'gray'} icon={faEdit} />}
					rules={[
						{
							required: true,
							message: 'Por favor seleccione una justificación!',
						},
					]}
				>
					<Select style={{ textAlign: 'left' }} onChange={(e) => setCommandDescription({ selectDescription: e })} placeholder={t('map.charger.command.description')} >
						<Option value={t('evse.commands.description1')}>{t('evse.commands.description1')}</Option>
						<Option value={t('evse.commands.description2')}>{t('evse.commands.description2')}</Option>
						<Option value={t('evse.commands.description3')}>{t('evse.commands.description3')}</Option>
						<Option value={t('evse.commands.description4')}>{t('evse.commands.description4')}</Option>
						<Option value={t('evse.commands.description5')}>{t('evse.commands.description5')}</Option>
					</Select>
				</Form.Item>
				{
					commandDescription.selectDescription === t('evse.commands.description5') && (
						<Form.Item
							name="description"
							label={' '}
						>
							<Input value={commandDescription.inputOther} onChange={(e) => setCommandDescription({ ...commandDescription, inputOther: e.target.value })} placeholder={t('evse.commands.description.other')} />
						</Form.Item>
					)
				}
				<Form.Item shouldUpdate className={'container-btn-sendcommand'}>
					{() => (
						<Button
							type="primary"
							htmlType="submit"
							style={{ width: '100%' }}
							loading={loading}
							disabled={
								!form.isFieldsTouched(true) ||
								form.getFieldsError().filter(({ errors }) => errors.length).length
							}
						>
							{t('evse.commands.btn.send')}
						</Button>
					)}
				</Form.Item>
			</Form>
			{success && (
				<Alert
					style={{ marginTop: 20, marginBottom: 20 }}
					description="Comando enviado correctamente."
					type="success"
					showIcon
					closable
					afterClose={handleClose}
				/>
			)}
			{error && (
				<Alert
					style={{ marginTop: 20, marginBottom: 20 }}
					message="Error en consulta"
					description={error.message}
					type="error"
					showIcon
					closable
					afterClose={handleClose}
				/>
			)}
		</Card>
	);
};

export default CardSendCommand;
