/* eslint-disable no-undef */
import React, { useState, useEffect } from 'react';
import { alertas } from '@utils/menus/main/itemsMenu';
import Breadcrumb from '@containers/Breadcrumb';
import { TablaAlertas } from '@views/Alertas/components';
import { useSelector } from 'react-redux';
import getLocalDateString from '@utils/helpers/UtcResponseReceive'
import { useTranslation } from 'react-i18next'
import { apiLocations } from '@utils/services/cpo';
import './style.less';

const TablesView = () => {
  const { t, /* i18n */ } = useTranslation();
  // const { name } = alertas;
  const breadcrumbData = [[null, t('alerts.header')]];

  // const months = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
  const { user, alertas: alertasItems } = useSelector((state) => state);

  // const alertas = useSelector((state) => state.alertas);
  const [tableData, setTableData] = useState({
    data: [],
    dataRaw: [],
    loading: false,
  });
  const [cargadores, setCargadores] = useState([]);


  const tableItem = (data) => {
    return {
      key: data.id,
      id: data.evse,
      fecha: getLocalDateString(data.created),
      fechaMillis: data.created,
      operario: data.operator,
      estacion: data.location,
      conector_id: data.connector_id[0][0],
      conector_tipo: data.connector_id[0][1],
      uso: data.type_use,
      alerta: data.type_alert
    }
  }

  const cargarCargadores = () => {
    apiLocations.allLocations(user.user_id).then((rslt) => {
      setCargadores(rslt);
    }).catch((err) => {
      console.log(err);
    });
  }

  const cargarAlertas = () => {
    updateTableData([{ tag: 'loading', value: alertasItems.fetching }]);
    let alertasArray = alertasItems.alertas;
    let dataTemp = [];
    let dataRaw = alertasArray ? alertasArray : [];
    for (let i = 0; i < dataRaw.length; i++) {
      dataTemp.push(tableItem(dataRaw[i]));
    }
    updateTableData([
      {
        tag: `data`,
        value: dataTemp,
      },
      {
        tag: 'dataRaw',
        value: dataRaw
      },
      {
        tag: 'loading',
        value: alertasItems.fetching
      },
    ]);


  }

  const updateTableData = (changes) => {
    let tempTableData = Object.assign({}, tableData);

    for (let i = 0; i < changes.length; i++) {
      const change = changes[i];
      const { tag, value } = change;
      tempTableData[`${tag}`] = value
    }

    setTableData(tempTableData);
  }

  useEffect(() => {
    cargarCargadores();
  }, []);

  useEffect(() => {
    cargarAlertas();
  }, [alertasItems.alertas]);




  return (
    <div id="alertas_general">
      <Breadcrumb breadcrumbData={breadcrumbData} />

      <div className="alertas-table">
        <TablaAlertas cargadores={cargadores} data={tableData.data} loading={tableData.loading} cargarAlertas={cargarAlertas} />
      </div>
    </div>
  );
}


export default TablesView;