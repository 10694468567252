import profilesConst from '../constants/chargeProfilesConstants';

const initState = {
  vehicules: [[], [], []],
  generalInfo: {
    id: 0,
    name: '',
    description: '',
    modeCharge: '',
    is_active: true,
  },
  chargers: {
    selected: [],
    maximum_consumption: 0,
  },
  profileSetup: {
    mark: '',
    editProfile: false,
  },
  listChargers: [],
};

const chargeProfileReducer = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    case profilesConst.CHARGE_PROFILE_VEHICULES:
      return {
        ...state,
        vehicules: payload,
      };

    case profilesConst.CHARGE_PROFILE_GENERAL_INFO:
      return {
        ...state,
        generalInfo: payload,
      };

    case profilesConst.CHARGE_PROFILE_CHARGERS:
      return {
        ...state,
        chargers: payload,
      };

    case profilesConst.CHARGE_PROFILE_SETUP:
      return {
        ...state,
        profileSetup: payload,
      };

    case profilesConst.CHARGE_PROFILE_LIST:
      return {
        ...state,
        listChargers: payload,
      };

    default:
      return state;
  }
};

export default chargeProfileReducer;
