import React, { useEffect, useRef, useState } from 'react';
import { Button, Input, message } from 'antd';
import { SketchPicker  } from 'react-color';
import './style.less';
const ColorPicker = ( props ) => {

    const inputRef = useRef(null);
    const { className, setColorHandler, colorDefault } = props;
    const [ color , setColor ] = useState('#fff');
    const [ colorInput , setColorInput ] = useState('');
    const [ pickerVisible, setPickerVisible ] = useState(false);

    useEffect( () => {
        if(colorDefault) setColorAll(colorDefault)
    },[]);

    useEffect( () => {
        if(colorDefault || colorDefault === '') setColorAll(colorDefault)
    },[colorDefault]);

    const setColorAll = ( value ) => {
        try{
            setColorInput( value );
            setColor( value );
        }catch(e){
            // console.log(e);
        }
    }

    const saveColor = () => {
        setColorInput(color);
        setPickerVisible(false);
        if(setColorHandler) setColorHandler(color);
    }

    return(
        <div className={className ? ['picker-container',className].join(' ') : 'picker-container'}>
            <Input placeholder={'#fff'} onFocus={() => setPickerVisible(true)} ref={inputRef} value={colorInput} onChange={( value ) => {
                const valueTemp = value.currentTarget.value;
                setColorAll(valueTemp);
            }} /> 
            <div style={{display:pickerVisible ? 'flex' : 'none'}} className={'picker-react'}>
                <SketchPicker color={color} onChangeComplete={(color)=>setColor(color.hex)} onChange={(color) => setColor(color.hex)} />
                <Button type={'primary'} onClick={saveColor} >Guardar</Button>
            </div>
        </div>
    );
}

export default ColorPicker;