import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import Cache from 'i18next-localstorage-cache'
import { initReactI18next } from 'react-i18next';

const resources = {
    en:{
        translation:require('./assets/i18n/translations/en')
    },
    es:{
        translation:require('./assets/i18n/translations/es')
    },
}

// console.log(resources);

i18n
// .use(Backend)
// .use(Cache)
.use(initReactI18next)
.init({
    resources,
    lng:'es',
    keySeparator:false,
    debug:true,
    interpolation:{
        escapeValue:false,
    },
});

export default i18n;