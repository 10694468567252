import React from 'react';
import { PageHeader, Button, Dropdown, Menu, Space } from 'antd';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle, faUserCircle } from '@fortawesome/free-solid-svg-icons';

import { logout } from '@utils/auth/createAuthProvider';
// import NotificationCenter from '../NotificationCenter.js';
import { Link, useHistory } from 'react-router-dom';
import { LanguageSelector } from '../../components'
import { useTranslation } from 'react-i18next';
import { comprobar_permiso  } from '@utils/auth/permissions';

const menu = ( action, t, user ) => {
  return (
    <Menu style={{
      display:"flex",
      flexDirection:'column',
      alignItems:"center"
    }}>
      <Menu.Item>
        <Link 
        to={!comprobar_permiso(user,5) ? "/configuracion-cuenta/usuarios" : "/configuracion-sitio/perfil" }
        style={{
          border:'none'
        }}>{t('menus.options.settings')}</Link>
      </Menu.Item>
      <Menu.Item
      style={{
        width:'100%',
        display:'flex',
        justifyContent:'center'
      }}>
        <Button type={'default'}  onClick={() => action()}>{t('menus.options.closesession')}</Button>
      </Menu.Item>
    </Menu>
  );
}

const Header = ({ className, toggleMenuCollapsed, isMenuCollapsed }) => {
  const { user } = useSelector((state) => state);
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const iconStyle = { fontSize: '21px',color:'white' };
  const IconTarget = () =>
    isMenuCollapsed ? (
      <MenuUnfoldOutlined style={iconStyle} />
  ) : (
      <MenuFoldOutlined style={iconStyle} />
  );

  const toLogin = () => {
    logout();
    history.replace('/ingreso-cpo');
    window.location.reload(true);
  }

  const toFaq = () => {
    window.open('/faq','_blank');
  }

  return (
    <PageHeader
      className={className}
      title=""
      onBack={toggleMenuCollapsed}
      backIcon={<IconTarget />}
      extra={[
        // <NotificationCenter key={2} />,
        <LanguageSelector key={3}/>,
        <FontAwesomeIcon key={2} onClick={toFaq} icon={faQuestionCircle} style={{ cursor:'pointer', fontSize: 16,color:"white" }} />,
        <Dropdown key={1} placement="bottomRight" overlay={menu(toLogin,t,user)} arrow>
          <Space size="small">
            {/* <Avatar size="small" style={{ marginRight: 0 }} /> */}
            <FontAwesomeIcon icon={faUserCircle} style={{ fontSize: 24,color:"white" }} />
            <Button type="text" style={{ padding: 0,color:"white" }}>
              {user.username}
            </Button>
          </Space>
        </Dropdown>,
      ]}
    />
  );
};

Header.propTypes = {
  className: PropTypes.string,
  isMenuCollapsed: PropTypes.bool.isRequired,
  toggleMenuCollapsed: PropTypes.func.isRequired,
};

Header.defaultProps = {
  className: null,
};

export default Header;
