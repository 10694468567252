import React from 'react';
import { useSelector } from 'react-redux';
import { Row, Col, Tabs } from 'antd';
import Breadcrumb from '@containers/Breadcrumb';
import { CargadoresProfiles } from './views';
import './style.less';

const PerfilesCargaCrear = () => {
  const { TabPane } = Tabs;
  const setup = useSelector(({ profiles }) => profiles.profileSetup);
  const breadcrumbData = [[null, `Garage/${setup.mark}`]];
  // console.log('Editar Perfil: ', setup.editProfile);
  return (
    <Row className="container_perfiles">
      <Breadcrumb breadcrumbData={breadcrumbData} />

      <Col span={24} className="perfiles_tabs_container">
        <Tabs defaultActiveKey="1">
          <TabPane tab="Perfiles de Parqueadero" key="stepprofile">
            <CargadoresProfiles />
          </TabPane>
        </Tabs>
      </Col>

    </Row>
  );
};

export default PerfilesCargaCrear;
