import React, { useState } from 'react';
import { Table, Tag } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDeletePopup } from '@components/ui';
import { faPen, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { comprobar_permiso } from '@utils/auth/permissions';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next'
const TableTarjetas = (props) => {
  const { platform } = useSelector((state) => state);
  const { data, loading, /* dataRaw, */ handleEditCard, handleSwitchCard, user, clients } = props;
  const [selectedData, setSelected] = useState([]);
  const { t, /* i18n */ } = useTranslation();
  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      defaultSortOrder: 'descend',
      fixed: 'left',
      responsive: ['lg'],
      sorter: (a, b) => `${a.id}`.localeCompare(b.id),

    },
    {
      title: t('global.word.code'),
      dataIndex: 'codigo',
      fixed: 'left',
      filters: [...new Set(data.map((e) => `${e.codigo}`))].map((e) => ({ text: `${e}`, value: `${e}` })),
      onFilter: (value, record) => record.codigo.indexOf(value) === 0,
      sorter: (a, b) => `${a.codigo}`.localeCompare(b.codigo),
    },
    {
      title: t('global.word.client'),
      dataIndex: 'cliente',
      filters: [
        ...Object.keys(clients).map((e) => {
          return { text: `${clients[e].name}`, value: `${clients[e].name}` }
        })
      ],
      onFilter: (value, record) => record.cliente.filter(item => item.name === value).length > 0,
      sorter: (a, b) => `${a.cliente}`.localeCompare(b.cliente),
      render: (e) => {
        return <>
          {
            e.map((e) => (
              <Tag
                key={e.id}
              >
                {e.name}
              </Tag>
            ))
          }
        </>;
      }
    },
    {
      title: t('global.word.description'),
      dataIndex: 'descripcion',
      sorter: (a, b) => `${a.descripcion}`.localeCompare(b.descripcion),
    },
    {
      title: t('global.word.datecreated'),
      dataIndex: 'fecha',
      sorter: (a, b) => `${a.fecha}`.localeCompare(b.fecha),
      defaultSortOrder: 'descend'
    },
    {
      title: t('global.word.state'),
      dataIndex: 'estado',
      sorter: (a, b) => `${a.estado}`.localeCompare(b.estado),
      render: (text, record, index) => {
        return (
          <IconDeletePopup icon={text ? faCheck : faTimes} style={{
            fontSize: 20,
            color: text ? `${platform.main_color}` : "gray",
            cursor: `${comprobar_permiso(user, 18) ? 'pointer' : 'arrow'}`,
            pointerEvents: `${comprobar_permiso(user, 18) ? 'all' : 'none'}`,
          }} title={text ? `¿Estas seguro que deseas desactivarlo?` : `¿Estas seguro que deseas activarlo?`} record={record} eliminarCliente={() => handleSwitchCard(record)} />
        );
      }
    },
  ];

  if (comprobar_permiso(user, 18)) columns.push(
    {
      title: t('global.word.actions'),
      dataIndex: 'acciones',
      render: (text, record, index) => {
        return (
          <div style={{
            display: "flex",
            justifyContent: "space-around",
          }}>
            <FontAwesomeIcon icon={faPen} onClick={() => handleEditCard(record)} style={{ fontSize: 20, color: `${platform.main_color}`, cursor: "pointer" }} />

          </div>
        );
      }
    },
  );


  const onSelectChange = selectedRowKeys => {
    // console.log(dataRaw);
    setSelected({ selectedRowKeys });
  };

  // const obtener_tarjeta_raw = (key) => {
  //   // console.log(dataRaw);
  //   for (let i = 0; i < dataRaw.length; i++) {
  //     if (dataRaw[i].id === key) return dataRaw[i];
  //   }
  // }

  const rowSelection = {
    selectedData,
    onChange: onSelectChange,
    selections: [
      Table.SELECTION_ALL,
      Table.SELECTION_INVERT,
      {
        key: 'odd',
        text: 'Select Odd Row',
        onSelect: changableRowKeys => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changableRowKeys.filter((key, index) => {
            if (index % 2 !== 0) {
              return false;
            }
            return true;
          });
          setSelected(newSelectedRowKeys);
        },
      },
      {
        key: 'even',
        text: 'Select Even Row',
        onSelect: changableRowKeys => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changableRowKeys.filter((key, index) => {
            if (index % 2 !== 0) {
              return true;
            }
            return false;
          });
          setSelected(newSelectedRowKeys);
        },
      },
    ],
  };



  return (
    <Table rowSelection={rowSelection} columns={columns} dataSource={data} loading={loading} />
  );
}

export default TableTarjetas;