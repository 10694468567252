/* eslint-disable no-undef */
import React, { memo, useCallback } from 'react';
import { GoogleMap, LoadScript } from '@react-google-maps/api';
import PropTypes, { shape } from 'prop-types';

const Map = (props) => {
  const { containerStyle, center, children, className, onClick, getMap, onDblClick,onCenterChanged,zoom,onZoomChanged } = props;
  const options = {
    disableDefaultUI: true,
  };

  const onLoad = useCallback((map) => {
    // dispatch(vehicleActions.updateMap(map));
    if (getMap) getMap(map);

    // eslint-disable-next-line
  }, []);

  const onUnmount = React.useCallback(() => {
    // dispatch(vehicleActions.updateMap(null));
    // eslint-disable-next-line
  }, []);

  return (
    <LoadScript googleMapsApiKey="AIzaSyC6utTCtC_j5WS93fz8xAxSweRc0J8eYT8">
      <GoogleMap
        className={className}
        mapContainerStyle={containerStyle}
        center={center}
        zoom={zoom}
        onLoad={onLoad}
        onUnmount={onUnmount}
        options={options}
        onClick={onClick}
        onDblClick={onDblClick}
        onCenterChanged={onCenterChanged}
        onZoomChanged={onZoomChanged}
      >
        {children}
      </GoogleMap>
    </LoadScript>
  );
};

Map.propTypes = {
  containerStyle: PropTypes.shape({
    width: PropTypes.string,
    height: PropTypes.string,
  }),

  center: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
  }),

  className: PropTypes.string,
  children: PropTypes.node,

  getMap: PropTypes.func,
  onClick: PropTypes.func,
  onDblClick: PropTypes.func,
  onCenterChanged: PropTypes.func,
  zoom:PropTypes.number,
  onZoomChanged:PropTypes.func
};

Map.defaultProps = {
  containerStyle: {
    width: '100%',
    height: '100%',
  },

  center: {
    lat: 4.570868,
    lng: -74.297333,
  },
  zoom:5,

  className: '',

  children: null,

  getMap: null,
  onClick: null,
  onDblClick: null,
  onZoomChanged:null,
};

export default memo(Map);
