import InProgressView from '../../../../views/InProgressView';

const path = '/configuracion-sitio/alertas';

const alertasMenu = ( t ) => {
  return {
    path,
    name: t("menus.alerts"),
    icon: null,
    component:InProgressView,
    key: 'config-sitio-alertas',
    children: [],
  }; 
}

export default alertasMenu;