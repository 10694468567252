const permisos_diccionario = {    
    0: "locations.view_location",
    1: "users.add_user",
    2: "client.change_client",
    3: "auth.view_group",
    4: "cards.add_cards",
    5: "client.view_client",
    6: "locations.change_location",
    7: "locations.view_evse",
    8: "client.delete_client",
    9: "users.view_user",
    10: "alerts.view_alerts",
    11: "locations.change_evse",
    12: "users.can_enable_disable_user",
    13: "cards.view_cards",
    14: "locations.add_evse",
    15: "client.add_client",
    16: "locations.add_location",
    17: "client.can_enable_disable_client",
    18: "cards.change_cards",
    19: "sessions.view_session",
    20: "users.change_user",


    77: "test_no_permiso"
}

const comprobar_permiso = ( user , permiso ) => {
    let permisoDic = permisos_diccionario[`${permiso}`];
    let permisosActuales = user.permissions;
    for(let i = 0 ; i < permisosActuales.length ; i++){
        // console.log(`${permisosActuales[i]} | ${permisoDic}`);
        if(permisosActuales[i] === permisoDic) return true;
    }

    return false;
}

export { permisos_diccionario, comprobar_permiso };