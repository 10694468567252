import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom';
import React from 'react';
import { AdministrarTarjetasClientes } from './views';
const TarjetasGeneral = () => {
    const match = useRouteMatch();
    return (
        <Switch>
            <Route path={`/tarjetas-para-recarga/tarjetas-clientes`} component={AdministrarTarjetasClientes} />
            <Redirect to={match.path} />
        </Switch>
    );

}

export default TarjetasGeneral;