import React, { useState, useCallback } from 'react';
import { Row, Form, Steps, message } from 'antd';
import './style.less';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { chargeProfileActions } from '@store/actions';
import { apiProfiles } from '@utils/services/cpo';
import GeneralInformation from './GeneralInformation/GeneralAlarma';
import GridVrhicules from './GridVehicules';
import ChargersSelector from './ChargersSelector';

const { Step } = Steps;

const CargadoresProfiles = () => {
  const history = useHistory();
  const profilesData = useSelector(({ profiles }) => profiles);
  const listChargers = useSelector(({ profiles }) => profiles.listChargers);
  const profileSetup = useSelector(({ profiles }) => profiles.profileSetup);
  const generalInfo = useSelector(({ profiles }) => profiles.generalInfo);
  const [form] = Form.useForm();
  const [currentStep, setCurrentStep] = useState(0);
  const [idChargers, setIdChargers] = useState([]);
  // const [initChargers, setInitChargers] = useState(listChargers);
  const dispatch = useDispatch();

  const onNext = useCallback(async () => {
    if (currentStep === 0) {
      form
        .validateFields()
        .then((rslt) => {
          setCurrentStep(currentStep + 1);
          if (profileSetup.editProfile) {
            dispatch(
              chargeProfileActions.setChargeProfileGeneralInfo({ ...rslt, id: generalInfo.id }),
            );
          } else {
            dispatch(chargeProfileActions.setChargeProfileGeneralInfo(rslt));
          }
        })
        .catch((e) => {
          console.error(e);
        });
    } else if (idChargers.length > 0) {
      setCurrentStep(currentStep + 1);
    } else {
      console.error('There are not chargers selected ');
      message.error('No hay Cargadores seleccionados');
    }
  }, [currentStep, idChargers]);

  const onPrevious = useCallback(async () => {
    // const isStepValid = await isStepValidate();
    // if (isStepValid) setCurrentStep(currentStep + 1);
    // eslint-disable-next-line
    if (currentStep === 0) {
      history.push('tablas');
    } else {
      setCurrentStep(currentStep - 1);
    }
  }, [currentStep]);

  const onSave = useCallback(async () => {
    const saveData = {
      name: profilesData.generalInfo.name,
      description: profilesData.generalInfo.description,
      potency_total_kw: profilesData.chargers.maximum_consumption,
      charge_mode: profilesData.generalInfo.modeCharge,
    };

    const updateData = {
      id: profilesData.generalInfo.id,
      name: profilesData.generalInfo.name,
      description: profilesData.generalInfo.description,
      potency_total_kw: profilesData.chargers.maximum_consumption,
      charge_mode: profilesData.generalInfo.modeCharge,
    };

    if (!profileSetup.editProfile) {
      apiProfiles
        .createParkingProfiles(saveData)
        .then(async (rslt) => {
          // console.log('RESULT PARKING: ', rslt);

          const Priorities = ['NONE', 'HIGH', 'LOW'];
          message.success('Creando Conectores Espere un momento');
          const responses = await Promise.all(
            profilesData.vehicules.map(async (priority, index) => {
              // console.log('POS ARRAY: ',index)
              const posPriority = index;
              const vehiculesResp = await Promise.all(
                priority.map(async (vehicule) => {
                  // console.log('PRIORITY: ', Priorities[posPriority]);
                  const dataVehicule = {
                    name: vehicule.content.name,
                    description: '',
                    potency_kw: vehicule.content.maxPower,
                    priority: Priorities[posPriority],
                    is_active: true,
                    connector: vehicule.content.connector,
                    garage: rslt.id,
                  };
                  try {
                    const resp = await apiProfiles.createParkingProfilesConnector(dataVehicule);

                    return resp;
                  } catch (err) {
                    message.error(
                      `Cargador ${vehicule.content.charger} ya existe o supera la potencia maxima error: ${err}`,
                    );
                    return { error: 'Error' };
                  }
                }),
              );
              return vehiculesResp;
            }),
          );

          history.push('tablas');
          message.success('Perfil de Patio creado.');
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      apiProfiles
        .updateProfileGarage(updateData)
        .then(async (rslt) => {
          const Priorities = ['NONE', 'HIGH', 'LOW'];
          const responses = await Promise.all(
            profilesData.vehicules.map(async (priority, index) => {
              // console.log('POS ARRAY: ',index)
              const posPriority = index;
              message.success('Actualizando Conectores Espere un momento');
              const vehiculesResp = await Promise.all(
                priority.map(async (vehicule) => {
                  // console.log('PRIORITY: ', Priorities[posPriority]);
                  const dataVehicule = {
                    name: vehicule.content.name,
                    description: '',
                    potency_kw: vehicule.content.maxPower,
                    priority: Priorities[posPriority],
                    is_active: true,
                    connector: vehicule.content.connector,
                    garage: rslt.id,
                  };
                  try {
                    const resp = await apiProfiles.updateProfileConnGarage(dataVehicule);

                    return resp;
                  } catch (err) {
                    try {
                      const resp = await apiProfiles.createParkingProfilesConnector(dataVehicule);

                      return resp;
                    } catch (err) {
                      message.error(
                        `Cargador ${vehicule.content.charger} ya existe o supera la potencia maxima error: ${err}`,
                      );
                      return { error: 'Error' };
                    }
                  }
                }),
              );
              return vehiculesResp;
            }),
          );

          history.push('tablas');
          message.success('Perfil de Patio Actualizado.');
        })
        .catch((err) => {
          message.error('ERROR ACTUALIZANDO');
          console.log('ERROR ACTUALIZANDO: ',err);
        });
    }
  }, [currentStep, profilesData]);

  return (
    <>
      <Row className="container-cargadores">
        <Steps className="steps" current={currentStep}>
          <Step title="Información General" />
          <Step title="Selección Cargadores" />
          <Step title="Acciones" />
        </Steps>
      </Row>
      <Form layout="vertical" form={form}>
        <div style={{ display: currentStep === 0 ? 'block' : 'none' }}>
          <GeneralInformation onNext={onNext} onPrevious={onPrevious} />
        </div>

        <div style={{ display: currentStep === 1 ? 'block' : 'none' }}>
          <ChargersSelector
            onNext={onNext}
            onPrevious={onPrevious}
            setIdChargers={setIdChargers}
            listChargers={listChargers}
          />
        </div>

        <div style={{ display: currentStep === 2 ? 'block' : 'none' }}>
          <GridVrhicules
            modeCharge={form.getFieldValue().modeCharge}
            onSave={onSave}
            onPrevious={onPrevious}
          />
        </div>
      </Form>
    </>
  );
};

export default CargadoresProfiles;
