import alertasConst from '@store/constants/alertasConst';

const {
    ALERTS_ERROR_ITEMS,
    ALERTS_FETCHING_ITEMS,
    ALERTS_SUCCESS_ITEMS,
    ALERTS_UPDATE_ITEMS,
} = alertasConst;

const initState = {
    alertas: [],
    error: null,
    nextPage:null,
    previusPage:null,
    fetching:false,
    isReadyToSocket:false,
}


const alertasReducer = (state = initState, action ) => {
    const { type , payload } = action;

    switch(type){
        case ALERTS_ERROR_ITEMS:
            return {
                ...state,
                error:payload,
                fetching:false,
            }
        case ALERTS_FETCHING_ITEMS:
            return {
                ...state,
                fetching:true,
            }
        case ALERTS_SUCCESS_ITEMS:
            return {
                ...state,
                ...payload,
                fetching:false,
            }
        case ALERTS_UPDATE_ITEMS:
            console.log(state);
            console.log(payload);
            return {
                ...state,
                alertas:payload,
                fetching:false
            }
        default:
            return state;
    }
}

export default alertasReducer;