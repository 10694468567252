import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { faPen, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { apiProfiles } from '@utils/services/cpo';
import { chargeProfileActions } from '@store/actions';
import { Row, Col, Card, Table, Button, Popconfirm, message } from 'antd';
import { FormularioPerfil } from './components';
import './style.less';

const Perfiles = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const [formPerfilVisible, setFormPerfilVisible] = useState(false);
	const [profiles, setProfiles] = useState({
		selected: [],
		editProfile: null,
		data: [
			// {
			//     key: '1',
			//     profile: 'A toda mecha',
			//     unit: 'Amperios',
			//     value: '3',
			// },
		],
		loading: false,
	});

	const loadProfiles = () => {
		setProfiles({ ...profiles, loading: true });
		apiProfiles.getParkingProfiles().then((rslt) => {
			setProfiles({ selected: [], data: rslt, loading: false });
		}).catch((err) => {
			setProfiles({ ...profiles, loading: false });
			console.log('err: ', err);
		});
	};

	const handleUpdateAction = async (obj) => {
		dispatch(chargeProfileActions.setChargeProfileSetup({
			mark: 'Editar Perfil',
			editProfile: true,
		}));
		message.warning('Cargando Perfil espere un momento');
		try {
			const profileEdit = await apiProfiles.getParkingProfilesbyId(obj.id);

			dispatch(chargeProfileActions.setChargeProfileGeneralInfo({
				id: obj.id,
				name: profileEdit.name,
				description: '',
				modeCharge: profileEdit.charge_mode,
				is_active: true,
			}));

			const chargersArray = [];

			const chargers = profileEdit.connectors.map((value) => {
				const evseId = value.connector.evse.evse_id;
				if (chargersArray.length > 0) {
					const inData = chargersArray.findIndex((val) => val.name.indexOf(evseId));

					console.log('chargersArray[inData]: ', chargersArray[inData])

					if (inData >= 0 && chargersArray[inData]) {
						if (typeof chargersArray[inData].connector == 'object') {
							chargersArray[inData].connector.push(value.connector)
						}

						chargersArray[inData] = {
							name: chargersArray[inData].name,
							connector: chargersArray[inData].connector };
					} else {
						chargersArray.push({
							name: value.connector.evse.evse_id,
							connector: [value.connector],
						});
					}

				} else {
					chargersArray.push({
						name: value.connector.evse.evse_id,
						connector: [value.connector],
					});
				}
				return evseId;
			});

			const nonePriority = [];
			const lowPriority = [];
			const highPriority = [];
			let indexArray = 0;

			const vehiculesArray = profileEdit.connectors.map((value) => {
				indexArray += 1;
				const dataVehicule = {
					id: indexArray.toString(),
					content: {
						name: value.name,
						charger: value.connector.evse.evse_id,
						connector: value.connector.id,
						connId: value.connector.conn_id,
						maxPower: value.potency_kw,
					},
				};

				if (value.priority === 'NONE') {
					nonePriority.push(dataVehicule);
				} else if (value.priority === 'LOW') {
					lowPriority.push(dataVehicule);
				} else {
					highPriority.push(dataVehicule);
				}

				return true;
			});

			const vehicules = [nonePriority, lowPriority, highPriority];

			dispatch(chargeProfileActions.setChargeProfileChargers({
				maximum_consumption: profileEdit.potency_total_kw,
				selected: chargersArray,
			}));
			dispatch(chargeProfileActions.setChargeProfileVehicules(vehicules));
			dispatch(chargeProfileActions.setChargeProfileList(chargers));
			history.push('editar-perfil');
		} catch (err) {
			console.log('err: ', err)
			message.error('Perfil no se pudo cargar');
		}
	};

	const handleDeleteAction = async (obj) => {
		apiProfiles.getParkingProfilesbyId(obj.id).then(async (rslt) => {
			message.info('Borrando Perfil');
			if (rslt.connectors.length > 0) {
				const respt = await Promise.all(rslt.connectors.map(async (value) => {
					const resp = await apiProfiles.deleteProfileGarageConnector(value.id);
					return resp;
				}));
			}

			const respGarage = await apiProfiles.delParkingProfilesbyId(obj.id);
			message.success('Perfil Borrado');
			loadProfiles();
		}).catch((err) => {
			setProfiles({ ...profiles, loading: false });
			//console.log(err);
		});
	};

	const columns = [
		{
			title: 'Perfiles',
			dataIndex: 'name',
			key: 'name',
			filters: profiles.data.map((e) => ({ text: e.name, value: e.name })),
			onFilter: (value, record) => record.name.indexOf(value) === 0,
			sorter: (a, b) => a.name.length - b.name.length,
		},
		{
			title: 'Modo de Carga',
			dataIndex: 'charge_mode',
			key: 'charge_mode',
			sorter: (a, b) => ((a.charge_mode < b.charge_mode) ? 1 : (a.charge_mode > b.charge_mode) ? -1 : 0),
		},
		{
			title: 'Potencia Total (kW)',
			dataIndex: 'potency_total_kw',
			key: 'potency_total_kw',
			sorter: (a, b) => ((a.potency_total_kw < b.potency_total_kw) ? 1 : (a.potency_total_kw > b.potency_total_kw) ? -1 : 0),
		},
		{
			title: 'Acciones',
			dataIndex: 'acciones',
			key: 'acciones',
			render: (v, e) => (
				<div>
					<FontAwesomeIcon onClick={() => handleUpdateAction(e)} className="style_action" icon={faPen} />
					<span className="style_divider" />
					<Popconfirm
						placement="left"
						title={`¿Deseas eliminar\n${e.name}?`}
						onConfirm={() => handleDeleteAction(e)}
						okText="Si"
						cancelText="Cancelar"
					>
						<FontAwesomeIcon className="style_action" icon={faTrash} />
					</Popconfirm>

				</div>
			),
		},
	];

	useEffect(() => {
		loadProfiles();
	}, []);

	const handleNuevoPerfilClick = () => {
		dispatch(chargeProfileActions.setChargeProfileSetup({
			mark: 'Crear Perfil',
			editProfile: false,
		}));
		dispatch(chargeProfileActions.setChargeProfileList([]));
		dispatch(chargeProfileActions.setChargeProfileGeneralInfo({
			id: 0,
			name: '',
			description: '',
			modeCharge: '',
			is_active: true,
		}));
		dispatch(chargeProfileActions.setChargeProfileVehicules([[], [], []]));
		dispatch(chargeProfileActions.setChargeProfileChargers({
			selected: [],
			maximum_consumption: 0,
		}));

		history.push('crear-perfil');
	};

	const handleHidePerfilForm = () => setFormPerfilVisible(false);

	return (
		<Row className="container-perfiles">

			<Col span={24}>
				<Button
					className="btn-nuevo_perfil"
					onClick={handleNuevoPerfilClick}
					type="primary"
					icon={<FontAwesomeIcon icon={faPlus} className="btn-icon" />}
				>
					Nuevo Garage
				</Button>
			</Col>
			<Col span={24}>
				<Card className="card-table">
					<Table
						loading={profiles.loading}
						dataSource={profiles.data}
						className="perfiles-table"
						columns={columns}
					/>
				</Card>
			</Col>

			<FormularioPerfil editProfile={profiles.editProfile} loadProfiles={loadProfiles} visible={formPerfilVisible} handleHidePerfilForm={handleHidePerfilForm} />
		</Row>
	);
};

export default Perfiles;
