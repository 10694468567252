import { get, post,} from './management';

const updateProfile = ( props ) => {
    const cID = props.client;
    delete props.client;
    return post(`/client/properties-client/${cID}/`, props , true);
}

const getProfile = ( props ) => {
    return get(`/client/properties-client/${props}` , true);
}



export default { updateProfile,getProfile };