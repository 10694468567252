import React, { useState } from 'react';
import { Typography, Card, Table, Tabs } from 'antd';
import { Link, } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { IconDeletePopup } from '@components/ui';
import { useSelector } from 'react-redux';
import { comprobar_permiso } from '@utils/auth/permissions';
import { useTranslation } from 'react-i18next'
// const { Paragraph } = Typography;
// const { TabPane } = Tabs;

const TablaClientes = (props) => {
  const { t, i18n } = useTranslation();
  const [pageSize, setPageSize] = useState(10);
  const { user, platform } = useSelector((state) => state);
  const { refreshData, data, loading, editarCliente, eliminarCliente } = props;

  const onChangeDateRange = (/* start,end */) => {

  };

  const columns = [
    {
      title: t('global.word.name'),
      dataIndex: 'nombre',
      key: 'nombre',
      defaultSortOrder: 'ascend',
      sorter: (a, b) => a.nombre.localeCompare(b.nombre),
      fixed: 'left',
      filters: [...new Set(data.map((e) => `${e.nombre}`))].map((e) => ({ text: `${e}`, value: `${e}` })),
      onFilter: (value, record) => record.nombre.indexOf(value) === 0,
    },
    {
      title: 'NIT',
      dataIndex: 'nit',
      key: 'nit',
      sorter: (a, b) => a.nit - b.nit,
      filters: [...new Set(data.map((e) => `${e.nit}`))].map((e) => ({ text: `${e}`, value: `${e}` })),
      onFilter: (value, record) => record.nit.indexOf(value) === 0,
    },
    {
      title: t('global.word.phone'),
      dataIndex: 'telefono',
      key: 'telefono',
      sorter: (a, b) => a.telefono - b.telefono,
      filters: [...new Set(data.map((e) => `${e.telefono}`))].map((e) => ({ text: `${e}`, value: `${e}` })),
      onFilter: (value, record) => record.telefono.indexOf(value) === 0,
    },
    {
      title: t('global.word.email'),
      dataIndex: 'correo_electronico',
      key: 'correo_electronico',
      sorter: (a, b) => a.correo_electronico.localeCompare(b.correo_electronico),
      filters: [...new Set(data.map((e) => `${e.correo_electronico}`))].map((e) => ({ text: `${e}`, value: `${e}` })),
      onFilter: (value, record) => record.correo_electronico.indexOf(value) === 0,
    },
    {
      title: t('global.word.country'),
      dataIndex: 'pais',
      key: 'pais',
      sorter: (a, b) => a.pais.localeCompare(b.pais),
      filters: [...new Set(data.map((e) => `${e.pais}`))].map((e) => ({ text: `${e}`, value: `${e}` })),
      onFilter: (value, record) => record.pais.indexOf(value) === 0,
    },
    {
      title: t('global.word.city'),
      dataIndex: 'ciudad',
      key: 'ciudad',
      sorter: (a, b) => a.ciudad.localeCompare(b.ciudad),
      filters: [...new Set(data.map((e) => `${e.ciudad}`))].map((e) => ({ text: `${e}`, value: `${e}` })),
      onFilter: (value, record) => record.ciudad.indexOf(value) === 0,
    }, {
      title: t('global.word.state'),
      dataIndex: 'estado',
      key: 'estado',
      sorter: (a, b) => a.estado - b.estado,
      filters: [{ text: t('global.word.active'), value: 1 }, { text: t('global.word.deactive'), value: 0 }],
      onFilter: (value, record) => record.estado === value,
      render: (text, record, index) => {
        return (
          <IconDeletePopup icon={text === 1 ? faCheck : faTimes}
            style={{
              fontSize: 20,
              color: text === 1 ? `${platform.main_color}` : "gray",
              cursor: `${comprobar_permiso(user, 17) ? 'pointer' : 'arrow'}`,
              pointerEvents: `${comprobar_permiso(user, 17) ? 'all' : 'none'}`,
            }}
            eliminarCliente={eliminarCliente}
            title={text === 1 ? `${t('clients.tables.popchecked')}` : `${t(`clients.tables.popnochecked`)}`}
            record={record} />
        );
      }
    },
    {
      title: t('global.word.actions'),
      dataIndex: 'acciones',
      key: 'acciones',
      render: (text, record, index) => {
        return (
          <div style={{
            display: "flex",
            justifyContent: "space-around",
          }}>
            <Link to={{ pathname: `/configuracion-cuenta/${record.nombre}/usuarios`, id: record.key }}><p style={{ fontSize: 16, color: `${platform.main_color}`, margin: 0 }} >{t('clients.table.btn.admin')}</p></Link>
            <FontAwesomeIcon onClick={() => editarCliente(record)}
              icon={faPen}
              style={{ fontSize: 20, color: `${platform.main_color}`, cursor: "pointer", visibility: `${comprobar_permiso(user, 2) ? `visible` : `hidden`}`, }}
            />
            {/* <IconDeletePopup eliminarCliente={eliminarCliente} record={record}/> */}
          </div>
        )
      }
    },
  ]

  const onChange = (page, pageSize) => {
    setPageSize(pageSize);
  };

  return (
    <Card>
      <h4>{t('clients.table.title')}</h4>
      <div className="filtros-tabla">
        <Table
          columns={columns}
          dataSource={data}
          tableLayout="auto"
          loading={loading}
          pagination={{
            pageSize,
            pageSizeOptions: [10, 20, 50],
            showSizeChanger: true,
            onChange: onChange,
          }} />
      </div>
    </Card>
  );
}

export default TablaClientes;