import React,{ useEffect,useState } from 'react';
import { Modal,Row,Col, Form, Input,Button, message } from 'antd';
import { PlusOutlined } from '@ant-design/icons'
import { apiLocations } from '@utils/services/cpo'
import { Spanish } from '@utils/helpers/dictIdiomas'
import { useTranslation } from 'react-i18next'
const FormularioBrand = ( props ) => {
    const { t, i18n } = useTranslation();
    const textLanguage = Spanish({screen:'evse-form'})
    const { 
        isVisible,
        setVisible,
        dataFormSend,
        setDataForm,
    } = props;
    const [formModel] = Form.useForm();
    const [brands,setBrands] = useState({
        data:[],
        loading:false
    });
    const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    useEffect( () => {
        if(isVisible) cargarBrands();
    },[isVisible]);

    useEffect( () => {
        let brandTemp = getBrandRaw()
        // console.log(brandTemp,brands.data);
        if(brandTemp) formModel.setFieldsValue({brand:brandTemp.description});
    },[brands.data]);

    const handleCancel = () => {
        setVisible(false);
    }

    const limpiarForm = () => {
        formModel.resetFields();
        handleCancel();
    }

    const getBrandRaw = () => {
        for(let i = 0 ; i < brands.data.length ; i++){
            const brand = brands.data[i];
            if(brand.id === dataFormSend.evse_brand) return brand;
        }
    }


    const cargarBrands = () => {
        setBrands({...brands,loading:true});
        apiLocations.getBrandsLocation().then( (rslt) => {
            setBrands({data:rslt,loading:false});
        }).catch( (err) => {
            setBrands({...brands,loading:false});
            message.warn(textLanguage.REQUEST_CON_ERROR);
            console.log('err: ',err);
        });
    }

    const sendRequest = () => {
        formModel.validateFields().then( (rslt) => {
            if(rslt.errorFields) return;
            apiLocations.createModel({
                brand:dataFormSend.evse_brand,
                description:rslt.description    
            })
            .then((rslt) => {
                // console.log(rslt);
                limpiarForm();
                if(dataFormSend && setDataForm) setDataForm({...dataFormSend,evse_model:rslt.id});
            })
            .catch((err) => {
                message.warn(textLanguage.REQUEST_CON_ERROR);
                //console.log(err);
            });
        }).catch( (err) => {
            console.log('err: ',err);
        });
    }

    return(
        <Modal visible={isVisible} onCancel={handleCancel}
        width={500}
        style={{
            textAlign:'center',
        }} 
        footer={false}>
            <Row style={{
                    justifyContent:'center'
                }}>
                <Col span={18}>
                    <h2
                    style={{
                        marginBottom:'30px'
                    }}
                    >{t('form.model.header')}</h2>
                    <Form style={{marginBottom:15}} {...layout} 
                    form={formModel}
                    name={'form-brand'}>
                        <Form.Item name={'brand'} label={t('form.charger.brand')} initialValue={dataFormSend.evse_brand} >
                            <Input  placeholder={'Ingrese marca'} disabled />
                        </Form.Item>
                        <Form.Item name={'description'}  label={t('form.charger.model')}
                        rules={[{required:true,message:t('global.word.inputrequire')}]}>
                            <Input  placeholder={t('global.word.extravalue')}/>
                        </Form.Item>
                    </Form>
                    
                </Col>
                <Col span={24} style={{marginTop:10}}>
                    <Button onClick={limpiarForm} style={{marginRight:10}} type={'default'} >{t('form.model.btn.cancel')}</Button>
                    <Button onClick={sendRequest} icon={<PlusOutlined/>} type='primary'>{t('form.model.btn.add')}</Button>
                </Col>
            </Row>
        </Modal>
    );
}

FormularioBrand.defaultProps = {
    isVisible:true,
    form:{
        brand:'',
        model:'',
    },
}


export default FormularioBrand;