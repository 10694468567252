import { apiLocations } from '../../utils/services/cpo';
import locationsConst from '@store/constants/locationsConst'
import getDateLocalString from '@utils/helpers/UtcResponseReceive'
import moment from 'moment';
import 'moment/locale/es';

const getLocations = (user) => async (dispatch, getState) => {
  const {
    LOCATION_FETCHING_ITEMS: fetching,
    LOCATION_SUCCESS_ITEMS: success,
    LOCATION_ERROR_ITEMS: error,
  } = locationsConst;

  let response;
  dispatch({ type: fetching });
  try {
    response = await apiLocations.locations(user.user_id);
  } catch (err) {
    return dispatch({ type: error, payload: err });
  }

  /* console.log('//////////////////////// getLocations //////////////////////////')
  console.log('////////////////////////////////////////////////////')
  console.log('response: ', response) */

  const locations = response ? response.map((item, index) => {
    return {
      key: `${item.evse["id"]}_${index}`,
      id: item.evse["evse"],
      raw_id: item.evse["id"],
      operador: item.evse["evse_operator"],
      estacion: item.evse["evse_location_name"],
      raw_conector_id: item.id,
      conector_id: item.conn_id,
      conector: item.standard,
      estado: item.status_connector,
      recarga_activa: [],
      uso: item.evse["evse_type_use"],
      direccion: item.evse["evse_location_address"],
      acciones: `${item.evse["evse"]}`,
      location_id: item.evse?.id || null,
      session_id: item.session_id || null
    }
  }) : []

  const payload = {
    locations: locations,
    locationsRaw: response
  }

  return dispatch({ type: success, payload });
}

const updateLocation = (json) => async (dispatch, getState) => {
  const evsesObject = getState().locations;
  const arrayLocations = evsesObject.locations;

  const {
    LOCATION_UPDATE_ITEMS: update,
    LOCATION_SUCCESS_ITEMS: success,
  } = locationsConst;

  for (let i = 0; i < arrayLocations.length; i++) {
    if (json.connector_id && json.connector_id !== '') {
      if (arrayLocations[i].raw_id === json.evse_id && arrayLocations[i].raw_conector_id === json.connector_id) {
        if ('status' in json) arrayLocations[i].estado = json.status;
        if (json.event === 'METER_VALUES') {
          let elemento = {
            soc: json.soc,
            uv: typeof json.power_active === 'string' ? (parseFloat(json.power_active) / 1000).toFixed(2) : (json.power_active / 1000).toFixed(2),
            pv: parseFloat(json.value / 1000).toFixed(2),
            name: moment().format('YYYY-MM-DD HH:mm:ss').toString(),
            session_id: json.session_id || null,
            evse_id: json.evse_id || null,
            connector_id: json.connector_id || null
          }

          arrayLocations[i].recarga_activa.push(elemento);
          arrayLocations[i].recarga_activa = arrayLocations[i].recarga_activa
            .map((item) => {
              return {
                ...item,
                name: moment(getDateLocalString(item.name), 'YYYY-MM-DD HH:mm:ss')
              }
            })
            .sort((a, b) => {
              return moment(a.name, 'YYYY-MM-DD HH:mm:ss').isBefore(moment(b.name, 'YYYY-MM-DD HH:mm:ss'))
            })
            .reduce((acumulador, elemento) => {
              if (acumulador.findIndex(item => item.name == elemento.name
                || moment(item.name, 'YYYY-MM-DD HH:mm:ss').isSame(moment(elemento.name, 'YYYY-MM-DD HH:mm:ss'))
                || moment(item.name, 'YYYY-MM-DD HH:mm:ss').toString() == moment(elemento.name, 'YYYY-MM-DD HH:mm:ss').toString()
              ) < 0) {
                acumulador.push(elemento);
              }
              return acumulador;
            }, []);
        }
        break;
      }
    } else {
      if (arrayLocations[i].raw_id === json.evse_id) {
        if ('status' in json) arrayLocations[i].estado = json.status;
        break;
      }
    }
  }

  const dResult = dispatch({ type: update, payload: arrayLocations });
  return dResult;
}

/**
 * 
 * client: 0
event: "CHANGE_STATUS"
evse_id: "testevse"
status: "AVAILABLE"
 */


export default {
  getLocations,
  updateLocation,
}