import React from 'react';
import { Card,Row,Col, Divider, Empty, Spin } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartBar, faCar, faChartLine, faInfo, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import './style.less'
const CardDefault = ( props ) => {

    const { title,mainValue,bottomMsg,bottomCount,children,loading } = props;

    return(
        <Card className={'card-default'}>
						{ !loading && (
								<Row>
										<Col span={24}>
												<Row>
														{ title && (
																<Col className={'card-default_title'} span={22}>
																		{title}
																</Col>
														)}
														<Col className={'card-default_info'} span={2}>
																{/* <FontAwesomeIcon   icon={faInfoCircle} /> */}
														</Col>
												</Row>
										</Col>
										{ mainValue && (
												<Col className={'card-default_mainvalue'} span={24}>
														<p>{mainValue}</p>
												</Col>
										)}
										<Col span={24}>
												{children}
										</Col>
										{ bottomMsg[0] || bottomMsg[1] ? (
												<Col span={24}>
														<Divider className={'card-default_divider'}/>
														{ bottomMsg[0] && <Row>
																<Col span={20}>
																		<p className={'bottomMsg'}>{bottomMsg[0]}</p>
																</Col>
																<Col span={4}>
																		<p className={'bottomCount'}>{bottomCount[0]}</p>
																</Col>
														</Row> }
														{ bottomMsg[1] && <Row>
																<Col span={20}>
																		<p className={'bottomMsg'} style={{marginTop:0}}>{bottomMsg[1]}</p>
																</Col>
																<Col span={4}>
																		<p className={'bottomCount'} style={{marginTop:0}}>{bottomCount[1]}</p>
																</Col>
														</Row> }
														
												</Col>
										) : ('')}
										
								</Row>
						)}
						{ loading && <Spin className={'loading-cake'}/>}
        </Card>
    );
}

CardDefault.defaultProps = {
    title:'Titulo card',
    bottomMsg:[],
    bottomCount:[],
    mainValue:'85',
		loading: true
}

export default CardDefault;