import platformConst from '../constants/platformSettingConst';

localStorage.removeItem('platform_settings');

const celsia_template = {
  main_color: '#EE7400',
  second_color: '',
  text_color: '',
  logo: './logocelsia_compuesto.png',
  icon: `./minicelsia.png`,
  name: 'Celsia Platform',
  copy: '© Copyright 2021 Celsia S.A. E.S.P. - Medellín, Colombia',
}

const mobum_template = {
  main_color: '#376680',
  second_color: '',
  text_color: '',
  logo: './logomubon_compuesto.png',
  icon: './mubon_simbolo_claro.png',
  name: 'Mubon Platform',
  copy: 'Copyright © 2021 Mubon Smart mobility solution for electric vehicles | Desarrollado por DeepSea Developments',
}

const endpoint = window.location.href;//'https://dev.chargers.mubon.co/';//'https://cargadores.celsia.com/';//window.location.href;

const initState = localStorage.getItem('platform_settings') ? JSON.parse(localStorage.getItem('platform_settings')) : {
  main_color: endpoint.includes('celsia') ? celsia_template.main_color : endpoint.includes('mubon') ? mobum_template.main_color : '#376680',
  name: endpoint.includes('celsia') ? celsia_template.name : endpoint.includes('mubon') ? mobum_template.name : 'CPO Platform',
  second_color: '#FFFF',
  text_color: '',
  logo: endpoint.includes('celsia') ? celsia_template.logo : endpoint.includes('mubon') ? mobum_template.logo : mobum_template.logo,
  icon: endpoint.includes('celsia') ? celsia_template.icon : endpoint.includes('mubon') ? mobum_template.icon : mobum_template.icon,
  copy: endpoint.includes('celsia') ? celsia_template.copy : endpoint.includes('mubon') ? mobum_template.copy : mobum_template.copy,
}

try {
  document.querySelector("link[rel*='icon']").href = `${initState.icon}`;
  window.less.modifyVars({
    '@primary-color': initState.main_color,
  });
} catch (error) {
  console.log('error: ', error)
}

const platformReducer = (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    case platformConst.UPDATE_PLATFORM:
      const temp = {
        ...state,
        ...payload
      };
      localStorage.setItem('platform_settings', JSON.stringify(temp));
      return temp;
    default:
      return state;
  }
}

export default platformReducer;