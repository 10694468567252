import React from 'react';
import { Table, Tag } from 'antd';

const TableTransaction = ({ state, rfids, clients }) => {

  const { data } = state;
  const columns = [
    {
      title: 'Codigo',
      dataIndex: 'tag_id',
      key: 'code',
      fixed: 'left',
      filters: [...new Set(data.map((e) => `${e.tag_id}`))].map((e) => ({ text: `${e}`, value: `${e}` })),
      sorter: (a, b) => `${a.tag_id}`.localeCompare(b.tag_id),
      onFilter: (value, record) => record.tag_id.indexOf(value) === 0,
    },
    {
      title: 'Cliente',
      dataIndex: 'client',
      key: 'client',
      filters: [
        ...Object.keys(clients).map((e) => {
          return { text: `${clients[e].name}`, value: `${clients[e].name}` }
        })
      ],
      onFilter: (value, record) => record.client.filter(item => item.name === value).length > 0,
      sorter: (a, b) => `${a.client}`.localeCompare(b.client),
      render: (e) => {
        return <>
          {
            e.map((e) => (
              <Tag
                key={e.id}
              >
                {e.name}
              </Tag>
            ))
          }
        </>;
      }
    },
    {
      title: 'Energy',
      dataIndex: 'energy',
      key: 'energy',
      sorter: (a, b) => parseFloat(a.energy) - parseFloat(b.energy),
      render: (e) => (`${(e >= 1000 ? (e / 1000) : e).toFixed(2)}${e >= 1000 ? 'MWh' : 'kWh'}`)
    },
  ]
  return (
    <Table
      columns={columns}
      dataSource={
        data.map((d, i) => {
          return {
            ...d,
            key: i,
            client: rfids ? rfids.filter(e => e.codigo === d.tag_id)[0].cliente : []
          }
        })
      }
    />
  );
}

export default TableTransaction;