// import InProgressView from '../../../../views/InProgressView';
import PerfilesUsuarios from '@views/PerfilesUsuarios';
const path = '/configuracion-sitio/perfil';

const perfilMenu = (t) => {
  return {
    path,
    name: t("menus.profile"),
    icon: null,
    component: PerfilesUsuarios,
    key: 'config-sitio-perfil',
    children: [],
  };
}

export default perfilMenu;