/* eslint-disable no-undef */
import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useLocation, useHistory, useRouteMatch, Link } from 'react-router-dom';
import { MarkerClusterer, Marker, InfoWindow } from '@react-google-maps/api';
import Map from '@components/mapa/GoogleMap';
import Breadcrumb from '@containers/Breadcrumb';
import { apiLocations } from '@utils/services/cpo';
import { useSelector,useDispatch } from 'react-redux';
import { PlusOutlined } from '@ant-design/icons';
import GetStatusDict from '@utils/helpers/estadosConector';
import { CardMap } from './components';
import { export_to_csv } from '@utils/helpers/exportCSVUtil';
import './style.less';
import { Button, message, Row, Col, Drawer, Table, Input } from 'antd';
import { OpenLocationCode } from 'open-location-code';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faEye, faPlug, faChargingStation, faPen } from '@fortawesome/free-solid-svg-icons';
import { Spanish } from '@utils/helpers/dictIdiomas'
import { useTranslation } from 'react-i18next'
import locationsConst from '@store/constants/locationsConst'

const MapsView = () => {
  const { t, /* i18n */ } = useTranslation();
  const textLanguage = Spanish({ screen: 'cpo-tablero' })
  const openCode = new OpenLocationCode();
  // const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  // const [cardFilter, setCardFilter] = useState(null);
  const match = useRouteMatch();
  const [activeCard, setAactiveCard] = useState(null);
  const [map, setMap] = useState(null);
  const [selectCard, setSelectCard] = useState(null);
  const { user, platform, locations: locationsItems } = useSelector((state) => state);
  const [sizeDrawerTable, setSizeDrawerTable] = useState(340);
  const timerLopping = useRef(); 

  const [tableData, setTableData] = useState({
    visible: false,
    ids_selected: null,
    operator_selected: null,
    estacion_selected: null,
    connector_selected: null,
    conectorid_selected: null,
    state_charger: null,
    currentDataSource: null,
    input_search: '',
  });
  const [infoVisible, setInfoVisible] = useState({
    lat: 0,
    lng: 0,
    obj: {
      location: 'Unicentro',
      state: 'Disponible',
      connectors: [
        {
          type: 'Tipo 1',
          total: 4,
          available: 2
        },
        {
          type: 'Tipo 2',
          total: 4,
          available: 1
        },
        {
          type: 'SCHUKO',
          total: 4,
          available: 4
        }
      ]
    },
    visible: false,
  });
  const [state, setState] = useState({
    locations: [],
  });
  const [locationMarker, setLocationMarker] = useState(null);
  const [mapZoom, setMapZoom] = useState(5);
  const [mapCenter, setMapCenter] = useState({
    lat: 4.6420853,
    lng: -78.8349386
  });
  const [locations, setLocations] = useState([]);
  const breadcrumbData = [[null, t('map.header')]];
  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      sorter: (a, b) => `${a.id}`.localeCompare(b.id),
      filters: [...new Set(locationsItems.locations.map(e => e.id))].map(e => ({ text: e, value: e })),
      filteredValue: tableData.ids_selected ? tableData.ids_selected : null,
      onFilter: (value, record) => record.id.indexOf(value) === 0,
    },
    {
      title: t('global.word.operator'),
      dataIndex: 'operador',
      key: 'operator',
      sorter: (a, b) => `${a.operador}`.localeCompare(b.operador),
      filters: [...new Set(locationsItems.locations.map(e => e.operador))].map(e => ({ text: e, value: e })),
      filteredValue: tableData.operator_selected ? tableData.operator_selected : null,
      onFilter: (value, record) => record.operador.indexOf(value) === 0,
    },
    {
      title: t('global.word.station'),
      dataIndex: 'estacion',
      key: 'evse',
      sorter: (a, b) => `${a.estacion}`.localeCompare(b.estacion),
      filters: [...new Set(locationsItems.locations.map(e => e.estacion))].map(e => ({ text: e, value: e })),
      filteredValue: tableData.estacion_selected ? tableData.estacion_selected : null,
      onFilter: (value, record) => record.estacion.indexOf(value) === 0,
    },
    {
      title: t('global.word.connector'),
      dataIndex: 'conector',
      key: 'connector',
      sorter: (a, b) => `${a.conector}`.localeCompare(b.conector),
      filters: [...new Set(locationsItems.locations.map(e => e.conector))].map(e => ({ text: e, value: e })),
      filteredValue: tableData.connector_selected ? tableData.connector_selected : null,
      onFilter: (value, record) => record.conector.indexOf(value) === 0,
    },
    {
      title: t('global.word.connector') + ' ID',
      dataIndex: 'conector_id',
      key: 'conector_id',
      sorter: (a, b) => `${a.conector_id}`.localeCompare(b.conector_id),
      filters: [...new Set(locationsItems.locations.map(e => e.conector_id))].map(e => ({ text: e, value: e })),
      filteredValue: tableData.conectorid_selected ? tableData.conectorid_selected : null,
      onFilter: (value, record) => record.conector_id.indexOf(value) === 0,
    },
    {
      title: t('global.word.state'),
      dataIndex: 'estado',
      key: 'status',
      sorter: (a, b) => `${a.estado}`.localeCompare(b.estado),
      filters: [...new Set(locationsItems.locations.map(e => e.estado))].map(e => ({ text: GetStatusDict(e, t)[0], value: e })),
      filteredValue: tableData.state_charger ? [...tableData.state_charger] : null,
      onFilter: (value, record) => record.estado.indexOf(value) === 0,
      render: (e) => {
        return (
          <div>
            <FontAwesomeIcon icon={faCircle} style={{ fontSize: 10, color: `${GetStatusDict(e)[1]}`, marginRight: 10 }} />
            {GetStatusDict(e, t)[0]}
          </div>);
      }
    },
    {
      title: t('global.word.actions'),
      dataIndex: 'acciones',
      key: 'actions',
      render: (link) => {
        return (
          <Row>
            <Col>
              <Link to={`${match.path}/${link}`}>
                <FontAwesomeIcon className={'icon-table_acciones'} to={`${match.path}/${link}`} icon={faEye} />
              </Link>
            </Col>
            <Col>
              <Link to={`/tablero-cpo/editar_cargador/${link}`} >
                <FontAwesomeIcon className={'icon-table_acciones'} icon={faPen} />
              </Link>
            </Col>
          </Row>
        );
      }
    },
  ];
  const getMap = useCallback((mapInstance) => {
    setMap(mapInstance);
    // eslint-disable-next-line
  }, []);

  const getLocations = () => {
     
    const {
      LOCATION_FETCHING_ITEMS: fetching,
      LOCATION_SUCCESS_ITEMS: success,
      LOCATION_ERROR_ITEMS: error,
    } = locationsConst;

    apiLocations.locations(user.user_id).then((response) => {
      const locations = response ? response.map((item, index) => {
        return {
          key: `${item.evse["id"]}_${index}`,
          id: item.evse["evse"],
          raw_id: item.evse["id"],
          operador: item.evse["evse_operator"],
          estacion: item.evse["evse_location_name"],
          raw_conector_id: item.id,
          conector_id: item.conn_id,
          conector: item.standard,
          estado: item.status_connector,
          recarga_activa: [],
          uso: item.evse["evse_type_use"],
          direccion: item.evse["evse_location_address"],
          acciones: `${item.evse["evse"]}`,
          location_id: item.evse?.id || null,
          session_id: item.session_id || null
        }
      }) : []
    
      const payload = {
        locations: locations,
        locationsRaw: response
      }
    
      dispatch({ type: success, payload });

    })
    .catch((error) => {
      console.log('getLocations: ', error); 
    }); 
   
   
  }

  const handleTableChange = () => {

    if (timerLopping.current) clearTimeout(timerLopping.current);
     
    getLocations();

    setState({ ...state });
    apiLocations
    .allLocations(user.user_id)
    .then((info) => {
      let general = info.map((e) => {
        let connectorsTemp = {};
        const connectorsArrayEnd = [];

        const evsesLocation = e.evses;
        if (evsesLocation && evsesLocation.length > 0) {
          for (let e = 0; e < evsesLocation.length; e++) {
            const evseObj = evsesLocation[e];
            if (evseObj.connectors && evseObj.connectors.length > 0) {
              const conTemp = evseObj.connectors;
              for (let i = 0; i < conTemp.length; i++) {
                const conObj = conTemp[i];
                if (!connectorsTemp[`${conObj.standard}`]) {
                  connectorsTemp[`${conObj.standard}`] = {
                    type: conObj.standard,
                    evse: conObj.evse.evse,
                    total: 1,
                    available: conObj.status_connector === 'AVAILABLE' ? 1 : 0
                  }
                } else {
                  connectorsTemp[`${conObj.standard}`] = {
                    ...connectorsTemp[`${conObj.standard}`],
                    total: connectorsTemp[`${conObj.standard}`].total + 1,
                    available: conObj.status_connector === 'AVAILABLE' ? connectorsTemp[`${conObj.standard}`].available + 1 : connectorsTemp[`${conObj.standard}`].available
                  }
                }
              }
            }
          }
        }

        let keyTypes = Object.keys(connectorsTemp);

        for (let i = 0; i < keyTypes.length; i++) {
          connectorsArrayEnd.push(connectorsTemp[`${keyTypes[i]}`]);
        }

        let data = {
          lat: 0,
          lng: 0,
          obj: {
            location: e.name,
            state: e.state,
            connectors: connectorsArrayEnd
          }
        }

        try {
          const decode_plus = openCode.decode(e.plus_code);
          data = {
            ...data,
            lat: parseFloat(decode_plus.latitudeCenter),
            lng: parseFloat(decode_plus.longitudeCenter),
          };
        } catch (err) {
          console.log('error: ', err);
          // console.warn(`${e.name} no tiene codigo plus`);
          if (e.name !== undefined) message.warning(`${e.name} ${t('map.notification.nopluscode')}`);
        }

        return data;
      });

      setLocations(general);


    })
    .catch((error) => {
      console.log('error: ', error);
      setState({ ...state, isFething: false });
      message.error(textLanguage.EVSES_REQUEST_FAIL);
    }); 

      
    timerLopping.current = setTimeout(
      () => handleTableChange(),
      30000,
    );
  }

  useEffect(() => {
    let size = window.innerHeight > 788 ? 340 :
      window.innerHeight > 670 ? 240 :
        window.innerHeight > 570 ? 140 : 70;

    setSizeDrawerTable(size);
  }, [window.innerHeight]);

  useEffect(() => { 

    handleTableChange(); 

    // eslint-disable-next-line
  }, []);

  // Cleanup function to clear the timer when the component unmounts
  /*useEffect(() => () => {
 
    clearTimeout(timerLopping.current);

  });*/

  // Cleanup function to clear the timer when the component unmounts
  useEffect(() => {
    // cleanup function
    return () => {
      if (timerLopping.current !== null) {
        clearTimeout(timerLopping.current);
      }
    };
  }, []);

  const handleCrearCargador = () => {
    history.push('crear_cargador');
  }

  const handleMarkerclick = (obj) => {
    console.log('handleMarkerclick: ', obj);
    console.log('handleMarkerclick: ', [...new Set(locationsItems?.locations?.map(e => e.operador))].map(e => ({ text: e, value: e })));

    setMapZoom(15)
    setMapCenter({
      lat: obj.lat,
      lng: obj.lng
    });
    setInfoVisible(obj);
  }

  const handleZoomChange = (e) => {
    if (!map) return;
    setMapZoom(map.getZoom())
  }

  const handleCenterChange = (e) => {
    if (!map) return;
  }

  const inspectMarker = (obj) => {
    setTableData({ ...tableData, visible: true, estacion_selected: [`${obj.obj.location}`] });
    setLocationMarker(obj.obj.location);
  }

  const handleCardStateClick = (value) => {
		let array = [];
    if (tableData.state_charger) array = [...tableData.state_charger];
    const index = array.indexOf(value);
    if (index === -1) {
			array = [value]
			setSelectCard(value)
      // array.push(value);
    } else {
			array = []
			setSelectCard(null)
      // array.splice(index, 1)
    }

    console.log('handleCardStateClick: ', array);
    setTableData({ ...tableData, visible: true, state_charger: array.length > 0 ? array : null })

  }

  const getDashboardCSV = () => {
    message.loading(textLanguage.REQUEST_DOWNLOAD_CSV);
    export_to_csv(
      tableData.currentDataSource,
      locationsItems.locations,
      'Dashboard'
    );
  }

  return (
    <Row id="tablero-general-cpo_mapa">
      <Col span={24}>
        <Breadcrumb breadcrumbData={breadcrumbData} extra={<Button type="primary" onClick={handleCrearCargador} icon={<PlusOutlined />}>{t('map.btn.newcharge')}</Button>} />
      </Col>
      <Col
        sm={24}
        lg={24}
        className={'col-map'}
      >
        <Row className={'card-info-container'} gutter={[10, 10]}>
          <Col>
            <CardMap
              focusable={false}
              handleClick={() => setTableData({ ...tableData, visible: true, estacion_selected: null })}
              items={[
                {
                  icon: faChargingStation,
                  title: t('map.card.stations'),
                  value: [...new Set([...locationsItems.locations].map((e) => e.estacion))].length
                },
              ]} />
          </Col>
          <Col>
            <CardMap
              focusable={false}
              handleClick={() => setTableData({ ...tableData, visible: true, estacion_selected: null })}
              items={[
                {
                  icon: faPlug,
                  title: t('map.card.connectors'),
                  value: locationsItems.locations.length
                }
              ]} />
          </Col>
        </Row>
        <Row className={'card-info-table'}>
          <Col span={24}>
            <Drawer
              title={t('global.word.chargers')}
              placement="bottom"
              mask={false}
              closable={true}
              onClose={() => setTableData({ ...tableData, visible: false, estacion_selected: null })}
              visible={tableData.visible}
              getContainer={false}
              style={{ position: 'absolute', width: '100%' }}
            >
              <div className={'filters-container'}>
                <Input width={200} value={tableData.input_search} onChange={(e) => setTableData({ ...tableData, input_search: e.target.value })} placeholder={t('global.word.search')} />
                <Button type={'primary'} onClick={getDashboardCSV} className={'btn-download-csv'}>{t('global.word.download')}</Button>
              </div>

              <Table
                onChange={(p, f, s, { currentDataSource }) => {
                  console.log('onChange: ', f);
                  setTableData({
                    ...tableData,
                    ids_selected: f.id,
                    currentDataSource: currentDataSource,
                    operator_selected: f.operator,
                    estacion_selected: f.evse,
                    connector_selected: f.connector,
                    conectorid_selected: f.conector_id,
                    state_charger: f.status,
                  })
                }}
                columns={columns}
                scroll={
                  {
                    y: sizeDrawerTable
                  }}
                size={'small'}
                dataSource={locationsItems.locations.filter(e => {
                  let starts = false;
                  if (tableData.input_search) {
                    if (e.operador.toLowerCase().includes(tableData.input_search.toLowerCase())) starts = true;
                    if (e.estacion.toLowerCase().includes(tableData.input_search.toLowerCase())) starts = true;
                    if (e.conector.toLowerCase().includes(tableData.input_search.toLowerCase())) starts = true;
                    if (e.conector_id.toLowerCase().includes(tableData.input_search.toLowerCase())) starts = true;
                    if (e.estado.toLowerCase().includes(tableData.input_search.toLowerCase())) starts = true;
                    if (e.id.toLowerCase().includes(tableData.input_search.toLowerCase())) starts = true;
                  } else {
                    starts = true;
                  }
                  return starts;
                })} />
            </Drawer>
          </Col>
        </Row>
        <Row className={'card-info-container-resume'} gutter={[10, 10]}>
          <Col>
            <CardMap
              handleClick={(e) => handleCardStateClick('AVAILABLE')}
              items={[
                {
                  icon: faPlug,
                  title: t('map.card.available'),
                  value: locationsItems.locations.filter((e) => e.estado === 'AVAILABLE').length,
                  color: '#00c08c',
                  active: selectCard == 'AVAILABLE'
                },
              ]} />
          </Col>
          <Col>
            <CardMap
              handleClick={(e) => handleCardStateClick('CHARGING')}
              items={[
                {
                  icon: faPlug,
                  title: t('map.card.charging'),
                  value: locationsItems.locations.filter((e) => e.estado === 'CHARGING').length,
                  color: '#49a2d8',
                  active: selectCard == 'CHARGING'
                },
              ]} />
          </Col>
          <Col>
            <CardMap
              handleClick={(e) => handleCardStateClick('PLANNED')}
              items={[
                {
                  icon: faPlug,
                  title: t('map.card.connectionfail'),
                  value: locationsItems.locations.filter((e) => e.estado === 'PLANNED').length,
                  color: '#f60141',
                  active: selectCard == 'PLANNED'
                },
              ]} />
          </Col>
          <Col>
            <CardMap
              handleClick={(e) => handleCardStateClick('OUTOFORDER')}
              items={[
                {
                  icon: faPlug,
                  title: t('map.card.outservice'),
                  value: locationsItems.locations.filter((e) => e.estado === 'OUTOFORDER').length,
                  color: '#999999',
                  active: selectCard == 'OUTOFORDER'
                },
              ]} />
          </Col>
        </Row>

        <Map
          zoom={mapZoom}
          onZoomChanged={handleZoomChange}
          center={mapCenter}
          onCenterChanged={handleCenterChange}
          getMap={setMap}>
          <MarkerClusterer >
            {(clusterer) => {
              return locations.map((general, index) => {
                const key = `marker_${index}`;
                return <Marker onClick={() => handleMarkerclick(general)} key={key} position={general} clusterer={clusterer} >
                  {
                    (infoVisible === general) &&
                    <InfoWindow onCloseClick={() => setInfoVisible({ ...infoVisible, visible: false })}>
                      <div style={{ padding: 10 }}>
                        <h3>{infoVisible.obj ? infoVisible.obj.location : 'Cargando'}</h3>
                        <p style={{ marginBottom: 20 }}><FontAwesomeIcon color={'#00c08c'} icon={faCircle} /> <strong>{t('states.available')}</strong></p>
                        {
                          (infoVisible.obj && infoVisible.obj.connectors.length > 0) ? infoVisible.obj.connectors.map((v, i) => {
                            return (
                              <div key={`info-tipo_${i}`} style={{ marginBottom: 10 }} >
                                <p style={{ marginBottom: 0, display: 'inline-block' }}><FontAwesomeIcon color={'gray'} icon={faPlug} /> {v.type}</p>
                                <p style={{ marginLeft: 5, marginRight: 5, float: 'right', marginBottom: 0, display: 'inline-block' }}><strong style={{ color: '#00c08c', marginRight: 4 }}>{`${v.available}`}</strong>{`de ${v.total}`}</p>
                              </div>
                            );
                          }) : <p style={{ marginBottom: 0, display: 'inline-block' }}><FontAwesomeIcon color={'gray'} icon={faPlug} /> {'Sin conectores'}</p>
                        }
                        <Button style={{ marginTop: '10px' }} onClick={() => inspectMarker(general)} color={platform.main_color} icon={<FontAwesomeIcon style={{ marginRight: '5px' }} icon={faEye} />}>{t('global.word.inspect')}</Button>
                      </div>
                    </InfoWindow>
                  }
                </Marker>;
              })
            }}
          </MarkerClusterer>
        </Map>

      </Col>
    </Row>
  );
};

export default MapsView;
